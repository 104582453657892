import * as d3 from "d3";
import $ from "jquery";
import { busy, hideBusy } from "./utils";

$(document).on("click", "[data-download-svg]", downloadSvgHandler);
$(document).on("click", "[data-download-svg-as-pdf]", convertSVGToPDFAndDownloadHandler);

// 2017-07-25 / Matthias Kummer
// click event handler for clicks on elements with [data-download-svg]
// where this data attribute contains the selector of the svg to download
// optional [data-svg-filename], resulting in filenane «filename»-YYYY-MM-DD-HH-MM-SS.svg
// otherwise use PLATIN-Chart as default filename prefix

function downloadBlob(blob, filename) {
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const svgUrl = URL.createObjectURL(blob).replace("&nbsp;", " "),
      downloadLink = document.createElement("a");
    downloadLink.href = svgUrl;
    downloadLink.download = filename;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
}

function downloadSvgHandler(e) {
  e.preventDefault();
  const sel = $(this).data("download-svg"),
    filename = ($(this).data("svg-filename") || "PLATIN-Chart") + " " + d3FormatFileTimestamp(new Date()) + ".svg",
    svg = document.querySelector(sel);
  serializeAndSendSVG(svg, filename);
}

function convertSVGToPDFAndDownloadHandler(event) {
  event.preventDefault();
  const sel = $(this).data("download-svg-as-pdf"),
    svg = $(sel)[0].prepareSVGForDownload(),
    serializer = new XMLSerializer(),
    svgString = serializer.serializeToString(svg).replace(/&nbsp;/g, " "),
    filename = $(this).data("pdf-filename") || "Chart.pdf";
  busy();
  $.post("/utils/svg2pdf", { svg: svgString, filename: filename }, processConvertedPdf);
}

function serializeAndSendSVG(svgElement, filename) {
  let svgData = (new XMLSerializer().serializeToString(svgElement)).replace(/&nbsp;/g, " "),
    svgBlob;
  try {
    svgBlob = new Blob([svgData], {
      type: "image/svg+xml;charset=utf-8"
    });
  } catch (e) {
    // Old browser, need to use blob builder
    window.BlobBuilder = window.BlobBuilder ||
      window.WebKitBlobBuilder ||
      window.MozBlobBuilder ||
      window.MSBlobBuilder;
    if (window.BlobBuilder) {
      var bb = new window.BlobBuilder();
      bb.append(svgData);
      svgBlob = bb.getBlob("image/svg+xml;charset=utf-8");
    }
  }
  downloadBlob(svgBlob, filename);
}

function processConvertedPdf(data) {
  var pdfData = data.pdf;
  var filename = data.filename;
  // decode base64 string, remove space for IE compatibility
  var binary = atob(pdfData.replace(/\s/g, ""));
  var len = binary.length;
  var buffer = new ArrayBuffer(len);
  var view = new Uint8Array(buffer);
  for (var i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }

  // create the blob object with content-type "application/pdf"               
  var blob = new Blob([view], { type: "application/pdf" });

  downloadBlob(blob, filename);
  hideBusy();
}

// Global variables for D3 features
d3.timeFormatDefaultLocale(
  {
    "dateTime": "%A, der %e. %B %Y, %X",
    "date": "%d.%m.%Y",
    "time": "%H:%M:%S",
    "periods": ["AM", "PM"],
    "days": ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
    "shortDays": ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
    "months": ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
    "shortMonths": ["Jan", "Feb", "Mrz", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"]
  }
);

const d3FormatFileTimestamp = d3.timeFormat("%Y-%m-%d-%H-%M-%S");
const d3FormatTimestamp = d3.timeFormat("%d.%m.%Y, %H:%M:%S");
const d3FormatWkdayTimestamp = d3.timeFormat("%a %d.%m.%Y, %H:%M:%S");
const d3FormatWkdayTimestampNoS = d3.timeFormat("%a %d.%m.%Y, %H:%M");
const d3FormatTime = d3.timeFormat("%H:%M:%S");
const d3FormatTimeNoS = d3.timeFormat("%H:%M");
const d3FormatDate = d3.timeFormat("%d.%m.%Y");
const d3FormatDateWkday = d3.timeFormat("%a %d.%m.%Y");
const d3FormatPercentage = d3.format(",.1%");
const d3ParseDate = d3.timeParse("%d.%m.%Y");
const d3ChartContainerSel = function (parent) {
  return parent + " .d3-chart-container";
};
const d3ChartOptionsSel = function (parent) {
  return parent + " .d3-chart-options";
};
const d3SVGDownloadButtonSel = function (parent) {
  return parent + " .d3-download-button";
};

export {
  d3FormatFileTimestamp,
  d3FormatTimestamp,
  d3FormatWkdayTimestamp,
  d3FormatWkdayTimestampNoS,
  d3FormatTime,
  d3FormatTimeNoS,
  d3FormatDate,
  d3FormatDateWkday,
  d3FormatPercentage,
  d3ParseDate,
  d3ChartContainerSel,
  d3ChartOptionsSel,
  d3SVGDownloadButtonSel,
  serializeAndSendSVG
};
