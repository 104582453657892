/* 2017-07-20 - 2017-07-31 / Matthias Kummer
   compvare rewrite based on D3 and DataTables
*/
/*jslint es6, multivar, browser, this, newcap: true */

import * as d3 from "d3";
import $ from "jquery";
import "webpack-jquery-ui/dialog";

import {
    startDatepicker
} from "utils";
import {
    d3ChartContainerSel,
    d3SVGDownloadButtonSel,
    d3FormatWkdayTimestamp
} from "d3utils";

"use strict";

import {
    initializers
} from "platin";

var selSensorSelectDialog = "#sensor-select",
    selBrowserWindow = "#tunnelmonitoring-browser",
    tmPreviewChartSel = "#tm-preview-chart",
    tmPreviewChartSvgSel = "#tm-preview-chart svg",
    tmPreviewBenchmarkSel = tmPreviewChartSel + " .benchmark",
    tmPreviewDownloadButtonSel = tmPreviewChartSel + ".d3-download-button",
    tmMainChartSel = "#tm-main-chart",
    tmMainChartSvgSel = "#tm-main-chart svg",
    tmMainBenchmarkSel = tmMainChartSel + " .benchmark",
    tmMainDownloadButtonSel = tmMainChartSel + " .d3-download-button",
    tmSensorsTableSel = "table.tm-sensors-table",
    tmSensorsTable,
    tmExportButtonsSel = "[data-tm-export]",
    tmExportDialogSel = "#tm-export-dialog",
    tmSelectQuarterDialogSel = "#select-quarter-dialog",
    dateTimeParser = d3.timeParse("%d.%m.%Y %H:%M:%S"),
    yAxisWidth = 60;

const initSeriesTable = function () {
    if ($(tmSensorsTableSel).length == 0){
        return
    };
    import( /* webpackChunkName: "dt" */ "packs/datatables").then(() => {
        if (!$.fn.DataTable.isDataTable(tmSensorsTableSel)) {
            tmSensorsTable = $(tmSensorsTableSel).DataTable({
                paging: false,
                scrollY: "400px",
                dom: "tr",
                columns: [{
                        data: 0
                    },
                    {
                        data: 1
                    },
                    {
                        data: 2
                    },
                    {
                        data: 3
                    },
                    {
                        data: 4
                    },
                    {
                        data: 5
                    },
                    {
                        data: showHide,
                        sortable: false
                    }
                ],
                columnDefs: [{
                    targets: [0],
                    visible: false
                }]
            });
        } else {
            tmSensorsTable = $(tmSensorsTableSel).DataTable();
        }

        function showHide(row) {
            return "<i class='fa fa-eye' data-show-hide=" + row[0] + "></i>";
        }

        $("#open-sensor-select-dialog").click();
    })

};

const showHideSeriesHandler = function (e) {
    var eye = $(e.target),
        sensorId = eye.data("show-hide"),
        series = $("#tm-main-chart g.data-series[data-sensor-id=" + sensorId + "]");
    if (series.css("display") == "none") {
        series.show("fade");
        eye.addClass("fa-eye").removeClass("fa-eye-slash");
    } else {
        series.hide("fade");
        eye.addClass("fa-eye-slash").removeClass("fa-eye");
    }
};

const startDateInput = function (parent) {
    return parent.querySelector("input.start-date");
};

const startTimeInput = function (parent) {
    return parent.querySelector("input.start-time");
};

const endDateInput = function (parent) {
    return parent.querySelector("input.end-date");
};

const endTimeInput = function (parent) {
    return parent.querySelector("input.end-time");
};

const dateAccessor = function (d) {
    return d[0];
};

const valueAccessor = function (d) {
    return d[1];
};

const tmOpenSensorSelectDialog = function (e) {
    e.preventDefault();
    $(selSensorSelectDialog).dialog({
        title: $(selSensorSelectDialog).data("title"),
        width: Math.max($(".main-content").innerWidth() * 0.9, 1200),
        height: Math.min(810, window.innerHeight - 140),
        closeText: "",
        show: {
            effect: "fade"
        },
        open: tmInitSensorSelectDialog
    });

    function tmInitSensorSelectDialog() {
        var selectTableHeight = (window.innerHeight > 960) ? "200px" : "100px",
            sensorTable,
            project = $(selSensorSelectDialog).data("project");

        function addSensorIdToRow(row, data) {
            $(row).data("sensor-id", data[3]);
        }

        startDatepicker();
        //App.startTimepicker()
        $(".d3-download-button").tooltip();
        var siSel = "table#sensor-ids";
        if (!$.fn.DataTable.isDataTable(siSel)) {
            // not yet initialized => initialize the DataTable
            $("table#sensor-ids").DataTable({
                paging: false,
                scrollY: selectTableHeight,
                dom: "tr",
                createdRow: addSensorIdToRow,
                columnDefs: [{
                    targets: [3],
                    visible: false,
                    searchable: false
                }]
            });
        } else {
            $(siSel).DataTable();
        }

        var nameSel = "table#units-name",
            namesTable;
        if (!$.fn.DataTable.isDataTable(nameSel)) {
            namesTable = $(nameSel).DataTable({
                paging: false,
                scrollY: selectTableHeight,
                dom: "tr"
            });
        } else {
            namesTable = $(nameSel).DataTable();
        }

        $("#si-select").change(function () {
            var searchString = $(this).val();
            if (searchString == "") {
                searchString = ".*";
            }
            $("#units-name tr.selected").removeClass("selected");
            namesTable.search("^" + searchString + "$", true, false, false).draw();
            $("table#sensor-ids").DataTable().clear().draw();
            tmUpdateSensorsTable([]);
        });

        $("select#los").change(function () {
            var searchString = $(this).val();
            if (searchString == "") {
                searchString = ".*";
            }
            $("table#sensor-ids tr.selected").removeClass("selected");
            sensorTable = $("table#sensor-ids").DataTable();
            sensorTable.columns(0).search("^" + searchString + "$", true, false, false).draw();
        });

        $("#units-name tr").click(function (e) {
            if (!(e.shiftKey || e.ctrlKey)) {
                $("#units-name tr.selected").removeClass("selected");
            }
            $(this).toggleClass("selected");
            var units = $("#units-name tr.selected").map(function () {
                return $(this).data("key");
            });
            $("table#sensor-ids").DataTable().clear().draw();
            $.post("/tunnelmonitoring/sensors_for_units?project=" + project, {
                unit_keys: units.toArray()
            }, tmUpdateSensorsTable);
        });

        $("#sensor-select button#max-timerange").click(function (e) {
            e.preventDefault();
            setTimeRangeInputs(null, selSensorSelectDialog);
            tmReloadPreviewChart();
        });

        $("#add-sensors-to-main-chart").click(function (e) {
            e.preventDefault();
        });
    }
};

function toggleMaxTimerange(e) {
    var parent = (e.target.id == "sel-max-timerange") ? selSensorSelectDialog : selBrowserWindow;
    tmEnableTimeRangeInputs(!this.checked, parent);
    if (this.checked) {
        if (parent == selSensorSelectDialog) {
            tmReloadPreviewChart();
        } else {
            tmReloadMainChart();
        }
    }
}

// 2017-07-27 / Matthias Kummer
// parent = parent element selector of the SVG chart; must be a sibling of the
// sole <nav> containing the inputs
function setTimeRangeInputs(timeRange, svgParentSel) {
    var parent = document.querySelector(svgParentSel).parentNode.querySelector("nav"),
        inpStartDate = startDateInput(parent),
        inpStartTime = startTimeInput(parent),
        inpEndDate = endDateInput(parent),
        inpEndTime = endTimeInput(parent);

    if (timeRange == null) {
        inpStartDate.val(null);
        inpStartTime.val(null);
        inpEndDate.val(null);
        inpEndTime.val(null);
    } else {
        var startTime = timeRange[0],
            endTime = timeRange[1],
            dateFormat = d3.timeFormat("%d.%m.%Y"),
            timeFormat = d3.timeFormat("%H:%M:%S");
        inpStartDate.value = dateFormat(startTime);
        inpStartTime.value = timeFormat(startTime);
        inpEndDate.value = dateFormat(endTime);
        inpEndTime.value = timeFormat(endTime);
    }
}

// 2017-08-01 / Matthias Kummer
// parentSel = parent element selector of the SVG chart; must be a sibling of the
// sole <nav> containing the inputs
function getTimeRangeFromInputs(svgParentSel) {
    var nav = document.querySelector(svgParentSel).parentNode.querySelector("nav"),
        inpStartDate = startDateInput(nav),
        inpStartTime = startTimeInput(nav),
        inpEndDate = endDateInput(nav),
        inpEndTime = endTimeInput(nav);
    return [
        dateTimeParser(inpStartDate.value + " " + inpStartTime.value),
        dateTimeParser(inpEndDate.value + " " + inpEndTime.value)
    ];
}

function tmEnableTimeRangeInputs(enabled, svgParentSel) {
    var parent = document.querySelector(svgParentSel).parentNode.querySelector("nav");
    $(parent).find("input.tm-manual-timerange").prop("disabled", enabled ? null : "disabled");
}

function tmUpdateSensorsTable(data) {
    var sensorsTable = $("table#sensor-ids").DataTable().clear();
    sensorsTable.rows.add(data.map(function (row) {
        return [row.LosName, row.AKSCode, row.Bemerkungen, row.sensor_id];
    })).draw();
}

function tmReloadPreviewChart() {
    var sensors = $("#sensor-ids tr.selected").map(function () {
            return $(this).data("sensor-id");
        }),
        nav = document.querySelector(tmPreviewChartSel).parentNode.querySelector("nav"),
        project = $(selSensorSelectDialog).data("project");

    if (sensors.length == 0) {
        return;
    }
    $.post("/tunnelmonitoring/fetch_series", {
        project: project,
        sensor_ids: sensors.toArray(),
        start_date: startDateInput(nav).value,
        start_time: startTimeInput(nav).value,
        end_date: endDateInput(nav).value,
        end_time: endTimeInput(nav).value,
        max_timerange: $("#sensor-select #sel-max-timerange").is(":checked")
    }, tmUpdatePreviewChart);
    $(tmPreviewBenchmarkSel).html("Laden… <span><i class='fa fa-refresh fa-spin fa-fw'></i></span>");
}

function tmFetchSeriesForAddingToMainChart(e) {
    e.preventDefault();
    tmReloadMainChart();
}

function tmReloadMainChart() {
    var newSensors = $("#sensor-ids tr.selected").map(function () {
            return $(this).data("sensor-id");
        }).toArray(),
        focus = d3.select(tmMainChartSvgSel + " g.focus"),
        oldSensors = focus.empty() ? [] : focus.selectAll(".data-series").data().map(function (r) {
            return r.sensor_id;
        }),
        sensors = [],
        nav = document.querySelector(tmMainChartSel).parentNode.querySelector("nav"),
        project = $(selSensorSelectDialog).data("project");

    oldSensors.concat(newSensors).forEach(function (el) {
        if (sensors.indexOf(el) == -1) {
            sensors.push(el);
        }
    });
    $(selSensorSelectDialog).dialog("close");
    if (sensors.length == 0) {
        return;
    }
    $.post("/tunnelmonitoring/fetch_series", {
        project: project,
        sensor_ids: sensors,
        start_date: startDateInput(nav).value,
        start_time: startTimeInput(nav).value,
        end_date: endDateInput(nav).value,
        end_time: endTimeInput(nav).value,
        max_timerange: $(selBrowserWindow + " #max-timerange").is(":checked")
    }, tmUpdateMainChart);
    $(selBrowserWindow + " .benchmark").html("Laden… <span><i class='fa fa-refresh fa-spin fa-fw'></i></span>");
}

function tmUpdatePreviewChart(response) {
    $(tmPreviewChartSvgSel).remove();
    $(tmPreviewBenchmarkSel).html(response.benchmark);
    if (response.records.length === 0) {
        $(tmPreviewDownloadButtonSel).css("display", "none");
    } else {
        tmDrawChart(tmPreviewChartSel, response);
    }
}

function tmUpdateMainChart(response) {
    $(tmMainChartSvgSel).remove();
    $(tmMainBenchmarkSel).html(response.benchmark);
    if (response.records.length === 0) {
        $(tmMainDownloadButtonSel).css("display", "none");
        $(tmExportButtonsSel).hide();
    } else {
        tmDrawChart(tmMainChartSel, response);
        $(tmExportButtonsSel).show();
    }
}

function tmDrawChart(parentSel, response) {
    var records = response.records,
        container = $(d3ChartContainerSel(parentSel)),
        containerWidth = container.innerWidth(),
        containerHeight = container.innerHeight(),
        nav = document.querySelector(parentSel).parentNode.querySelector("nav"),
        showLines = container.siblings(".d3-chart-options").find("input[name=show_lines]").is(":checked"),
        showPoints = container.siblings(".d3-chart-options").find("input[name=show_points]").is(":checked"),
        showAreas = container.siblings(".d3-chart-options").find("input[name=show_areas]").is(":checked");

    if (window.innerHeight < 960) {
        containerHeight = Math.min(240, window.innerHeight);
        $(container).css("height", containerHeight);
    }

    tmSensorsTable.clear().rows.add(records.map(function (r) {
        return [r.sensor_id, r.los, r.aks, r.unit, r.si, r.total_count];
    })).draw();

    $(d3SVGDownloadButtonSel(parentSel)).css("display", "");
    var svg = d3.select(d3ChartContainerSel(parentSel))
        .append("svg")
        .attr("version", "1.1")
        .attr("xmlns", "http://www.w3.org/2000/svg")
        .attr("class", "d3-chart")
        .attr("width", containerWidth)
        .attr("height", containerHeight);

    var siUnits = new Array(),
        nLeftAxes, nRightAxes,
        margin, width, height;

    records.forEach(function (el) {
        if (siUnits.indexOf(el.si) == -1) {
            siUnits.push(el.si);
        }
    });

    nLeftAxes = Math.ceil(siUnits.length / 2);
    nRightAxes = Math.floor(siUnits.length / 2);
    margin = {
        top: 8,
        right: nRightAxes * yAxisWidth + 20,
        bottom: 16,
        left: nLeftAxes * yAxisWidth
    };
    width = containerWidth - margin.left - margin.right;
    height = containerHeight - margin.top - margin.bottom;

    svg.append("defs").append("clipPath")
        .attr("id", "clip")
        .append("rect")
        .attr("width", width)
        .attr("height", height);

    var dataDateRange = d3.extent(d3.merge(records.map(function (e) {
            return d3.extent(e.data, dateAccessor);
        }))),
        timeRange = getTimeRangeFromInputs(parentSel),
        startTime = timeRange[0],
        endTime = timeRange[1];

    if (response.max_timerange || (startTime == null)) {
        startTime = dataDateRange[0];
    }
    if (response.max_timerange || (endTime == null)) {
        endTime = dataDateRange[1];
    }

    // x-scale and -axis
    var x = d3.scaleTime().rangeRound([0, width]);
    x.domain([startTime, endTime]);
    var xAxis = d3.axisBottom(x)
        .tickSize(-height);

    // y-scales and -axes: one per Si unit
    var yScaleForSi = {}, // hash of y scale functions indexed by SI unit
        yScale = []; // array of y scale functions indexed by series index

    // create the y scales per SI unit first
    var i = 0,
        r;
    for (i = 0; i < records.length; i += 1) {
        r = records[i];
        // no y-axis for this SI unit yet => add one and initialize the domain to this series' extent
        if (!yScaleForSi[r.si]) {
            yScaleForSi[r.si] = d3.scaleLinear()
                .rangeRound([height, 0])
                .domain(d3.extent(r.data, valueAccessor));
        } else {
            // y-axis for this SI-unit already present => update the domain to the extent of the previous domain's extent and this series' extent
            yScaleForSi[r.si].domain(
                d3.extent(
                    yScaleForSi[r.si].domain().concat(d3.extent(r.data, valueAccessor))
                )
            );
        }
    } // for loop creating y scales per SI unit

    // finally populate the lookup arrays by series index
    yScale = records.map(function (r) {
        return yScaleForSi[r.si];
    });

    var y = d3.local();
    var area = d3.local();
    var line = d3.local();

    var brush = d3.brushX()
        .extent([
            [0, 0],
            [width, height]
        ])
        .on("end", brushed);

    var color = d3.scaleOrdinal(d3.schemeCategory10);

    var bisectDate = d3.bisector(dateAccessor).left;

    var focus = svg.append("g")
        .attr("class", "focus")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
        .on("mousemove", moveCursor)
        .on("mouseout", hideCursor);

    setTimeRangeInputs(x.domain(), parentSel);
    focus.append("g")
        .attr("class", "axis axis--x")
        .attr("transform", "translate(0," + height + ")")
        .call(xAxis)
        .selectAll(".tick line")
        .attr("stroke-width", 0.5)
        .attr("stroke", "#ccc");

    var yAxisG;
    for (i = 0; i < siUnits.length; i += 1) {
        var ty = yScale[i],
            yTickSize = (i > 0 ? 6 : -width);
        yAxisG = focus.append("g")
            .attr("class", "axis axis--y")
            .attr("transform", ((i % 2) == 0) ? "translate(" + -i / 2 * yAxisWidth + ",0)" : "translate(" + parseInt((i - 1) / 2 * yAxisWidth + width) + ",0)")
            .call(((i % 2) == 0) ? d3.axisLeft(ty).tickSize(yTickSize) : d3.axisRight(ty));
        yAxisG
            .selectAll(".tick line")
            .attr("stroke-width", 0.5)
            .attr("stroke", "#ccc");
        yAxisG.append("text")
            .attr("fill", "#000")
            .attr("transform", "rotate(-90)")
            .attr("y", ((i % 2) == 0) ? -50 : 30)
            .attr("x", -height / 2)
            .attr("dy", "0.71em")
            .attr("text-anchor", "middle")
            .text(siUnits[i]);
    }

    // no comes the actual plots (lines, areas, points)
    var seriesG = focus.selectAll("g.data-series")
        .data(records)
        .enter()
        .append("g")
        .attr("class", "data-series")
        .attr("data-sensor-id", function (d) {
            return d.sensor_id;
        })
        .each(function (r, i) {
            var ty = y.set(this, yScale[i]);
            line.set(this, d3.line()
                .x(function (d) {
                    return x(d[0]);
                })
                .y(function (d) {
                    return ty(d[1]);
                })
            );
            area.set(this, d3.area()
                .x(function (d) {
                    return x(d[0]);
                })
                .y0(ty(0))
                .y1(function (d) {
                    return ty(d[1]);
                })
            );
        });

    seriesG.append("path")
        .datum(function (r) {
            return r.data;
        })
        .attr("class", "plot-line")
        .attr("stroke", function (d, i) {
            return color(i);
        })
        .attr("fill", "none")
        .style("opacity", showLines ? 1 : 0)
        .attr("d", function (d) {
            return line.get(this)(d);
        });

    var pointPlots = seriesG.append("g")
        .datum(function (r) {
            return r.data;
        })
        .attr("class", "plot-point")
        .style("opacity", showPoints ? 1 : 0)
        .attr("fill", function (d, i) {
            return color(i);
        });

    pointPlots.selectAll("circle.plot-point")
        .data(function (d) {
            return d;
        })
        .enter()
        .append("circle")
        .attr("class", "plot-point")
        .attr("cx", function (d) {
            return x(d[0]);
        })
        .attr("cy", function (d) {
            return y.get(this)(d[1]);
        })
        .attr("r", 2);

    seriesG.append("path")
        .datum(function (r) {
            return r.data;
        })
        .attr("class", "plot-area")
        .style("opacity", showAreas ? 0.5 : 0)
        .style("clip-path", "url(#clip)")
        .attr("fill", function (d, i) {
            return color(i);
        })
        .attr("d", function (d) {
            return area.get(this)(d);
        });

    focus.append("g")
        .attr("class", "brush")
        .on("mousedown", brushclicked)
        .call(brush);

    var cursor = focus.append("line")
        .attr("class", "d3-cursor")
        .attr("y1", 0)
        .attr("y2", height)
        .attr("stroke", "red")
        .style("display", "none");

    var tooltip = focus.append("g")
        .attr("class", "d3-tooltip")
        .style("display", "none");
    var ttBox = tooltip.append("rect")
        .attr("rx", 4)
        .attr("ry", 4);
    tooltip.append("circle")
        .attr("r", 4)
        .attr("fill", "none")
        .attr("stroke-width", 1)
        .attr("stroke", "red");
    var ttText = tooltip.append("text");
    var ttTextTimestamp = ttText
        .append("tspan")
        .attr("class", "timestamp")
        .attr("dy", "-1.2em");
    var ttTextValue = ttText
        .append("tspan")
        .attr("class", "value")
        .attr("dy", "0");
    var ttTextAKS = ttText
        .append("tspan")
        .attr("class", "aks")
        .attr("dy", "-2.4em");

    var legendBox = focus.append("g")
        .attr("class", "legend")
        .attr("transform", "translate(" + 8 + "," + 16 + ")");

    var legendBackground = legendBox.append("rect")
        .attr("fill", "white")
        .attr("stroke", "grey")
        .attr("stroke-width", 0.5)
        .style("opacity", 0.8);

    var legendItems = legendBox.selectAll("g.legend-item")
        .data(records.map(function (r) {
            return r.aks;
        }))
        .enter()
        .append("g")
        .attr("class", "legend-item");

    var legendTexts = legendItems.append("text")
        .style("font-size", "8pt")
        .style("font-family", "Helvetica")
        .attr("fill", function (d, i) {
            return color(i);
        })
        .text(function (d) {
            return d;
        });

    var maxLegendTextWith = d3.max(legendTexts.nodes().map(function (n) {
            return n.getBBox().width;
        })),
        legendTextHeight = legendTexts.nodes()[0].getBBox().height;

    legendBackground.attr("x", -2)
        .attr("y", -legendTextHeight)
        .attr("width", maxLegendTextWith + 4)
        .attr("height", records.length * (legendTextHeight + 4) + 2);

    legendItems.attr("transform", function (d, i) {
        return "translate(0," + i * (legendTextHeight + 4) + ")";
    });

    //$(App.d3ChartOptionsSel(parentSel) + " input[type=checkbox]").trigger("change");

    function brushed() {
        if (d3.event.selection != null) {
            var s = d3.event.selection;
            setTimeRangeInputs(s.map(x.invert, x), parentSel);
            tmEnableTimeRangeInputs(true, parentSel);
            $(parentSel).siblings(nav).find("input[name$=max-timerange]").prop("checked", false);
            if (parentSel === tmMainChartSel) {
                tmReloadMainChart();
            } else {
                tmReloadPreviewChart();
            }
        }
    }

    var brushDoubleClick = false;

    function brushclicked() {
        if (brushDoubleClick) {
            setTimeRangeInputs(null, parentSel);
            if (parentSel === tmMainChartSel) {
                tmReloadMainChart();
            } else {
                tmReloadPreviewChart();
            }
        } else {
            brushDoubleClick = true;
            setTimeout(function () {
                brushDoubleClick = false;
            }, 500);
        }
    }

    function hideCursor() {
        cursor.style("display", "none");
        tooltip.style("display", "none");
    }

    function mouseDist2(yScale, datapoint, mouseX, mouseY) {
        var xdiff = x(datapoint[0]) - mouseX,
            ydiff = yScale(datapoint[1]) - mouseY;
        return xdiff * xdiff + ydiff * ydiff;
    }

    function moveCursor() {
        var mouseX = d3.mouse(this)[0],
            mouseY = d3.mouse(this)[1],
            mouseDate = x.invert(mouseX);
        var nearDatapoints = records.map(function (record) {
            var ind;
            if (record.data.length > 1) {
                ind = Math.min(bisectDate(record.data, mouseDate, 1), record.data.length - 1);
                if ((record.data[ind - 1] != undefined) && (Math.abs(record.data[ind - 1][0] - mouseDate) < Math.abs(record.data[ind][0] - mouseDate))) {
                    ind -= 1;
                }
            } else {
                ind = 0;
            }
            return record.data[ind];
        });
        var index = 0,
            j;
        for (j = 0; j < nearDatapoints.length; j += 1) {
            if ((nearDatapoints[j] != null) && mouseDist2(yScale[j], nearDatapoints[j], mouseX, mouseY) < mouseDist2(yScale[index], nearDatapoints[index], mouseX, mouseY)) {
                index = j;
            }
        }

        var data = nearDatapoints[index],
            ysc = yScale[index],
            date = data[0],
            val = data[1],
            pointX = x(date),
            textX,
            pointY = ysc(val),
            textY;
        cursor
            .style("display", "")
            .attr("x1", pointX)
            .attr("x2", pointX);

        var textBBox = ttText.node().getBBox();

        if (pointX + textBBox.width <= width - 8) {
            textX = 6;
        } else {
            textX = -6 - textBBox.width;
        }
        if (pointY - textBBox.height >= 6) {
            textY = -6;
        } else {
            textY = 2 + textBBox.height;
        }
        tooltip
            .style("display", "")
            .attr("transform", "translate(" + pointX + "," + pointY + ")");
        ttText
            .attr("x", textX)
            .attr("y", textY);
        ttTextTimestamp.
        text(d3FormatWkdayTimestamp(date))
            .attr("x", textX)
            .attr("y", textY);
        ttTextValue
            .text(val + " " + records[index].si + " (" + records[index].unit + ")")
            .attr("x", textX)
            .attr("y", textY);
        ttTextAKS
            .text(records[index].aks)
            .attr("x", textX)
            .attr("y", textY);
        ttBox
            .attr("width", textBBox.width + 4)
            .attr("height", textBBox.height + 4)
            .attr("x", textX - 2)
            .attr("y", textY - textBBox.height);
    }
}

// 2017-07-25 / Matthias Kummer
// Click event handler for sensor select table
// change the selection status and reload the preview chart
var clickOnSensorTable = function (e) {
    if (!e.shiftKey) {
        $("#sensor-ids tr.selected").removeClass("selected");
    }
    $(this).toggleClass("selected");
    tmReloadPreviewChart();
};

function d3OptionCheckbox() {
    var sel = $(this).data("selector"),
        origOpacity = $(sel).data("orig-opacity") || 1;

    if (this.checked) {
        d3.selectAll(sel).transition().duration(400).style("opacity", origOpacity);
    } else {
        $(sel).data("orig-opacity", $(sel).css("opacity"));
        d3.selectAll(sel).transition().duration(400).style("opacity", 0);
    }
}

function tmExportDialog(e) {
    var exportSeriesTable,
        fileFormat = $(e.target).data("tm-export"),
        timeRange = getTimeRangeFromInputs(tmMainChartSel),
        freqFactor = 3600000 / (timeRange[1] - timeRange[0]);

    function tmGetExportSeriesData() {
        function calcFields(row) {
            return {
                sensorId: row[0],
                aks: row[2],
                totalCount: row[5],
                requestedCount: Math.min(row[5], 100000),
                frequency: 0
            };
        }
        return tmSensorsTable.data().map(calcFields);
    }

    function requestedCountInput(data) {
        return "<input type=number class=\"form-control form-control-sm form-control-dt\" value=\"" + data + "\" size=\"4\" data-requested-count></input>";
    }

    function freqString(count) {
        return (count * freqFactor).toLocaleString({
            maximumSignificantDigits: 4
        });
    }

    function requestedCountChanged(e) {
        if (e.ctrlKey || e.metaKey || e.key == "Meta" || e.key == "Alt" || e.key == "Tab") {
            return;
        }
        e.preventDefault();
        var reqCountInput = $(this),
            row = exportSeriesTable.row(this.parentNode).data();
        reqCountInput.val(Math.min(Math.min(row.totalCount, 1000000), reqCountInput.val()));
        $(this.parentNode).siblings(".frequency").html(freqString(this.value));
    }

    function sensorArray() {
        return exportSeriesTable.data().map(function (row, i) {
            return {
                sensor_id: row.sensorId,
                count: exportSeriesTable.cells(i, 3).nodes().toJQuery().find("input").val()
            };
        }).toArray();
    }

    function exportHandler(e) {
        var data,
            a = document.createElement("a"),
            project = $(selSensorSelectDialog).data("project");

        e.preventDefault();
        data = {
            ms_start: timeRange[0].getTime(),
            ms_end: timeRange[1].getTime(),
            sensors: sensorArray(),
            project: project
        };
        if (fileFormat == "xlsx") {
            a.href = "/tunnelmonitoring/export_xlsx";
        } else {
            a.href = "/tunnelmonitoring/export_csv";
        }
        a.search = $.param(data);
        window.location = a;
        $(tmExportDialogSel).modal("hide");
    }

    e.preventDefault();
    if (!$.fn.DataTable.isDataTable("table#tm-export-params")) {
        exportSeriesTable = $("table#tm-export-params").DataTable({
            columns: [{
                    data: "sensorId",
                    visible: false
                },
                {
                    data: "aks",
                    width: "55%"
                },
                {
                    data: "totalCount",
                    width: "15%"
                },
                {
                    data: "requestedCount",
                    render: requestedCountInput,
                    width: "15%"
                },
                {
                    data: "requestedCount",
                    render: freqString,
                    className: "frequency",
                    width: "15%"
                }
            ],
            dom: "tr",
            ordering: false,
            autoWidth: false
        });
    } else {
        exportSeriesTable = $("table#tm-export-params").DataTable();
    }
    exportSeriesTable.clear().rows.add(tmGetExportSeriesData()).draw();
    $("[data-requested-count]").on("keyup change", requestedCountChanged);
    $("#tm-export-dialog button[type=submit]").off("click").on("click", exportHandler);
    $(tmExportDialogSel).modal();
}

function tmSelectQuarterDialog(e) {
    function tmSelectQuarterDialogSubmit() {
        $(tmSelectQuarterDialogSel + " .modal-footer").hide();
    }

    e.preventDefault();
    $(tmSelectQuarterDialogSel + " form").submit(tmSelectQuarterDialogSubmit);
    $(tmSelectQuarterDialogSel).modal();
}

// install event handlers once upon execution of js bundle
$(document).on("click", "#sensor-ids tr", clickOnSensorTable);
$(document).on("change", selSensorSelectDialog + " .tm-manual-timerange", tmReloadPreviewChart);
$(document).on("change", selBrowserWindow + " .tm-manual-timerange", tmReloadMainChart);
$(document).on("change", ".d3-chart-options input[type=checkbox]", d3OptionCheckbox);
//$(document).on("change", selSensorSelectDialog + " #sel-max-timerange", toggleMaxTimerange);
$(document).on("change", selBrowserWindow + " #max-timerange", toggleMaxTimerange);
$(document).on("click", "#open-sensor-select-dialog", tmOpenSensorSelectDialog);
$(document).on("click", "#add-sensors-to-main-chart", tmFetchSeriesForAddingToMainChart);
$(document).on("click", "[data-show-hide]", showHideSeriesHandler);
$(document).on("click", "[data-tm-export]", tmExportDialog);
$(document).on("click", "#select-quarter-for-export", tmSelectQuarterDialog);

// run table initializer after turbolinks:load
initializers.push(initSeriesTable);