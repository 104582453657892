# 2018-02-13 / Matthias Kummer
#
# global handler of special keys
#
# so far, only left and right arrow keys are handled for
# - navigating back and forth between objects (usually used in show views;
#   generally for clicking prev_next arrows, potentially with shift modifier
#   for larger steps, e.g. in graphical_timetable_d3)
# - browsing through pages of paginated DataTables

import { initializers } from "./platin";
import { hideTooltips } from "./utils";

# arrow keypress is used for pagination only when no DOM element other than
# the pagination elements themenselves is selected
paginationTarget = (e) ->
  e.currentTarget.nodeName == "#document" ||  $(e.target).hasClass("page-link")

# arrow keypress is used for moving back or forth in a collection of objects
# only if no DOM element other than a «prev_next» arrow itself is selected
prevNextTarget = (e) ->
  e.currentTarget.nodeName == "#document" || $(e.target).hasClass("prev_next")

handleArrowKeys = ->
  $(document).on "keydown.arrow", (e) ->
    # immediately return if not a left or right arrow key or Cmd / Meta is pressed
    return true unless (e.keyCode == 37 || e.keyCode == 39 || e.metaKey)
    # immediately return if a BS or JQueryUI modal is on screen or an <input> field is active
    return true if (e.currentTarget.activeElement && e.currentTarget.activeElement.nodeName == "INPUT") || $(".modal-backdrop").hasClass("show") || $('.ui-dialog').is(':visible')
    if e.keyCode == 37 # left arrow key
      if e.shiftKey
        if prevNextTarget(e) && (left_arrow_shift = $("a.left_arrow_shift")).length > 0
          e.preventDefault()
          left_arrow_shift[0].click()
        else
          return true
      else
        if prevNextTarget(e) && (left_arrow = $("a.left_arrow")).length > 0
          e.preventDefault()
          left_arrow[0].click()
        else if paginationTarget(e) && (pagination_back = $(".paginate_button.previous")).length > 0
          e.preventDefault()
          pagination_back[0].click()
        else if ((fcPrev = $(".fc-prev-button")) && (fcPrev.length > 0))
          hideTooltips()
          fcPrev.closest(".fc").fullCalendar("prev")
        else
          return true
    if e.keyCode == 39 # right arrow key
      if e.shiftKey
        if prevNextTarget(e) && (right_arrow_shift = $("a.right_arrow_shift")).length > 0
          e.preventDefault()
          right_arrow_shift[0].click()
        else
          return true
      else
        if prevNextTarget(e) && (right_arrow = $("a.right_arrow")).length > 0
          e.preventDefault()
          right_arrow[0].click()
        else if paginationTarget(e) && (pagination_next = $(".paginate_button.next")).length > 0
          e.preventDefault()
          pagination_next[0].click()
        else if ((fcNext = $(".fc-next-button")) && (fcNext.length > 0))
          hideTooltips()
          fcNext.closest(".fc").fullCalendar("next")
        else
          return true


###App.handleAltKey = ->
  $(document).off("keydown.alt").on "keydown.alt", (e) ->
    return true unless e.key == "Alt"
    $("[data-editable-id]").addClass("altkey")
  $(document).off("keyup.alt").on "keyup.alt", (e) ->
    return true unless e.key == "Alt"
    $("[data-editable-id]").removeClass("altkey")
###

#initializers.push handleArrowKeys
handleArrowKeys()
