/* global $ */

import { initializers } from "platin";

const intallPlanningVersionSelector = function() {

    $("a.switch-planning-version").click(function(){
        var id = $(this).data("id"),
            url = $(this).data("url");
        $.post(`/planning_versions/${id}/select`, {url: url});
    });

};

const installPlanningScenarioSelector = function() {

    $("a.switch-planning-scenario").click(function(){
        var id = $(this).data("id"),
            url = $(this).data("url");
        $.post(`/planning_scenarios/${id}/select`, {url: url});
    });

};

initializers.push(intallPlanningVersionSelector);
initializers.push(installPlanningScenarioSelector);    

