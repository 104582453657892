/* global $, App */

import {
  postInit
} from "./platin";
import * as d3 from "d3";

let subscription, dialogTimestamp,
  importButton,
  channelOutput,
  progressBar,
  fileField,
  eta,
  etaString;
const format = d3.format(",.0%");

/* this contains the DOM node of the dialog */
function initDialog() {
  const dialog = this;
  importButton = $("#import-button", dialog),
  channelOutput = $("#channel-output", dialog),
  progressBar = $(".progress-bar", dialog),
  fileField = $("input#zipfile", dialog);
  eta = $(".eta", dialog),
  etaString = $("span.eta-string", eta);
  progressBar.css("width", "0%");
  progressBar.html("");
  channelOutput.html("Idle");
  importButton.removeClass("d-none");
  dialogTimestamp = Date.now();
  $("[name$='modal_timestamp']", dialog).val(dialogTimestamp);
  eta.addClass("d-none");
  etaString.html("");
  importButton.on("click", function(){
    if (!subscription){
      subscription = App.consumer.subscriptions.create(
        { channel: "ImportEnergyChannel" },
        { received: receiveMessage }
      );
    }
    importButton.addClass("d-none");
    channelOutput.html("Starte…");
  });
  fileField.on("change", watchFileField);
  watchFileField.call(fileField);
}

function receiveMessage(jsonString) {
  //console.log(`received ${jsonString}`);
  let data = JSON.parse(jsonString);
  if (data.dialog_timestamp != dialogTimestamp){
    return;
  }
  if (data.message) {
    //importButton.addClass("d-none");
    channelOutput.html(data.message);
  }
  if (data.fraction) {
    progressBar.css("width", 100*data.fraction + "%");
    progressBar.html(format(data.fraction));
    progressBar.removeClass("progress-bar-animated");
    progressBar.removeClass("progress-bar-striped");
  }
  if (data.eta) {
    eta.removeClass("d-none");
    etaString.html(data.eta);
  }
  if (data.empty) {
    importButton.removeClass("d-none");
    channelOutput.html(data.empty);
  }
  if (data.no_estimate) {
    progressBar.css("width", "100%");
    progressBar.addClass("progress-bar-animated");
    progressBar.addClass("progress-bar-striped");
    progressBar.html("Dauer unbekannt…");    
  }
  if (data.done) {
    importButton.removeClass("d-none");
    fileField.val(null);
    watchFileField.call(fileField);
    eta.addClass("d-none");
    progressBar.css("width", "100%");
    progressBar.html("Fertig.");    
    channelOutput.html("Import komplett.");
  }
}

function stopSubsription(){
  if (subscription){
    App.consumer.subscriptions.remove(subscription);
  }
  subscription = null;
}

function watchFileField(){
  importButton.prop("disabled", !$(this).val());
}

function installImportEnergyHandler(){
  $(document).on("shown.bs.modal", "#choose-energy-zip-modal", initDialog);
  $(document).on("hide.bs.modal", "#choose-energy-zip-modal", stopSubsription);
}

postInit("trains#energy_overview", installImportEnergyHandler);