import {
  postInit
} from "./platin";
import * as d3 from "d3";

function initEmailDeliveryStatistics() {
  var margin = {
      top: 10,
      right: 30,
      bottom: 30,
      left: 60
    },
    width = 1200 - margin.left - margin.right,
    height = 600 - margin.top - margin.bottom;

  // append the svg object to the body of the page
  var svg = d3.select("#email-deliveries-stats-chart")
    .append("svg")
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform",
      "translate(" + margin.left + "," + margin.top + ")");

  //Read the data
  d3.json("/email_deliveries/statistics.json", {
    credentials: "same-origin"
  }).then(function (rawData) {

    let data = rawData.map(function (d) {
      return {
        date: new Date(d.st),
        lead: d.lt
      };
    });


    // Add X axis --> it is a date format
    var x = d3.scaleTime()
      .domain(d3.extent(data, function (d) {
        return d.date;
      }))
      .range([0, width]);
    svg.append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x));

    // Add Y axis
    var y = d3.scaleLinear()
      .domain([0, d3.max(data, function (d) {
        return +d.lead;
      })])
      .range([height, 0]);
    svg.append("g")
      .call(d3.axisLeft(y));

    // Add the line
    svg.append("path")
      .datum(data)
      .attr("fill", "none")
      .attr("stroke", "steelblue")
      .attr("stroke-width", 1.5)
      .attr("d", d3.line()
        .x(function (d) {
          return x(d.date);
        })
        .y(function (d) {
          return y(d.lead);
        })
      );

  });
}

postInit("email_deliveries#statistics", initEmailDeliveryStatistics);