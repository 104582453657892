import $ from "jquery";
import {
    postInit
} from "./platin";
import * as d3 from "d3";
import {
    serializeAndSendSVG,
    d3FormatWkdayTimestampNoS,
    d3FormatTimeNoS
} from "d3utils";

const fontFamily = "Frutiger LT 45 Light, Frutiger LT 45, Helvetica, sans-serif";
const axisLabelFontSize = "10pt";


function tooltipText(d) {
    let startString = d3FormatWkdayTimestampNoS(new Date(d.start_time)),
        endString = d3FormatTimeNoS(new Date(d.end_time));
    return `${startString}–${endString}, Anzahl: ${d.number}<br>${d.m_name}`;
}

const getName = function (obj) {
    return obj.name;
};

const initPlanningOverview = function () {
    const totalHeight = window.innerHeight - 200,
        containerSelector = "#rs-plan-container",
        container = $(containerSelector),
        canReadMissions = $(containerSelector).data("readMissions");

    var margin = {
        top: 10,
        right: 30,
        bottom: 80,
        left: 180
    },
        width = $(containerSelector).innerWidth() - margin.left - margin.right,
        height = totalHeight - margin.top - margin.bottom,
        svg, enclosingG;

    import( /* webpackChunkName: "d3" */ 'd3').then(d3 => {

        d3.json("/rolling_stock_types/planning.json", {
            credentials: "same-origin"
        }).then(function (data) {
            if (data.length == 0) {
                return;
            }
            var dateRange = [App.testbetriebStart, App.testbetriebEnd];

            var x = d3.scaleTime()
                .domain(d3.extent(dateRange))
                .range([0, width]);
            var y = d3.scaleBand()
                .domain(data.map(getName))
                .range([0, height])
                .padding([0]);

            var xAxis = d3.axisBottom(x)
                .ticks(d3.timeWeek.every(2))
                .tickSize(-height)
                .tickFormat(d3.timeFormat("%d.%m"));

            var yAxis = d3.axisLeft(y)
                .tickSize(0);

            var tooltipContainer = $("<div>");
            tooltipContainer.appendTo(container);

            svg = d3.select(containerSelector)
                .append("svg")
                .attr("class", "d3-chart")
                .attr("font-family", fontFamily)
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom);

            enclosingG = svg.append("g")
                .attr("class", "enclosing")
                .attr("font-family", fontFamily)
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

            enclosingG.append("g")
                .attr("class", "axis axis--x")
                .attr("transform", "translate(0," + height + ")")
                .call(xAxis)
                .selectAll(".tick line").attr("stroke", "#ccc");

            enclosingG.append("g")
                .attr("class", "axis axis--y")
                .call(yAxis)
                .select(".domain").remove();

            enclosingG.selectAll(".axis--y g.tick text")
                .classed("pointer", true)
                .attr("data-rolling-stock-type-id", function () {
                    return data.find(d => (d.name == this.textContent)).id
                });

            $("[data-rolling-stock-type-id]").click(function () {
                window.open("/rolling_stock_types/" + $(this).data("rolling-stock-type-id"), "_blank");
            });

            enclosingG.selectAll("g.tick text")
                .attr("font-size", axisLabelFontSize);

            var rsGs = enclosingG.selectAll("g.rs-bar")
                .data(data)
                .enter()
                .append("g")
                .attr("class", "rs-bar")
                .attr("transform", function (d) {
                    return "translate(0," + y(d.name) + ")";
                });

            rsGs.selectAll("rect")
                .data(function (d) {
                    return d.timeshifts;
                })
                .enter()
                .append("rect")
                .attr("class", function (d) {
                    return `timeshift count-${d.number}`;
                })
                .attr("x", function (d) {
                    return x(new Date(d.start_time));
                })
                .attr("y", 0)
                .attr("width", function (d) {
                    return x(new Date(d.end_time)) - x(new Date(d.start_time));
                })
                .attr("height", y.bandwidth())
                .attr("title", tooltipText)
                .on("click", showMission);

            $("g.rs-bar rect").tooltip({
                container: tooltipContainer,
                animation: false,
                html: true
            });

            y.domain().forEach(function (yValue) {
                enclosingG.append("line")
                    .attr("x1", 0)
                    .attr("x2", width)
                    .attr("y1", y(yValue) - 1)
                    .attr("y2", y(yValue) - 1)
                    .attr("stroke", "#ccc");
            });

            $("svg", container)[0].prepareSVGForDownload = prepareSVGForDownload;

        })
    })

    $("#download-button").click(function (event) {
        let svg = prepareSVGForDownload();
        event.preventDefault();
        serializeAndSendSVG(svg, $(this).data("svg-filename") || "Tunnelbelegung.svg");
    });

    /* 2018-06-26 / Matthias Kummer
    create a new svg element with inline styles which can be serialized
    and downloaded as a file 
    */
    const prepareSVGForDownload = function () {
        let clonedSvg = document.querySelector(`${containerSelector} svg`).cloneNode(true),
            body = document.getElementsByTagName("BODY")[0],
            rects, axes, legendTexts;

        body.appendChild(clonedSvg);

        // set inline fill styles for all relevant elements, as many SVG editors cannot deal with CSS stylesheets
        // convert rgba to rgb/opacity, as some SVG editors cannot deal with rgba…
        rects = clonedSvg.querySelectorAll("rect.timeshift, g.legend rect");
        for (let i = 0; i < rects.length; ++i) {
            let fill = window.getComputedStyle(rects[i]).fill;
            rects[i].setAttribute("fill", fill);
        }
        legendTexts = clonedSvg.querySelectorAll("g.legend text");
        for (let i = 0; i < legendTexts.length; ++i) {
            let fill = window.getComputedStyle(legendTexts[i]).fill;
            legendTexts[i].setAttribute("fill", fill);
            legendTexts[i].setAttribute("font-size", "16pt");
            legendTexts[i].setAttribute("dy", "0.9em");
        }
        axes = clonedSvg.querySelectorAll("g.axis");
        for (let i = 0; i < axes.length; ++i) {
            axes[i].removeAttribute("font-family");
        }

        body.removeChild(clonedSvg);
        return clonedSvg;
    };

    const showMission = function () {
        if (!canReadMissions) {
            return false;
        }
        var url = `/missions/${d3.select(this).datum().m_id}`;
        window.open(url, "_blank");
    };

};

postInit("rolling_stock_types#planning", initPlanningOverview);