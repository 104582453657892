import {
  postInit
} from "./platin";
import $ from "jquery";

function initShow(){
  const modalSel = "#position-reports-modal",
    modal = $(modalSel);
  modal.on("shown.bs.modal", function(){
    $("#dt-position-reports", modal).DataTable().draw();
  });

}

postInit("trains#show", initShow);