import $ from "jquery";
import * as d3 from "d3";
import { interpolateBlues } from "d3-scale-chromatic";
import { postInit } from "./platin";

var formatPerc = d3.format(".1%");

function km(el){return el.km;}
function getKey(el){return el.key;}
function getValue(el){return el.value;}

// expects connectionDropEvents to be an array with the following structure:
// connectionDropEvents = [{vehicle_class: "xxx", …}, {vehicle_class: "yyy", …}, …]
function pieChart(connectionDropEvents){
    var svg = d3.select("svg.pie"),
        width = $("#pie-container").innerWidth(),
        height = +svg.attr("height"),
        radius = Math.min(width, height) / 2,
        g = svg.append("g").attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");
    svg.attr("width", width);

    var color = d3.scaleOrdinal(["#98abc5", "#8a89a6", "#7b6888", "#6b486b", "#a05d56", "#d0743c", "#ff8c00"]);

    // array of {key:/value:} pairs
    var dropsPerVehicleClass = d3.nest()
        .key(function(d){return d.vehicle_class;})
        .rollup(function(ar){return ar.length;})
        .entries(connectionDropEvents)
        .sort(function(a,b){return (a.value == b.value) ? 0 : ((a.value > b.value) ? -1 : 1);});

    var rest = dropsPerVehicleClass.slice(8,-1).reduce(function(total,el){return total + el.value;}, 0);
    var data = dropsPerVehicleClass.slice(0,7).concat([{key: "Rest", value: rest}]);
    var total = d3.sum(data, getValue);

    var pie = d3.pie()
        .sort(null)
        .value(function(d) { return d.value; });

    var path = d3.arc()
        .outerRadius(radius - 10)
        .innerRadius(0);

    var label = d3.arc()
        .outerRadius(radius - 40)
        .innerRadius(radius - 60);

    var arc = g.selectAll(".arc")
        .data(pie(data))
        .enter().append("g")
        .attr("class", "arc");

    arc.append("path")
        .attr("d", path)
        .attr("fill", function(d,i) { return interpolateBlues(1-i/data.length); });

    var labels = g.selectAll(".label")
        .data(pie(data))
        .enter().append("g")
        .attr("class", "label");

    labels.append("text")
        .attr("transform", function(d) { return "translate(" + label.centroid(d) + ")"; })
        .attr("dy", "0.35em")
        .attr("text-anchor", "middle")
        .attr("fill", function(d,i){return i/data.length < 0.3 ? "white" : "black";})
        .text(function(d) { return d.data.key; });
    labels.append("text")
        .attr("transform", function(d) { return "translate(" + label.centroid(d) + ")"; })
        .attr("dy", "1.35em")
        .attr("text-anchor", "middle")
        .attr("fill", function(d,i){return i/data.length < 0.3 ? "white" : "black";})
        .text(function(d,i) { return (i < 4 || i == data.length-1) ? formatPerc(d.data.value/total) : ""; });
}

// expects connectionDropEvents to be an array with the following structure:
// connectionDropEvents = [{track: "xxx", …}, {track: "yyy", …}, …]
function trackChart(connectionDropEvents){
    var countByTrack = d3.nest()
        .key(function(d){return d.track;})
        .rollup(function(ar){return ar.length;})
        .entries(connectionDropEvents)
        .sort(function(a,b){if(a.key<b.key){return -1;}if(a.key>b.key){return 1;}return 0;});

    var svg = d3.select("svg.histo"),
        margin = {top: 20, right: 30, bottom: 30, left: 30},
        //width = +svg.attr("width") - margin.left - margin.right,
        width = $("#histo-container").innerWidth() - margin.left - margin.right,
        height = +svg.attr("height") - margin.top - margin.bottom,
        g = svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    svg.attr("width", width + margin.left + margin.right);

    svg.append("g")
        .attr("transform", "translate(" + (margin.left + width/2) + ",30)")
        .append("text")
        .attr("class", "chart-title")
        .attr("text-anchor", "middle")
        .text("Connection-drops pro Gleis");

    var tracks = countByTrack.map(getKey);
    var x = d3.scaleBand()
        .domain(tracks)
        .range([0, width])
        .padding(0.1);
    var xAxis = d3.axisBottom(x)
        .tickValues(x.domain().filter(function(d,i){ return !(i%25);}));

    var y = d3.scaleLinear()
        .domain([0, 1.1*d3.max(countByTrack, function(d) { return d.value; })])
        .range([height, 20]);        
    var yAxis = d3.axisLeft(y)
        .tickSize(-width);

    g.append("g")
        .attr("class", "axis axis--x")
        .attr("transform", "translate(0," + height + ")")
        .call(xAxis);
    g.append("g")
        .attr("class", "axis axis--y")
    //.attr("transform", "translate(0," + height + ")")
        .call(yAxis);

    var bar = g.selectAll(".bar")
        .data(countByTrack)
        .enter().append("g")
        .attr("class", "bar")
        .attr("transform", function(d) { return "translate(" + x(d.key) + "," + y(d.value) + ")"; });

    var expand = function(){
        var rect = d3.select(this),
            w = +rect.attr("width");
        rect.attr("width", w*2);
        rect.attr("x", -w/2);
        click.call(this);
    };
    var shrink = function(){
        var rect = d3.select(this);
        rect.attr("width", x.bandwidth());
        rect.attr("x", 0);
    };
    var click = function(){
        var selection = d3.select(this).datum(),
            track = selection.key,
            eventsInTrack = connectionDropEvents.filter(function(el){return el.track == track;}),
            kmRange = d3.extent(eventsInTrack, km);
        d3.select("svg.km").selectAll("*").remove();
        kmChart(connectionDropEvents, kmRange[0], kmRange[1], track);
        ttText.text(track);       
        tooltip.attr("transform", "translate(" + x(track) + "," + y(selection.value) + ")");
    };
    bar.append("rect")
        .attr("x", 0)
        .attr("width", x.bandwidth())
        .attr("height", function(d) { return height - y(d.value); })
        .on("mouseover", expand)
        .on("mouseout", shrink);

    var tooltip = g.append("g")
        .attr("class", "chart-tt");
    var ttText = tooltip.append("text")
        .attr("dy", "-0.75em")
        .attr("x", 0)
        .attr("y", 0)
        .attr("text-anchor", "middle")
        .attr("fill", "black")
        .text("");    
}

// expects connectionDropEvents to be an array with the following structure:
// connectionDropEvents = [{km: "xxx", …}, {km: "yyy", …}, …]
function kmChart(connectionDropEvents, km1, km2, track){
    var formatCount = d3.format(",.0f");

    var svg = d3.select("svg.km"),
        margin = {top: 40, right: 30, bottom: 30, left: 30},
        width = $("#km-container").innerWidth() - margin.left - margin.right,
        height = +svg.attr("height") - margin.top - margin.bottom,
        g = svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    svg.attr("width", width + margin.left + margin.right);

    svg.append("g")
        .attr("transform", "translate(" + (margin.left + width/2) + ",30)")
        .append("text")
        .attr("class", "chart-title")
        .attr("text-anchor", "middle")
        .text("Verteilung im Gleis " + track);

    var x = d3.scaleLinear()
        .range([0, width])
        .domain([km1, km2]);
    var xAxis = d3.axisBottom(x).ticks(5);

    var bins = d3.histogram()
        .domain(x.domain())
        .thresholds(x.ticks(10))
        .value(km)(connectionDropEvents);
    window.bins = bins;

    var y = d3.scaleLinear()
        .domain([0, d3.max(bins, function(d) { return d.length; })])
        .range([height, 0]);
    var yAxis = d3.axisLeft(y)
        .tickSize(-width);

    g.append("g")
        .attr("class", "axis axis--x")
        .attr("transform", "translate(0," + height + ")")
        .call(xAxis);
  
    g.append("g")
        .attr("class", "axis axis--y")
        .call(yAxis);

    var nBins = bins.length,
        binWidth = width/nBins;

    var bar = g.selectAll(".bar")
        .data(bins)
        .enter().append("g")
        .attr("class", "bar")
        .attr("transform", function(d,i) { return "translate(" + i*binWidth + "," + y(d.length) + ")"; });

    bar.append("rect")
        .attr("x", 1)
        .attr("width", binWidth-2)
        .attr("height", function(d) { return height - y(d.length); });

    bar.append("text")
        .attr("dy", ".75em")
        .attr("y", 6)
        .attr("x", binWidth / 2)
        .attr("text-anchor", "middle")
        .text(function(d) { return d.length > y.domain()[1]/20 ? formatCount(d.length) : ""; });
}

function vcChart(connectionDropEvents){
    var countByVc = d3.nest()
        .key(function(d){return d.vehicle_class;})
        .rollup(function(ar){return ar.length;})
        .entries(connectionDropEvents)
        .sort(function(a,b){return b.value-a.value;});

    var svg = d3.select("svg.vc"),
        margin = {top: 20, right: 30, bottom: 30, left: 30},
        //width = +svg.attr("width") - margin.left - margin.right,
        width = $("#vc-container").innerWidth() - margin.left - margin.right,
        height = +svg.attr("height") - margin.top - margin.bottom,
        g = svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    svg.attr("width", width + margin.left + margin.right);

    svg.append("g")
        .attr("transform", "translate(" + (margin.left + width/2) + ",30)")
        .append("text")
        .attr("class", "chart-title")
        .attr("text-anchor", "middle")
        .text("Connection-drops pro Fahrzeugtyp");

    var vcs = countByVc.map(getKey);
    var x = d3.scaleBand()
        .domain(vcs)
        .range([0, width])
        .padding(0.1);
    var xAxis = d3.axisBottom(x)
        .tickValues([]);

    var y = d3.scaleLinear()
        .domain([0, 1.1*d3.max(countByVc, function(d) { return d.value; })])
        .range([height, 20]);        
    var yAxis = d3.axisLeft(y)
        .tickSize(-width);

    g.append("g")
        .attr("class", "axis axis--x")
        .attr("transform", "translate(0," + height + ")")
        .call(xAxis);
    g.append("g")
        .attr("class", "axis axis--y")
        .call(yAxis);

    var bar = g.selectAll(".bar")
        .data(countByVc)
        .enter().append("g")
        .attr("class", "bar")
        .attr("transform", function(d) { return "translate(" + x(d.key) + "," + height + ")"; });            

    bar.append("rect")
        .attr("x", 0)
        .attr("y", function(d){ return y(d.value)-height; } )
        .attr("width", x.bandwidth())
        .attr("height", function(d) { return height - y(d.value); });

    bar.append("text")
        .attr("dy", "1.35em")
        .attr("transform", "rotate(-90)")
        .attr("x", 4)
        .attr("text-anchor", "start")
        .text(function(d) { return d.key; });
}

const initCharts = function(){
    d3.json("/rbc_connection_drops/d3_chart_data.json", {credentials: "same-origin"}).then(function(data){
        trackChart(data);
        pieChart(data);
        vcChart(data);
    });
};

postInit("rbc_connection_drops#vehicle_class_chart", initCharts);