/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import {
  busy,
  parseParams
} from "utils";
import $ from "jquery";

// import Turbolinks from "turbolinks";

$(document).on("turbolinks:load", function () {
  $("#choose-rbc-date, #choose-rbc-date-graphical").datepicker({
    //showOn: "both"
    dateFormat: "dd.mm.yy"
  });
  //buttonText: "<i class='fa fa-calendar' title='Datum wählen…'></i>"

  $("#choose-rbc-date").change(function () {
    var url = "/trains/date?date=DATE".replace("DATE", $(this).val());
    busy();
    return window.location = url;
  });

  $("input.refresh-graphical-timetable").change(function () {
    var normalize_km = $("input[name=\"normalize_km\"]")[0].checked;
    var show_all = $("input[name=\"show_all\"]")[0].checked;
    var all_points = $("input[name=\"all_points\"]")[0].checked;
    var l2only = $("input[name=\"l2only\"]")[0].checked;
    var start_time = $("input#start_time").val();
    var end_time = $("input#end_time").val();
    var date = $("input#choose-rbc-date-graphical").val();
    var url = "/trains/graphical_timetable?all_points=ALL_POINTS&date=DATE&end_time=END_TIME&l2only=L2ONLY&start_time=START_TIME"
      .replace("DATE", date)
      .replace("START_TIME", start_time)
      .replace("END_TIME", end_time)
      .replace("ALL_POINTS", all_points)
      .replace("L2ONLY", l2only) +
      "&normalize_km=" + normalize_km +
      "&show_all=" + show_all;
    busy();
    return window.location = url;
  });

  $("#refresh-graphical-timetable-d3").click(function () {
    var selection, show_all;
    var area = $(this).data("area");
    if ((selection = $("input[name=\"show_all\"]")).length > 0) {
      show_all = selection[0].checked;
    } else {
      show_all = false;
    }
    var all_points = $("input[name=\"all_points\"]")[0].checked;
    var start_time = $("input#start_time").val();
    var end_time = $("input#end_time").val();
    var date = $("input#choose-rbc-date-graphical").val();
    var url = "/trains/graphical_timetable_d3?all_points=ALL_POINTS&area=AREA&date=DATE&end_time=END_TIME&l2only=L2ONLY&start_time=START_TIME"
      .replace("AREA", area)
      .replace("DATE", date)
      .replace("START_TIME", start_time)
      .replace("END_TIME", end_time)
      .replace("ALL_POINTS", all_points) +
      "&show_all=" + show_all;
    busy();
    return window.location = url;
  });

  $("input.refresh-daily-category-chart").change(function () {
    var area = $(this).data("area");
    var l2only = $(this)[0].checked;
    var url = "/trains/daily_category_chart?area=AREA&l2only=L2ONLY".
    replace("AREA", area).
    replace("L2ONLY", l2only);
    busy();
    return window.location = url;
  });

  $("input.refresh-category-chart").change(function () {
    var area = $(this).data("area");
    var l2only = $(this)[0].checked;
    var url = "/trains/category_chart?area=AREA&l2only=L2ONLY".
    replace("AREA", area).
    replace("L2ONLY", l2only);
    busy();
    return window.location = url;
  });

  $("input.refresh-graphical-timetable-lvx").change(function () {
    var normalize_km = $("input[name=\"normalize_km\"]")[0].checked;
    var all_points = $("input[name=\"all_points\"]")[0].checked;
    var l2only = $("input[name=\"l2only\"]")[0].checked;
    var start_time = $("input#start_time").val();
    var end_time = $("input#end_time").val();
    var date = $("input#choose-rbc-date-graphical").val();
    var url = "/trains/graphical_timetable_lvx?all_points=ALL_POINTS&date=DATE&end_time=END_TIME&l2only=L2ONLY&start_time=START_TIME"
      .replace("DATE", date)
      .replace("START_TIME", start_time)
      .replace("END_TIME", end_time)
      .replace("ALL_POINTS", all_points)
      .replace("L2ONLY", l2only) +
      "&normalize_km=" + normalize_km;
    busy();
    return window.location = url;
  });

  $("input[name=\"normalize_km\"]").change(function () {
    var a = document.createElement("a");
    a.href = window.location;
    var params = parseParams(a.search);
    params["normalize_km"] = this.checked;
    a.search = $.param(params);
    busy();
    return window.location = a.href;
  });

  $("input[name=\"show_all\"]").change(function () {
    var a = document.createElement("a");
    a.href = window.location;
    var params = parseParams(a.search);
    params["show_all"] = this.checked;
    a.search = $.param(params);
    busy();
    return window.location = a.href;
  });

  return $("a[data-include-check-boxes]").click(function (e) {
    var param;
    e.preventDefault();
    var include_check_boxes = $(this).data("include-check-boxes");
    var a = document.createElement("a");
    a.href = $(this).attr("href");
    var params = parseParams(a.search);
    for (var _i = 0, _Array$from = Array.from(include_check_boxes); _i < _Array$from.length; _i++) {
      param = _Array$from[_i];
      (function (param) {
        var checkbox = $("input[name=\"" + param + "\"]")[0];
        return params[param] = checkbox.checked;
      })(param);
    }
    if (e.shiftKey) {
      params["shift"] = "true";
    }
    a.search = $.param(params);
    busy();
    return window.location = a.href;
  });
});