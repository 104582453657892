import {
  postInit,
  preInit
} from "./platin";
import $ from "jquery";

import consumer from "./channels/consumer";

function subscribeToWebRequests() {

  let table = $("table#dt-web-requests-table");

  const subscription = consumer.subscriptions.create("WebRequestChannel", {
    connected: function () {},
    // Called when the subscription is ready for use on the server

    disconnected: function () {},
    // Called when the subscription has been terminated by the server

    received: function (data) {
      if (table.length > 0) {
        var rowData = [
          data.id,
          data.timestamp,
          data.user,
          data.ip,
          data.user_agent,
          data.method,
          data.xhr,
          data.path
        ];
        table.DataTable().row.add(rowData).draw();
      }
    }
  });

  $(document).one("turbolinks:visit", () => consumer.subscriptions.remove(subscription));
}

/*
/ 2020-08-21 running experiments trying to speed up by caching filtered count
/   though was not feasible, there is no good way avoiding hitting the database
/   as soon as the filter string changes (which is the standard use case…)
function setAjaxFn() {
  let table = $("table#dt-web-requests-table"),
    ajaxObj = {
      url: table.data("ajax"),
      data: function (dtData) {
        let dt = table.DataTable(),
          pageInfo = dt.page.info();
        dtData.recordsFiltered = pageInfo.recordsDisplay;
        dtData.recordsTotal = pageInfo.recordsTotal;
        console.log("recordsTotal = ", pageInfo.recordsTotal);
        console.log("recordsDisplay = ", pageInfo.recordsDisplay);
      }
    };
  table.data("ajax", ajaxObj);
}
*/

// preInit("web_requests#index", setAjaxFn);
postInit("web_requests#index", subscribeToWebRequests);