import $ from "jquery";
import {
  showBusy,
  hideBusy,
  hideTooltips
} from "./utils";
import * as d3 from "d3";

function initDebriefingInfoTable() {
  $("table#dt-debriefing-info")[0].createdRow = function (row, data) {
    $(row).data("filter", `mstatus-${data.mstatus} tnt-${data.tnt} df-${data.df.status} goal-${data.df.goal} repeat-${data.df.repeat}`);
  };

  function parseDebriefingMinutes() {
    const tr = $(this).closest("tr"),
      dt = $("table#dt-debriefing-info").DataTable(),
      row = dt.row(tr[0]),
      oldData = row.data();
    let url;
    if (window.location.href.match(/main_breaker_events/)) {
      url = `/missions/${oldData.id}/parse_debriefing_minutes_from_main_breaker_events_table`;
    } else {
      url = `/missions/${oldData.id}/parse_debriefing_minutes_from_table`;
    }

    function updateRow(data) {
      hideBusy();
      row.data(data);
    }

    showBusy();
    hideTooltips();
    $.post(url, updateRow, "json");
  }
  $("table#dt-debriefing-info").on("click", ".fa.fa-refresh", parseDebriefingMinutes);
}

function debriefingInfoStatChart() {

  const createPieChart = function (data, category) {
    const selector = `#stat-chart-${category}`,
      container = $(selector),
      width = $(container).attr("width") || 48,
      height = $(container).attr("height") || 48,
      radius = Math.min(width, height) / 2 - 4;

    function getSvg(selector) {
      let svg = d3.select(selector)
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", `translate(${width / 2}, ${height / 2})`)
        .attr("class", "debriefing-info-color");
      return svg;
    }

    const pie = d3.pie()
      .value(d => d.count)
      .sort(null);

    const arc = d3.arc()
      .innerRadius(0)
      .outerRadius(radius);

    const svg = getSvg(selector);
    const path = svg.selectAll("path")
      .data(pie(data));

    path.enter().append("path")
      .attr("class", (d) => `pie-segment ${category}-${d.data.status}`)
      .attr("d", arc)
      .attr("stroke", "grey")
      .attr("stroke-width", "1px")
      .attr("title", (d) => `${d.data.label}<br>${d.data.count}<br>${d.data.perc}%`)
      .each(function (d) {
        this._current = d;
      });
  };

  d3.json("/debriefing_infos/stats.json").then(data => {
    for (var category in data) {
      createPieChart(data[category], category);
    }
    $("path.pie-segment").tooltip({
      html: true
    });
  });
}

export {
  initDebriefingInfoTable,
  debriefingInfoStatChart
};