/* 2018-02-09 / Matthias Kummer
   focus elements per page
*/
/* globals $ */

import {
  initializers
} from "./platin";
import Cookies from "js-cookie/src/js.cookie";

// div contains the enclosing div.table-controls
function table_filter(div) {
  const checkBoxes = $("input[id^=\"show_\"]", div).sort((a, b) => ($(a).data("rank") || 0) > ($(b).data("rank") || 0));
  checkBoxes.each(function () {
    const selector = `.${$(this).attr("id").match(/^show_(.*)$/)[1]}`,
      checkbox = $(this);
    if (!checkbox.is(":checked") && !checkbox.data("hide-after")) {
      $(selector).hide();
    } else if (checkbox.is(":checked")) {
      $(selector).show();
    } else if (!checkbox.is(":checked") && checkbox.data("hide-after")) {
      $(selector).hide();
    }
  });

  /*
  $("input[id^=\"show_\"]:not(:checked):not([data-hide-after])", div).each(function () {
    var selector = "." + $(this).attr("id").match(/^show_(.*)$/)[1];
    $(selector).hide();
  });
  $("input[id^=\"show_\"]:checked", div).each(function () {
    var selector = "." + $(this).attr("id").match(/^show_(.*)$/)[1];
    $(selector).show();
  });
  $("input[id^=\"show_\"][data-hide-after]:not(:checked)", div).each(function () {
    var selector = "." + $(this).attr("id").match(/^show_(.*)$/)[1];
    $(selector).hide();
  });*/
  $(".table_filter_hide").hide(); // needed for additional filters (e.g. location filter in timeline)() {
}

/* 2020-04-15 / Matthias Kummer
   used for only applying «negative» settings, i.e. hiding classes that should
   be hidden without forcefully showing classes for checked checkboxes
   (as they might have been hidden by another filter before)
   typically called at the end of an update callback of a second filter
   e.g. TNT filter in tunnel_occupation */
function tableFilterOnlyHide(div) {
  const checkBoxes = $("input[id^=\"show_\"]", div).sort((a, b) => ($(a).data("rank") || 0) > ($(b).data("rank") || 0));
  checkBoxes.each(function () {
    const selector = `.${$(this).attr("id").match(/^show_(.*)$/)[1]}`,
      checkbox = $(this);
    if (!checkbox.is(":checked")) {
      $(selector).hide();
    }
  });
  $(".table_filter_hide").hide(); // needed for additional filters (e.g. location filter in timeline)() {
}

function initTableFilter() {
  $(".table-controls").each(function () {
    let div = this,
      cookieName = $(this).data("cookie");

    // initialize to cookie values
    if (cookieName) {
      $("input[id^=\"show_\"]", this).each(function () {
        let cookie = Cookies.get(cookieName + "-" + this.name);
        if (cookie) {
          $(this).prop("checked", JSON.parse(cookie));
        }
      });
    }

    $("input[id^=\"show_\"]", this).click(function (e) {
      if (cookieName) {
        Cookies.set(cookieName + "-" + e.target.name, $(e.target).is(":checked"));
      }
      if (e.shiftKey) {
        $(e.target)
          .siblings("input[id^=\"show_\"]")
          .prop("checked", $(e.target).is(":checked"))
          .each(function () {
            if (cookieName) {
              Cookies.set(cookieName + "-" + this.name, $(e.target).is(":checked"));
            }
          });
      }
      table_filter(div);
    });

    table_filter(div);
  });

  $(".dropdown.platin-class-filter").each(function () {
    let dropdown = this,
      cookieName = $(this).data("cookie");

    // initialize to cookie values
    if (cookieName) {
      $("input[id^=\"show-\"]", this).each(function () {
        let cookie = Cookies.get(cookieName + "-" + this.name);
        if (cookie) {
          $(this).prop("checked", JSON.parse(cookie));
        }
      });
    }

    $("input[id^=\"show-\"]", this).click(function (e) {
      if (cookieName) {
        Cookies.set(cookieName + "-" + e.target.name, $(e.target).is(":checked"));
      }

      if (e.shiftKey) {
        const filterIndex = $(this).data("filter-index");
        $(e.target)
          .closest(".dropdown").find(`input[id^="show-"][data-filter-index=${filterIndex}]`)
          .prop("checked", $(e.target).is(":checked"))
          .each(function () {
            if (cookieName) {
              Cookies.set(cookieName + "-" + this.name, $(e.target).is(":checked"));
            }
          });
      }

      platinClassFilter(dropdown);
    });

    platinClassFilter(dropdown);
  });

}

function platinClassFilter(div) {
  const checkBoxes = $("input[id^=\"show-\"]", div).sort((a, b) => ($(a).data("rank") || 0) > ($(b).data("rank") || 0));
  $(".platin-class-filter").show();
  checkBoxes.each(function () {
    const selector = `.platin-class-filter.filter-${$(this).attr("id").match(/^show-(.*)$/)[1]}`,
      checkbox = $(this);
    if (!checkbox.is(":checked")) {
      $(selector).hide();
    }
  });
}


initializers.push(initTableFilter);
//initializers.push(table_filter);

export {
  table_filter,
  initTableFilter,
  tableFilterOnlyHide,
  platinClassFilter
};