import $ from "jquery";

const explanationUpdateAttributeSelect = function (data) {
    var select = $("select#explanation_attribute_name");
    select.html("");
    $("<option value=''></option>").appendTo(select);
    data.forEach(function (attr) {
        var option = $("<option value='" + attr[0] + "'>" + attr[1] + " (" + attr[0] + ")</option>");
        option.appendTo(select);
    });
};

const explanationClassSelectChangeHandler = function (event) {
    $.post("/explanations/get_attribute_names", {
        class_name: $("option:selected", event.target).val()
    }, explanationUpdateAttributeSelect, "json");
};

const explanationUpdateValueSelect = function (data) {
    const select = $("select#explanation_value"),
        formGroup = select.closest(".form-group");
    if (!data) {
        formGroup.addClass("d-none");
    } else {
        formGroup.removeClass("d-none")
        select.html("");
        $("<option value=''></option>").appendTo(select);
        data.forEach(function (value) {
            var selected, option;
            if (value.value == select.data("selected")) {
                selected = " selected";
            } else {
                selected = "";
            }
            option = $("<option value='" + value.value + "'" + selected + ">" + value.label + " (" + value.value + ")</option>");
            option.appendTo(select);
        });
    }
};

const explanationValueSelectChangeHandler = function (event) {
    $.post("/explanations/get_values.json", {
        class_name: $(event.target).data("class-name"),
        attr_name: $("option:selected", event.target).val()
    }, explanationUpdateValueSelect);
};


export {
    explanationUpdateAttributeSelect,
    explanationClassSelectChangeHandler,
    explanationValueSelectChangeHandler
};