/*
    2018-06-19 / Matthias Kummer
    
    usage: add data-shortcut="a" to <a> elements for triggering
           a click on this link upon keypress of the "a" key

    The handler is installed on the <body> upon turbolinks.load,
    only if there are any elements with a data-shortcut attribute.
    When adding such links AFTER the initial creation of the body,
    installShortcutHandler has to be called explicitely.
*/

import {
  initializers
} from "./platin";
import $ from "jquery";

const installShortcutHandler = function () {
  var linksWithShortcuts = $("[data-shortcut]"),
    shortcuts = {};
  if (linksWithShortcuts.length > 0) {
    linksWithShortcuts.each(function (i, e) {
      let letter = $(e).data("shortcut"),
        index = $(e).text().toLocaleLowerCase().indexOf(letter);

      shortcuts[letter] = $(e);

      // replace the first occurrance of the shortcut letter with a span.underline
      if (index >= 0) {
        let fullText = $(e).text();
        $(e).html($(e).text().slice(0, index) + "<span class=\"underline\">" + fullText.slice(index, index + 1) + "</span>" + fullText.slice(index + 1));
      }

    });
    $(document).off("keydown.shortcut").on("keydown.shortcut", function (e) {
      var el;
      // immediately return if a BS or JQueryUI modal is on screen or an <input> field is active
      if ((e.currentTarget.activeElement && e.currentTarget.activeElement.nodeName == "INPUT") ||
                $(".modal-backdrop").hasClass("show") || $(".ui-dialog").is(":visible") ||
                e.metaKey || e.ctrlKey) {
        return;
      }
      if (e.currentTarget.nodeName == "#document") {
        el = shortcuts[e.key];
        if (el) {
          el.click();
        }
      }
    });
  }
};

initializers.push(installShortcutHandler);
export {
  installShortcutHandler
};