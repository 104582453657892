import {
  postInit
} from "./platin";
import $ from "jquery";
import moment from "moment";
import {
  activateAutocomplete
} from "./autocomplete";
import {
  prepareSvgForDisplay
} from "./locations";
import {
  hideTooltips
} from "./utils";

function autoFormatDuration() {
  let s = $(this).val(),
    splits = s.split(":"),
    secs;
  // one number only => treat as minute
  if (splits.length == 1) {
    splits = [splits[0], "0"];
  }
  secs = splits.reduce((acc, val) => acc * 60 + parseInt(val), 0);
  if (isNaN(secs)) {
    secs = 0;
  }
  $(this).val(moment(secs * 1000).utc().format("HH:mm:ss"));
}

const categoriesWithVmax = ["train_run"];
const categoriesWithTrainNr = ["train_run", "train_logistics", "team_movement", "moving_to_perimeter", "moving_from_perimeter"];
const attributes = ["id", "_destroy", "pos", "teams", "category",
  "duration", "description", "specialties", "vmax",
  "location", "train_nr"
]
const selectors = attributes.reduce((obj, val) => {
  obj[val] = `[id$=${val}]`;
  return obj;
}, {});
const nameRegex = /^(tdb\[tdb_lines_attributes\]\[)\d+(\].+)$/
const idRegex = /^(tdb_tdb_lines_attributes_)\d+(_.+)$/

const initTdbEditor = function () {

  const table = $("table.tdb-lines"),
    numTeams = getNumTeams();

  function updateTdbStartTime() {
    let year = $("#tdb_start_time_1i").val(),
      month = $("#tdb_start_time_2i").val(),
      day = $("#tdb_start_time_3i").val(),
      hr = $("#tdb_start_time_4i").val(),
      min = $("#tdb_start_time_5i").val(),
      date = new Date(year, month, day, hr, min);
    $(table).data("start-time", date);
  }

  function getNumTeams() {
    const fieldValue = parseInt($("input[name='tdb[num_teams]").val());
    if (isNaN(fieldValue)) {
      return 1;
    } else {
      return fieldValue;
    }
  }

  /* returns an array of team indices active in this <tr> */
  function getSelectedTeamsForTr(tr) {
    const checkboxes = $("[id*='teams']", tr);
    let activeIndices = [];
    checkboxes.each((i, el) => $(el).is(":checked") ? activeIndices.push(i) : null);
    return activeIndices;
  }

  /* sets the text content of td to hh:mm-hh-mm or 
     hh:mm:ss-hh:mm:ss, depending of whether seconds
     precision is needed or not
     duration is in seconds
  */
  function setStepTimesText(td, startTime, duration) {
    let endTime = moment(startTime).add(duration, "s"),
      format;
    if (startTime.second() != 0 || endTime.second() != 0) {
      format = "HH:mm:ss"
    } else {
      format = "HH:mm"
    };
    $(td).html(`${startTime.format(format)}-${endTime.format(format)}`);
  }

  function recalculatePositions() {
    {
      let startTime = $(table).data("start-time"),
        lastStartTime = startTime,
        offsets = (new Array(numTeams)).fill(0); // offset per team
      $("tbody tr", table).not(".to-remove").each(function (i, tr) {
        let tdPos = $("td.pos", this),
          tdTimes = $("td.step-times", this),
          s, lineDuration,
          currentStartTime,
          maxOffset, // the highest offset from startTime for all active teams
          teamIndices = getSelectedTeamsForTr(this);

        $("span", tdPos).html(i + 1);
        $("input", tdPos).val(i + 1);

        maxOffset = Math.max(...teamIndices.map(i => offsets[i]));
        currentStartTime = moment(startTime).add(maxOffset, "s");

        $(tr).toggleClass("sequence-error", (currentStartTime < lastStartTime));
        lastStartTime = currentStartTime;

        s = $(selectors.duration, tr).val();
        lineDuration = s.split(":").reduce((acc, val) => acc * 60 + parseInt(val), 0);
        if (isNaN(lineDuration)) {
          lineDuration = 0;
        }
        setStepTimesText(tdTimes, currentStartTime, lineDuration);
        teamIndices.forEach(i => offsets[i] = maxOffset + lineDuration);
      });

      $("tbody tr", table).each(function (i, tr) {
        /* adjust all name and id attributes for the updated index */
        $("[name^='tdb[tdb_lines_attributes][']", tr).each(function () {
          let res = $(this).attr("name").match(nameRegex);
          $(this).attr("name", `${res[1]}${i}${res[2]}`);
          if ($(this).attr("id") && (res = $(this).attr("id").match(idRegex))) {
            $(this).attr("id", `${res[1]}${i}${res[2]}`);
          }
        })
        // treat <trix-editor> separately as is has no name attribute
        $("trix-editor", tr).each(function () {
          let parts = $(this).attr("id").match(idRegex);
          $(this).attr("id", `${parts[1]}${i}${parts[2]}`);
          parts = $(this).attr("input").match(idRegex);
          $(this).attr("input", `${parts[1]}${i}${parts[2]}`);
        })
      });
    }
  }

  function categoryChanged() {
    let tr = $(this).closest("tr"),
      locTo = $(selectors.location_to, tr),
      vmax = $(selectors.vmax, tr),
      trainNr = $(selectors.train_nr, tr);
    /*if (categoriesWithFromTo.includes($(this).val())) {
      locTo.show();
    } else {
      locTo.hide();
    }*/
    if (categoriesWithVmax.includes($(this).val())) {
      vmax.show();
    } else {
      vmax.hide();
    }
    if (categoriesWithTrainNr.includes($(this).val())) {
      trainNr.show();
    } else {
      trainNr.hide();
    }
  }

  function initializeNewTrixEditor(jqTrixEditor, clear = false) {
    let ids = $("trix-toolbar").toArray().map((t) => parseInt($(t).attr("id").match(/\d+$/))),
      nextID = Math.max(...ids) + 1,
      trixToolbar = $("trix-toolbar", $(jqTrixEditor.closest("td")));

    trixToolbar.attr("id", `trix-toolbar-${nextID}`);
    jqTrixEditor.attr("toolbar", `trix-toolbar-${nextID}`);
    jqTrixEditor.attr("trix-id", `trix-${nextID}`);
    if (clear) {
      jqTrixEditor.one("trix-initialize", () => jqTrixEditor[0].editor.loadHTML(""));
    }
  }

  function addLine(e) {
    e.preventDefault();
    let oldTr = $(this).closest("tr"),
      newTr = oldTr.clone(true, true),
      trixEditor = $("trix-editor", newTr);

    $("input[type=text], input[type=hidden], textarea", newTr).val("");
    $("select", newTr).val([]);
    $(".empty-on-add", newTr).html("");
    initializeNewTrixEditor(trixEditor, true);
    newTr.insertAfter(oldTr);
    $("[data-autocomplete-url]", newTr).each(activateAutocomplete);
    categoryChanged.call($(selectors.category, newTr));
    $(selectors.location, newTr).focus();
    recalculatePositions()
  }

  function duplicateLine(e) {
    e.preventDefault();
    let oldTr = $(this).closest("tr"),
      newTr = oldTr.clone(true, true),
      trixEditor = $("trix-editor", newTr);

    $("input[type=hidden][id$=id]", newTr).val("");
    initializeNewTrixEditor(trixEditor, false);
    newTr.insertAfter(oldTr);
    recalculatePositions();
    categoryChanged.call($(selectors.category, newTr));
    $(selectors.location, newTr).focus();
  }

  function removeLine(e) {
    e.preventDefault();
    let tr = $(this).closest("tr"),
      destroyAttribute = $(selectors._destroy, tr);
    // new line (not persisted)? => just remove
    if ($("input[name$='id]']", tr).attr("value") == "") {
      hideTooltips();
      tr.remove();
    } else { // persisted line => toggle mark as to be destroyed
      if (tr.hasClass("to-remove")) {
        tr.removeClass("to-remove");
        destroyAttribute.val("0");
      } else {
        tr.addClass("to-remove");
        destroyAttribute.val("1");
      }
    }
    recalculatePositions();
  }

  import( /* webpackChunkName: "tablednd" */ "tablednd/js/jquery.tablednd").then(() => {
    table.tableDnD({
      dragHandle: ".drag-handle",
      onDragStop: recalculatePositions
    });
  })

  function focusTrixEditor(event) {
    const editorField = $(this),
      formerEditorField = $("trix-editor.active");
    formerEditorField.removeClass("active").siblings("trix-toolbar").hide();
    editorField.addClass("active").siblings("trix-toolbar").show();
    editorField.closest("table").DataTable().columns.adjust();
  }

  /* blurs a TrixEditor field if an element receives focus on a different tr*/
  function blurTrixEditor() {
    const trReceivingFocus = $(this),
      formerEditorField = $("trix-editor.active");
    /* only blur if an element on a tr with inactive editor receives focus */
    if (!$("trix-editor", trReceivingFocus).hasClass("active")) {
      $(formerEditorField).removeClass("active").siblings("trix-toolbar").hide();
    }
  }

  $("th.recalculate-positions").addClass("pointer").click(recalculatePositions);
  $(table).on("focusout", selectors.duration, autoFormatDuration);
  $(table).on("click", ".add-line", addLine);
  $(table).on("click", ".duplicate-line", duplicateLine);
  $(table).on("click", ".remove-line", removeLine);
  $(table).on("change", selectors.category, categoryChanged);
  $(selectors.category).each(categoryChanged);
  $(table).on("focusin", "trix-editor", focusTrixEditor);
  $(table).on("focusin", "tr", blurTrixEditor);
  $("select[id^=tdb_start_time]").change(() => {
    updateTdbStartTime();
    recalculatePositions()
  });
}

function installShowTdbCode() {
  const mapPopoverDiv = $("#tdb-locations-popover");
  if (mapPopoverDiv.length == 0) {
    return;
  };
  const planWidth = 600,
    svg = $("svg", mapPopoverDiv)[0],
    viewBox = svg.viewBox.baseVal,
    aspectRatio = viewBox.width / viewBox.height;

  function showLocationPopover(event) {
    const td = $(this),
      popoverTemplate = "<div class=\"popover location-map perimeter-map\" role=\"tooltip\"><div class=\"arrow\"></div><h3 class=\"popover-header text-primary\"></h3><div class=\"popover-body\"></div></div>";
    let popover = td.data("bs.popover"); // will be defined only if popover has been initialized already

    function initPopover() {
      const locKeys = td.data("locs");
      prepareSvgForDisplay(svg, locKeys);
      mapPopoverDiv.removeClass("d-none");
      svg.setAttribute("width", `${planWidth}px`);
      svg.setAttribute("height", `${planWidth / aspectRatio}px`);
      if (!popover) {
        popover = td.popover({
          content: mapPopoverDiv,
          html: true,
          template: popoverTemplate
        }).data("bs.popover")
      };
    }

    function showOrHidePopover() {
      if (event.type == "mouseenter") {
        popover.show();
      } else {
        popover.hide();
      }
    }

    initPopover();
    showOrHidePopover();
  }

  $("[data-location-popover]").hover(showLocationPopover);
}

postInit("tdbs#edit", initTdbEditor);
postInit("tdbs#update", initTdbEditor);
postInit("tdbs#show", installShowTdbCode);

export {
  installShowTdbCode
}