/* global locale */

import {
    postInit
} from "./platin";
import {
    dtAddDefaultButtons
} from "./datatable-initializers";
import {
    resetselectStaffForm
} from "./staff";
import $ from "jquery";

const peopleIndexDataTable = function () {
    import( /* webpackChunkName: "dt" */ "packs/datatables").then(function () {

        if ($("#people-table").length == 0 || $.fn.DataTable.isDataTable("#people-table")) {
            return;
        }
        let canDestroy = $("#people-table").data("can-destroy"),
            personPath = `/${locale}/people/_ID_`,
            organizationPath = `/${locale}/organizations/_ID_`,
            ouPath = `/${locale}/organizational_units/_ID_`;

        const person_link = function (data, type, row) {
            if ((type == "display") && data !== null) {
                return ("<a href=\"" + personPath.replace("_ID_", row.id) + "\">" + data + "</a>");
            } else {
                return data;
            }
        };

        const links = function (data, type, row) {
            if (type == "display") {
                let show = `<a href="/${locale}/people/${row.id}"><i class="fa fa-eye"></i></a>`,
                    edit = `<a href="/${locale}/people/${row.id}/edit"><i class="fa fa-pencil"></i></a>`,
                    destroy = `<a data-confirm="Soll ${row.name} wirklich gelöscht werden?" class="delete_x" rel="nofollow" data-method="delete" href="/${locale}/people/${row.id}"><i class="fa fa-trash"></i></a>`;
                if (canDestroy) {
                    return show + " " + edit + " " + destroy;
                } else if (row.edit) {
                    return show + " " + edit;
                } else {
                    return show;
                }
            } else {
                return data;
            }
        };

        const org_link = function (data, type, row) {
            if ((type == "display") && data !== null) {
                return ("<a href=\"" + organizationPath.replace("_ID_", row.orgid) + "\">" + data + "</a>");
            } else {
                return data;
            }
        };

        const ou_link = function (data, type, row) {
            if ((type == "display") && data !== null) {
                return ("<a href=\"" + ouPath.replace("_ID_", row.ouid) + "\">" + data + "</a>");
            } else {
                return data;
            }
        };

        let people_dt = $("#people-table").DataTable({
            ajax: "/people/index_json_simple",
            dom: "ftir",
            order: [
                [1, "asc"]
            ],
            lengthChange: false,
            scrollX: true,
            scrollY: "calc(100vh - 320px)",
            pageLength: 25,
            stateSave: true,
            processing: true,
            columns: [{
                    data: "fname",
                    render: person_link
                },
                {
                    data: "lname",
                    render: person_link
                },
                {
                    data: "code",
                    render: person_link
                },
                {
                    data: "email"
                },
                {
                    data: "phone"
                },
                {
                    data: "org",
                    render: org_link
                },
                {
                    data: "ou",
                    render: ou_link
                },
                {
                    data: "id",
                    render: links
                },
            ],
            initComplete: function (settings) {
                dtAddDefaultButtons(people_dt);
                $(settings.nTableWrapper).find("input[type='search']").focus();
                $(".dataTables_filter input[type=search]").on("search keyup", function () {
                    people_dt.search(
                        neutraliseAccents(this.value) // neutralise accents and tabs in filter string
                    ).draw();
                });
            }
        });

        /* accent neutralisation according to
         * https://datatables.net/forums/discussion/36473/datatables-search-filter-special-characters-with-html-data
         * hint by rca 2018-08-14 22:31
         */
        const neutraliseAccents = function (string) {
            return string.replace(/[áÁàÀâÂäÄãÃåÅæÆ]/g, "a")
                .replace(/[çÇ]/g, "c")
                .replace(/[éÉèÈêÊëË]/g, "e")
                .replace(/[íÍìÌîÎïÏîĩĨĬĭ]/g, "i")
                .replace(/[ñÑ]/g, "n")
                .replace(/[óÓòÒôÔöÖœŒ]/g, "o")
                .replace(/[ß]/g, "ss")
                .replace(/[úÚùÙûÛüÜ]/g, "u")
                .replace(/[ýÝŷŶŸÿ]/g, "y")
                .replace(/\t/g, " ");
        };

        let _div = document.createElement("div");
        const neutraliseAccentsHtml = function (data) {
            _div.innerHTML = data;
            return _div.textContent ? neutraliseAccents(_div.textContent) : neutraliseAccents(_div.innerText);
        };

        $.fn.dataTable.ext.type.search.html = neutraliseAccentsHtml;
        $.fn.dataTable.ext.type.search.string = neutraliseAccents;
    })
};

function handleNewlyRegisteredPerson() {
    const newPersonModal = $("#new-person-modal"),
        newPersonForm = $("#new-person-form", newPersonModal),
        registerNewPersonUrl = newPersonForm.attr("action"),
        submitButton = $("input[type=submit]", newPersonForm);

    function processResult(result) {
        newPersonModal.modal("hide");
        resetselectStaffForm(true);
    };

    newPersonModal.on("shown.bs.modal", () => {
        $("input[name=firstname]", newPersonModal).focus();
    })

    submitButton.click(function (event) {
        const formData = new FormData(newPersonForm[0]);
        event.preventDefault();
        $.ajax({
            type: "POST",
            enctype: "multipart/form-data",
            url: registerNewPersonUrl,
            data: formData,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 800000,
            success: processResult
        });
    })

}

postInit("people#index", peopleIndexDataTable);

export {
    handleNewlyRegisteredPerson
}