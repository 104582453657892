import {
  postInit
} from "./platin";
import $ from "jquery";


function initPhotoBooth() {
  var width = 210; // Width of the video
  var height = 173; // Height of the video
  var streaming = false; // No streaming before startup
  var video = null;
  var canvas = null;
  var photo = null;
  var startbutton = null;
  let savebutton;

  function startup() {
    video = document.getElementById("video");
    canvas = document.getElementById("canvas");
    photo = document.getElementById("photo");
    startbutton = document.getElementById("startbutton");
    savebutton = document.getElementById("savebutton");

    navigator.mediaDevices.getUserMedia({
      video: true,
      audio: false
    })
      .then(function (stream) {
        video.srcObject = stream;
        video.play();
      })
      .catch(function (err) {
        console.log("An error occurred: " + err); // Print error
      });

    video.addEventListener("canplay", function () {
      if (!streaming) {
        // Set dimensions
        video.setAttribute("width", width);
        video.setAttribute("height", height);
        canvas.setAttribute("width", height);
        canvas.setAttribute("height", width);
        photo.setAttribute("width", height);
        photo.setAttribute("height", width);
        streaming = true; // Begin streeming video
      }
    }, false);

    startbutton.addEventListener("click", function (ev) {
      takepicture(); // Take a picture
      ev.preventDefault();
    }, false);

    savebutton.addEventListener("click", capturePhoto);
    clearphoto();
  }

  // Grey rectangular where the picture will be placed
  function clearphoto() {
    var context = canvas.getContext("2d");
    context.fillStyle = "#DDD";
    context.fillRect(0, 0, canvas.width, canvas.height);
    var data = canvas.toDataURL("image/png");
    photo.setAttribute("src", data);
  }

  // Take a picture of the current contents of the video
  function takepicture() {
    var context = canvas.getContext("2d");
    if (width && height) {
      context.drawImage(video, 120, 0, 390, 480, 0, 0, height, width); // Crop video image to ID-badge-picture
      var data = canvas.toDataURL("image/png"); // Convert to PNG
      photo.setAttribute("src", data);
      $(savebutton).removeClass("d-none");

    } else {
      clearphoto();
    }
  }

  function capturePhoto() {
    let picture = $(photo).attr("src"),
      id = $(photo).data("id");
    stopCamera();
    $("a[href='#foto-tab']").tab("show");
    $.post(`/people/${id}/capture_photo`, {
      photo: picture
    });
  }

  function stopCamera() {
    let stream = video.srcObject;
    if (stream) {
      stream.getTracks().forEach(function (track) {
        track.stop();
      });
    }
  }

  $("a[href='#photo_booth-tab']").on("shown.bs.tab", startup);
  $("a[href='#photo_booth-tab']").on("hidden.bs.tab", stopCamera);

  $("#show-photo_booth-tab").click((e) => {
    e.preventDefault();
    $("a[href='#photo_booth-tab']").removeClass("d-none").tab("show");
  });
}

postInit("people#show", initPhotoBooth);

export { initPhotoBooth };