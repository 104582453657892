# Usage:
# - define an element with class "dropzone"
# - add data-type data-id containing class name and id of the object, the file(s) are to be attached to (optional)
# - add data-url containing the path of the action handling the uplad (defaults to /documents/drop_file)
# - implement the controller action for the url
#
# 2019-01-06 / Matthias Kummer
# extension with release notes dialog
# if there is a div.release-notes in the DOM, this div is treated as a Bootstrap modal (shown using $(div).modal("show")) and
# expected to have a) a <textarea id="comment_text"> and b) a <input type="submit" class="upload-file">
# if the dialog is closed using said submit input, {relnotes: "contents of release notes textarea"} is added to the form data
# submitted during file upload

import { initializers } from "./platin"

installDropzoneHandler = ->
  #$('.dropzone').off('dragenter dragover').on 'dragenter dragover', (event) ->
  $("body").off("dragenter dragover").on "dragenter dragover", ".dropzone", (event) ->
    event.stopPropagation()
    event.preventDefault()
    $(@).addClass('dragover')

  $('.card-header a[data-toggle="collapse"]').off('dragenter').on 'dragenter', (event) ->
    id = $(event.currentTarget).attr("href")
    event.stopPropagation()
    event.preventDefault()
    $(id).collapse('toggle')

  $(".open-on-dragenter a[data-toggle='tab']").off("dragenter").on "dragenter", (event) ->
    id = $(event.currentTarget).tab("show")
    event.preventDefault()

  $("body").off("dragleave drop").on "dragleave drop", ".dropzone", (event) ->
    event.stopPropagation()
    event.preventDefault()
    $(@).removeClass('dragover')

  $("body").off("drop").on "drop", ".dropzone", (dropEvent) ->
    files = dropEvent.originalEvent.dataTransfer.files
    droppedOn = $(@)
    # check if the user has to be queried for release notes
    relnotesDialog = $("#relnotes-dialog")
    relnotes = ""
    locationCategory = ""
    if (relnotesDialog.length > 0) && droppedOn.data("relnotes")
      # console.log "relnotesDialog present"
      $("input[type=\"submit\"][disabled]", relnotesDialog).attr("disabled", null)
      relnotesDialog.modal("show").on "shown.bs.modal", ->
        $("form", @).off("submit").submit (event) ->
          # window.data = event
          event.preventDefault()
          $(relnotesDialog).modal("hide")
          relnotes = $("form textarea#version_comment").val()
          locationCategory = $("form input#location_category").val()
          $(relnotesDialog).modal("hide")
          $('#busy_indicator').fadeIn(200)
          handleFileUpload(files, droppedOn, relnotes, locationCategory)
    else
      $('#busy_indicator').fadeIn(200)
      handleFileUpload(files, droppedOn, relnotes)
    $(@).removeClass('dragover')

  $("body").on "drop dragover", ".dropzone", (event) ->
    event.preventDefault()

  # 2018-08-22 / Matthias Kummer
  # example:
  # within a .dropzone div, add fa_icon("trash", class: "cursor-pointer remove-link", data: {remove_url: ajax-url-which-removes-file-attribute})
  # the corresponding controller action must respond with JSON {status: boolean, message: string[, also_clear: CSS-sel]}
  # optional selector get's cleared as well
  # see e.g. in FileHelper#subject_link_plus_preview_link and meetings_controller#remove_invitation
  # 2020-01-25 / Matthias Kummer
  # add .remove-container feature: put the document link in a span.remove-link to have only the one document removed
  # (and not the contents of the entire .dropzone, which is the default behaviour)
  $("body").on "click", ".dropzone .remove-link", (e) ->
    dropzoneContainer = $(@).closest(".dropzone")
    removeContainer = $(@).closest(".remove-container")
    clearField = (json) ->
      data = JSON.parse json
      $(data.also_clear).html("") if data.also_clear
      if data.status == true
        $("[data-toggle='tooltip']", dropzoneContainer).tooltip("hide") # hide tooltips on elements we're gonna remove
        if (removeContainer.length > 0) 
          if removeContainer[0].nextSibling && removeContainer[0].nextSibling.nodeName == "#text"
            removeContainer[0].nextSibling.remove()
          removeContainer.remove()
        else 
          dropzoneContainer.html("")
        App.flashMessage(data.message, "success")
      else
        App.flashMessage(data.message, "danger")
    $.get $(@).data("remove-url"), clearField, "script"

handleFileUpload = (files, droppedOnObj, relnotes, locationCategory) ->    
  # console.log "handleFileUpload"
  type = droppedOnObj.data('parent-class')
  id = droppedOnObj.data('parent-id')
  url = droppedOnObj.data('url')
  attribute = droppedOnObj.data('attribute')
  template = droppedOnObj.data('template')
  redirectPath = droppedOnObj.data("redirect-path")
  roles = droppedOnObj.data("roles")
  for file in files
    fd = new FormData();
    fd.append('file', file)
    fd.append('parent_type', type) if type?
    fd.append('parent_id', id) if id?
    fd.append('attribute', attribute) if attribute?
    fd.append('template', template) if template?
    fd.append('redirect_path', redirectPath) if redirectPath?
    fd.append("stay", true) if droppedOnObj.hasClass("dropzone-stay")
    fd.append("relnotes", relnotes) if relnotes != ""
    fd.append("location_category", locationCategory) if locationCategory != ""
    fd.append("roles", roles) if roles?

    sendFileToServer(fd, url)

sendFileToServer = (formData, url) ->
  # console.log "sendFileToServer"
  uploadURL = url || "/documents/drop_file"
  jqXHR=$.ajax
    xhr: ->
      xhrobj = $.ajaxSettings.xhr()
      xhrobj.upload
      return xhrobj

    url: uploadURL
    type: "POST"
    dataType: "script"
    contentType:false
    processData: false
    cache: false
    data: formData

initializers.push installDropzoneHandler
export { installDropzoneHandler, handleFileUpload, sendFileToServer }