const $ = require("jquery");
const d3 = require("d3");
import Cookies from "js-cookie/src/js.cookie";
import {
  postInit
} from "platin";
import {
  busy,
  hideBusy,
} from "utils";
import moment from "moment";
import {
  serializeAndSendSVG,
  d3FormatWkdayTimestampNoS,
  d3FormatTimeNoS
} from "d3utils";

const initProbebetrieb = function () {
  const containerSelector = "#shift-slots-container",
    margin = {
      top: 16,
      right: 0,
      bottom: 30,
      left: 10
    },
    dateColWidth = 70,
    height = 4000 - margin.top - margin.bottom,
    cookie = Cookies.get("shift-slots-labels"),
    dateFormat = d3.timeFormat("%a %d.%m.%Y"),
    oneDayInMs = 24 * 3600000,
    fontFamily = "Frutiger LT 45 Light, Frutiger LT 45, Helvetica, sans-serif";

  var width, tunnelWidth, startDate, endDate,
    dayHeight,
    baseSvg, svg,
    x, yEast, yWest,
    y, yinv,
    pt,
    datePointer;

  const prepareSvg = function () {
    let someDay = new Date(2020, 4, 1);
    baseSvg = d3.select(containerSelector)
      .append("svg")
      .attr("class", "d3-chart")
      .attr("font-family", fontFamily)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom);
    svg = baseSvg
      .append("g")
      .attr("class", "enclosing")
      .attr("font-family", fontFamily)
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    x = d3.scaleLinear()
      .domain([0, 24])
      .range([dateColWidth, dateColWidth + tunnelWidth]);
    yEast = d3.scaleLinear()
      .domain(d3.extent([startDate, endDate]))
      .range([0, height]);
    dayHeight = yEast(new Date(someDay.getTime() + oneDayInMs)) - yEast(someDay);
    yWest = d3.scaleLinear()
      .domain(d3.extent([startDate, endDate]))
      .range([dayHeight / 2, height + dayHeight / 2]);
    y = yEast;
    yinv = yEast.invert;
    pt = baseSvg.node().createSVGPoint();
    $(baseSvg.node()).mousemove(moveDatePointer);
    datePointer = baseSvg.append("polygon")
      .attr("id", "datePointer")
      .attr("points", "0,0 6,3 0,6 0,0")
      .attr("transform", "translate(0, " + margin.top + dayHeight / 2 + ")")
      .attr("display", "none")
      .attr("fill", "blue");
    $(containerSelector + " svg")[0].prepareSVGForDownload = prepareSVGForDownload;
  };

  // specialDatesMs is an array of arrays [startTimeInMilliseconds, endTimeInMilliseconds, "title"]
  const drawGrid = function (specialDatesMs) {
    let specialDates = specialDatesMs.map(function (ds) {
      return {
        start: new Date(ds.start),
        end: new Date(ds.end),
        text: ds.text
      };
    });
    svg.selectAll("line.gridX")
      .data(d3.timeDays(startDate, endDate))
      .enter()
      .append("line")
      .attr("class", "gridX")
      .attr("x1", 0)
      .attr("x2", width)
      .attr("y1", function (d) {
        return y(d);
      })
      .attr("y2", function (d) {
        return y(d);
      })
      .attr("stroke", "grey")
      .attr("stroke-width", "0.5");
    svg.append("rect")
      .attr("class", "borderBox")
      .attr("x", 0.5)
      .attr("width", width - 1)
      .attr("y", y.range()[0] + 0.5)
      .attr("height", height - 1)
      .attr("stroke", "grey")
      .attr("stroke-width", "1")
      .attr("fill", "none");
    svg.append("line")
      .attr("x1", dateColWidth - 0.5)
      .attr("x2", dateColWidth - 0.5)
      .attr("y1", y.range()[0])
      .attr("y2", y.range()[1])
      .attr("stroke", "grey")
      .attr("stroke-width", "1");

    // mark all saturdays and sundays
    svg.selectAll("rect.dateWeekend")
      .data(d3.timeDays(startDate, endDate).filter(function (d) {
        let wkday = d.getDay();
        return wkday == 0 || wkday == 6;
      }))
      .enter()
      .append("rect")
      .attr("class", "dateWeekend")
      .attr("x", 1)
      .attr("y", function (d) {
        return y(d) + 0.5;
      })
      .attr("width", dateColWidth - 2)
      .attr("height", dayHeight - 1);

    // mark all special days
    svg.selectAll("rect.dateInfo")
      .data(specialDates)
      .enter()
      .append("rect")
      .attr("class", "dateInfo")
      .attr("x", 1)
      .attr("y", function (d) {
        return y(d.start) + 0.5;
      })
      .attr("width", dateColWidth - 2)
      .attr("height", function (d) {
        return y(d.end) - y(d.start) - 1;
      });

    svg.selectAll("text.dateLabel")
      .data(d3.timeDays(startDate, endDate))
      .enter()
      .append("text")
      .attr("class", "dateLabel")
      .attr("alignment-baseline", "central")
      .attr("text-anchor", "middle")
      .attr("x", dateColWidth / 2)
      .attr("y", function (d) {
        return y(d);
      })
      .attr("font-size", 8)
      .attr("dy", dayHeight / 2)
      .text(function (d) {
        return dateFormat(d);
      });

    // tooltips for dateInfo rects (above labels for improved user experience)
    svg.selectAll("rect.dateInfoTooltipTarget")
      .data(specialDates)
      .enter()
      .append("rect")
      .attr("class", "dateInfoTooltipTarget")
      .attr("x", 1)
      .attr("y", function (d) {
        return y(d.start) + 0.5;
      })
      .attr("width", dateColWidth - 2)
      .attr("height", function (d) {
        return y(d.end) - y(d.start) - 1;
      })
      .attr("title", function (d) {
        return d.text;
      })
      .attr("fill", "rgba(255,255,255,0)");

    $("rect.dateInfoTooltipTarget").tooltip({
      container: "body",
      placement: "left"
    });

    svg.append("text")
      .attr("x", margin.left + dateColWidth + tunnelWidth / 2)
      .attr("y", 0)
      .attr("dy", "-0.35em")
      .text("Tunnel")
      .attr("font-size", 12)
      .attr("text-anchor", "middle");
  };

  const extractDate = function (date) {
    return (new Date(date)).setHours(0, 0, 0, 0);
  };

  const extractTime = function (d) {
    var date = new Date(d);
    return date.getHours() + date.getMinutes() / 60;
  };

  const highlightG = function () {
    $(this).addClass("highlight");
  };

  const unHighlightG = function () {
    $(this).removeClass("highlight");
  };

  const handleClickOnShift = function (d) {
    if (!$(this).hasClass("timeshift")) {
      return;
    }
    var url = "/shift_slots/__ID__".replace("__ID__", d.shift_slot_id);
    window.open(url, "_blank");
  };

  const handleClickOnMission = function (d) {
    d3.event.stopPropagation();
    var url = "/missions/__ID__".replace("__ID__", d.mission_dbid);
    window.open(url, "_blank");
  };

  // eastWest == 'east' / 'west'
  const drawTunnel = function (data, svg, eastWest) {
    let y;

    if (eastWest == "east") {
      y = yEast;
    } else {
      y = yWest;
    }

    function segmentTransform(d) {
      let xCoord = x(extractTime(d.start_time)),
        yCoord = y(extractDate(d.start_time));
      return `translate(${xCoord},${yCoord})`;
    }

    var shiftGs = svg.selectAll("g.timeshift." + eastWest)
      .data(data)
      .enter()
      .append("g")
      .attr("font-size", 8)
      .attr("data-id", function (d) {
        return d.id;
      })
      .attr("class", function (d) {
        var occupiedClass = "";
        if (d.occupied) {
          //occupiedClass = " occupied";
        }

        return "timeshift " + eastWest + " case-" + d.case+occupiedClass;
      })
      .on("mouseover", highlightG)
      .on("mouseout", unHighlightG)
      .on("click", handleClickOnShift);

    var segmentGs = shiftGs.selectAll("g.segment")
      .data(function (d) {
        return d.segments;
      })
      .enter()
      .append("g")
      .attr("class", "segment")
      .attr("transform", segmentTransform)
      .attr("title", d => `${d3FormatWkdayTimestampNoS(d.ss_st)}-${d3FormatTimeNoS(d.ss_et)}<br>${d.name}`);

    $(baseSvg.node()).tooltip({
      selector: "g.segment, g.mission-label",
      animation: false,
      placement: "right",
      container: "header",
      html: true
    }).on("show.bs.tooltip", function () {
      $("g.segment, g.mission-label").tooltip("hide");
    });

    segmentGs
      .append("rect")
      .attr("class", "segment")
      .attr("width", function (d) {
        return x(extractTime(d.end_time)) - x(extractTime(d.start_time));
      })
      .attr("height", dayHeight / 2);

    segmentGs
      .append("text")
      .classed("name", true)
      .attr("text-anchor", "middle")
      .attr("alignment-baseline", "center")
      .attr("x", function (d) {
        return (x(extractTime(d.end_time)) - x(extractTime(d.start_time))) / 2;
      })
      .attr("y", dayHeight / 4)
      .attr("dy", "0.35em")
      .text(function (d) {
        return d.name_trunc;
      });

    var missionLabelGs = segmentGs
      .filter((d) => "timeshifts" in d)
      .selectAll("g.mission-label")
      .data(function (d) {
        return d.timeshifts;
      })
      .enter()
      .append("g")
      .classed("mission-label", true)
      .attr("title", d => `${d3FormatWkdayTimestampNoS(d.ts_st)}-${d3FormatTimeNoS(d.ts_et)}<br>${d.mission_id}: ${d.mission_name}`)
      .on("click", handleClickOnMission);

    missionLabelGs
      .append("rect")
      .attr("x", (d, i) => i * 40 + 2)
      .attr("y", 3.5)
      .attr("width", 36)
      .attr("height", 12)
      .style("fill", "#ccc")
      .style("opacity", "0.9")
      .attr("rx", 3)
      .attr("ry", 3)
      .attr("stroke", "gray");
    missionLabelGs
      .append("text")
      .attr("class", "mission-id")
      .attr("x", (d, i) => i * 40 + 20)
      .attr("y", 12.5)
      .attr("text-anchor", "middle")
      .text(d => d.mission_id);

    //.text("\uf005");
    //.text("\uf07c");

  };

  function showMissionLabels() {
    if ($("#show-mission-labels").prop("checked")) {
      d3.selectAll(".mission-label").transition().style("opacity", 1);
    } else {
      d3.selectAll(".mission-label").transition().style("opacity", 0);
    }
  }
  /*const showLabels = function () {
    var klass = $("input[name=toggle_labels]:checked").data("class");
    Cookies.set("occupation-plan-labels", klass);
    if (klass == "aid") {
      d3.selectAll("text.name").transition().style("opacity", 0);
      d3.selectAll("text.tnt").transition().style("opacity", 0);
      d3.selectAll("text.aid").transition().style("opacity", 1);
    } else if (klass == "name") {
      d3.selectAll("text.name").transition().style("opacity", 1);
      d3.selectAll("text.tnt").transition().style("opacity", 0);
      d3.selectAll("text.aid").transition().style("opacity", 0);
    } else {
      d3.selectAll("text.name").transition().style("opacity", 0);
      d3.selectAll("text.tnt").transition().style("opacity", 1);
      d3.selectAll("text.aid").transition().style("opacity", 0);
    }
  };*/

  function moveDatePointer(event) {
    let cursorPoint, date;
    pt.x = event.clientX;
    pt.y = event.clientY;
    cursorPoint = pt.matrixTransform(baseSvg.node().getScreenCTM().inverse());
    date = new Date(new Date(yinv(cursorPoint.y + dayHeight / 2)).setHours(0, 0, 0, 0));
    datePointer
      .attr("transform", "translate(0, " + Math.max((y(date) - 3), margin.top) + ")")
      .attr("display", null);
  }

  function calcDimensions() {
    width = Math.round($(containerSelector).innerWidth()) - margin.left - margin.right - 20;
    tunnelWidth = (width - dateColWidth);
  }

  /*  2018-06-26 / Matthias Kummer
      create a new svg element with inline styles which can be serialized
      and downloaded as a file 
  */
  const prepareSVGForDownload = function () {
    let clonedSvg = document.querySelector("#shift-slots-container svg").cloneNode(true),
      body = document.getElementsByTagName("BODY")[0],
      rects;

    body.appendChild(clonedSvg);

    // set inline fill styles for all relevant elements, as many SVG editors cannot deal with CSS stylesheets
    // convert rgba to rgb/opacity, as some SVG editors cannot deal with rgba…
    rects = clonedSvg.querySelectorAll("g.timeshift rect, g.timeshift text, rect.dateWeekend, rect.dateInfo, rect.dateInfoTooltipTarget");
    for (let i = 0; i < rects.length; ++i) {
      let fill = window.getComputedStyle(rects[i]).fill,
        rgba = fill.match(/rgba\(([\d.]+), ([\d.]+), ([\d.]+), ([\d.]+)/);
      if (rgba) {
        rects[i].setAttribute("fill", "rgb(" + rgba[1] + ", " + rgba[2] + ", " + rgba[3] + ")");
        rects[i].setAttribute("opacity", rgba[4]);
      } else {
        rects[i].setAttribute("fill", fill);
      }
    }
    clonedSvg.getElementById("datePointer").style.display = "none";

    body.removeChild(clonedSvg);
    return clonedSvg;
  };

  // start initialization 
  busy();
  calcDimensions();
  d3.json("/shift_slots/probebetrieb?format=json", {
    credentials: "same-origin"
  }).then(function (data) {
    window.test = data;
    if (typeof (data.east) == "undefined" || typeof (data.west) == "undefined") {
      hideBusy();
      return;
    }
    if (cookie != undefined) {
      $("input[data-class=" + cookie + "]").attr("checked", "checked");
    }
    startDate = moment(data.start_time).startOf("day");
    endDate = moment(data.end_time).endOf("day");
    prepareSvg();
    drawTunnel(data.east.concat(data.both || []), svg, "east");
    drawTunnel(data.west.concat(data.both || []), svg, "west");
    drawGrid(data.specialDatesMs);
    showMissionLabels();
    $("#show-mission-labels").click(showMissionLabels);
    $(".show-mission-labels").click(function () {
      $("#show-mission-labels").prop("checked", !$("#show-mission-labels").prop("checked"));
      showMissionLabels();
    });
    $("#download-button").click(function () {
      let svg = prepareSVGForDownload();
      serializeAndSendSVG(svg, $(this).data("svg-filename") || "Tunnelbelegung.svg");
    });
  });
  hideBusy();
};

postInit("shift_slots#probebetrieb", initProbebetrieb);