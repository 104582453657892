// 2017-08-02 / Matthias Kummer
// Datatables initializers for the various Datatables used throughout PLATIN
/* global locale, $ */

import {
  initializers
} from "./platin";
import {
  d3FormatDate,
  d3ParseDate,
  d3FormatTimestamp
} from "./d3utils";
import {
  hideTooltips,
  initPopoversHover,
  initPopoversClick,
  showBusy,
  hideBusy
} from "./utils";
// import moment from "moment";

function userLink(data, type, row) {
  if (type == "display") {
    return "<a href=\"/" + locale + "/users/" + row[7] + "\">" + data + "</a>";
  } else {
    return data;
  }
}

function loginLink(data, type, row) {
  if (type == "display") {
    return "<a href=\"/" + locale + "/logins/" + row[8] + "\"><i class=\"fa fa-eye\"></i></a>";
  } else {
    return data;
  }
}

/* 2018-05-07 / Matthias Kummer
   helper function for rendering links, parametrized by display text, url and ID
   usage example:
   - on the table element, add {data: {link_cols: [[2, 12, train_url(id: "_ID_")]]}}
   - in the data source, col[2] contains the display text, col[12] contains the ID
 
   linkCol = [textCol, idCol, url] */
function link(linkCol) {
  var linkRenderer = function (data, type, row) {
    if (type == "display") {
      return "<a href=\"" + linkCol[2].replace("_ID_", row[linkCol[1]]) + "\" target=\"_blank\">" + row[linkCol[0]] + "</a>";
    } else {
      return data;
    }
  };
  return linkRenderer;
}

/* 2017-09-22 / Matthias Kummer
    render function for data formatted either as timestamp (JS ms)
    or formatted as dd.mm.yyyy
*/
function dateOrTimestamp(data, type) {
  if (type == "display") {
    if (isNaN(data)) {
      return d3FormatDate(d3ParseDate(data));
    } else {
      return d3FormatTimestamp(data);
    }
  } else {
    if (isNaN(data)) {
      return d3ParseDate(data);
    } else {
      return new Date(parseInt(data));
    }
  }
}

function timestamp(data, type) {
  if (type == "display") {
    return d3FormatTimestamp(data);
  } else {
    return data;
  }
}

/* 2017-11-02 / Matthias Kummer
    data can be a number (JS timestamp) or a date string
*/
function date(data, type) {
  if (type == "display") {
    if (isNaN(data) || data == "") {
      //return App.d3FormatDate(App.d3ParseDate(data));
      return data;
    } else {
      return d3FormatDate(data);
    }
  } else {
    if (isNaN(data) || (data == "")) {
      return d3ParseDate(data);
    } else {
      return new Date(parseInt(data));
    }
  }
}

const dtAddDefaultButtons = function (dt) {
  import( /* webpackChunkName: "dt" */ "packs/datatables");
  new $.fn.dataTable.Buttons(dt, {
    buttons: [{
        extend: "copy",
        text: "<i class='fa fa-clipboard' title='Tabelle in die Zwischenablage kopieren' data-toggle='tooltip'>",
        className: "btn-sm"
      },
      {
        extend: "csv",
        text: "<img src='/static/excel_csv.svg' width=20 title='Tabelle als .csv exportieren' data-toggle='tooltip'>",
        className: "btn-sm"
      },
      {
        extend: "colvis",
        text: "Spalten",
        className: "btn-sm"
      }
      /*,
      {
          extend: "excel",
          text: "<img src='/assets/excel_xlsx.svg' width=20 title='Tabelle als .xlsx exportieren' data-toggle='tooltip'>",
          className: "btn-sm"
      },
      {
          extend: "pdf",
          text: "<img src='/assets/pdf.svg' width=20 title='Tabelle als pdf herunterladen' data-toggle='tooltip'>",
          orientation: "landscape",
          messageBottom: "Exportiert aus PLATIN " + moment().format("LLLL"),
          className: "btn-sm"
      }*/
    ]
  });

  dt.buttons().container().prependTo(
    dt.table().container()
  );
};

const initDataTables = function () {
  let dtTables = $("table.datatables");

  if (!(dtTables.length > 0)) {
    return;
  }

  import( /* webpackChunkName: "dt" */ "packs/datatables").then(() => {
    const i18n = {
      de: {
        "sEmptyTable": "Keine Daten in der Tabelle vorhanden",
        "sInfo": "_START_ bis _END_ von _TOTAL_ Einträgen",
        "sInfoEmpty": "0 bis 0 von 0 Einträgen",
        "sInfoFiltered": "(gefiltert von _MAX_ Einträgen)",
        "sInfoPostFix": "",
        "sInfoThousands": ".",
        "sLengthMenu": "_MENU_ Einträge anzeigen",
        "sLoadingRecords": "Wird geladen...",
        "sProcessing": "<i style=\"border: none\" class=\" fa fa-refresh fa-spin\"></i><span class=\"ml-2\">Daten werden geladen...</span>",
        "sSearch": "Filter",
        "sSearchPlaceholder": "Suchtext",
        "sZeroRecords": "Keine Einträge vorhanden.",
        "oPaginate": {
          "sFirst": "Erste",
          "sPrevious": "Zurück",
          "sNext": "Nächste",
          "sLast": "Letzte"
        },
        "oAria": {
          "sSortAscending": ": aktivieren, um Spalte aufsteigend zu sortieren",
          "sSortDescending": ": aktivieren, um Spalte absteigend zu sortieren"
        }
      },
      it: {
        "sEmptyTable": "Nessun dato presente nella tabella",
        "sInfo": "Vista da _START_ a _END_ di _TOTAL_ elementi",
        "sInfoEmpty": "Vista da 0 a 0 di 0 elementi",
        "sInfoFiltered": "(filtrati da _MAX_ elementi totali)",
        "sInfoPostFix": "",
        "sInfoThousands": ".",
        "sLengthMenu": "Visualizza _MENU_ elementi",
        "sLoadingRecords": "Caricamento...",
        "sProcessing": "<i style=\"border: none\" class=\" fa fa-refresh fa-spin\"></i><span class=\"ml-2\">Elaborazione...</span>",
        "sSearch": "Cerca:",
        "sZeroRecords": "La ricerca non ha portato alcun risultato.",
        "oPaginate": {
          "sFirst": "Inizio",
          "sPrevious": "Precedente",
          "sNext": "Successivo",
          "sLast": "Fine"
        },
        "oAria": {
          "sSortAscending": ": attiva per ordinare la colonna in ordine crescente",
          "sSortDescending": ": attiva per ordinare la colonna in ordine decrescente"
        }
      }
    };
    $.extend($.fn.dataTable.defaults, {
      "language": i18n[window.locale || "de"]
    });

    /* 2017-09-22 / Matthias Kummer
        initialize DataTable on each table with class datatables
        options:
        data-timestamp-cols: array of col indices where timestamp render function shall be used
        data-date-cols: array of col indices where date render function shall be used
        data-date-or-timestamp-cols: array of col indices where dateOrTimestampCols render function shall be used
        data-focus-filter: set to true to set focus on the filter input after initialization
    */
    dtTables.each(function (index, el) {
      const isDt = $(el).closest(".dataTables_wrapper").length > 0; /* isDataTable for some reason doesn't work during Turbolinks cache restores */
      if (!isDt) {
        initSingleDataTable(el);
      }
    });
  });

  $(document).on("draw.dt", function () {
    $(".dataTables_paginate ul.pagination").addClass("pagination-sm");
  });

};

const initSingleDataTable = function (el) {
  import( /* webpackChunkName: "dt" */ "packs/datatables").then(() => {
    /* 2019-09-18 / Matthias Kummer
       enable e.g. putting undefined / empty values to bottom
       with
       columnDefs: [
            { type: emptyStringsToBottom, targets: 0 }
       ]
    */
    import( /* webpackChunkName: "dt-absolute" */ "vendor/dt-absolute").then(() => {
      let dt, options = {
        scrollCollapse: true,
        stateDuration: 0,
        //processing: true,
        //language: {
        //  processing: "<i style=\"border: none\" class=\" fa fa-refresh fa-spin fa-2x\"></i><span class=\"sr-only\" style=\"border: none\">Loading...</span>"
        // }
      };
      const
        timestampCols = $(el).data("timestamp-cols"),
        dateCols = $(el).data("date-cols"),
        dateOrTimestampCols = $(el).data("date-or-timestamp-cols"),
        linkCols = $(el).data("link-cols"),
        emptyToBottomCols = $(el).data("empty-to-bottom"),
        emptyToBottomNumberCols = $(el).data("empty-to-bottom-number"),
        focusFilter = $(el).data("focus-filter"),
        url = $(el).data("datatables-url"),
        defaultButtons = $(el).data("dt-buttons"),
        columnFilters = $(el).data("column-filters") || [],
        columnSelects = $(el).data("column-selects") || [],
        onDraw = $(el).data("on-draw") || [],
        createdRow = $(el)[0].createdRow;

      if (!$.fn.DataTable.isDataTable(el)) {

        if (timestampCols) {
          options["columnDefs"] = options.columnDefs || [];
          options.columnDefs.push({
            targets: timestampCols,
            render: timestamp
          });
        }
        if (dateCols) {
          options["columnDefs"] = options.columnDefs || [];
          options.columnDefs.push({
            targets: dateCols,
            render: date
          });
        }
        if (dateOrTimestampCols) {
          options["columnDefs"] = options.columnDefs || [];
          options.columnDefs.push({
            targets: dateOrTimestampCols,
            render: dateOrTimestamp
          });
        }
        if (linkCols) {
          options["columnDefs"] = options.columnDefs || [];
          linkCols.forEach(function (linkCol) {
            options.columnDefs.push({
              targets: linkCol[0],
              render: link(linkCol)
            });
          });
        }
        if (emptyToBottomCols) {
          const emptyToBottom = $.fn.dataTable.absoluteOrder([{
              value: '',
              position: 'bottom'
            },
            {
              value: null,
              position: 'bottom'
            }
          ]);

          options["columnDefs"] = options.columnDefs || [];
          options.columnDefs.push({
            targets: emptyToBottomCols,
            type: emptyToBottom
          });
        };
        if (emptyToBottomNumberCols) {
          const emptyToBottomNumber = $.fn.dataTable.absoluteOrderNumber([{
              value: '',
              position: 'bottom'
            },
            {
              value: null,
              position: 'bottom'
            },
            {
              value: "N/A",
              position: 'bottom'
            }
          ]);

          options["columnDefs"] = options.columnDefs || [];
          options.columnDefs.push({
            targets: emptyToBottomNumberCols,
            type: emptyToBottomNumber
          });
        };
        if (createdRow) {
          options["createdRow"] = createdRow;
        }
      }
      if (focusFilter) {
        options["initComplete"] = function (settings) {
          $(settings.nTableWrapper).find("input[type='search']").focus();
        };
      }
      if (url) {
        options["ajax"] = url;
      }

      /* install draw_callbacks */
      onDraw.forEach(callback => {
        switch (callback) {
          case "popover-hover":
            $(el).on("draw.dt", initPopoversHover);
          case "popover-click":
            $(el).on("draw.dt", initPopoversClick);
          case "columns-adjust":
            $(el).on("draw.dt", function(){
              $(this).DataTable().columns.adjust();
          })
        }
      })

      if (columnSelects.length > 0 || columnFilters.length > 0) {
        const thead = $("thead", el),
          filterThead = thead.clone(true);

        /* adds an <option> to select; option can be just a string
           or an HTML element where text will be extracted */
        function addOption(select) {
          return function (option) {
            const text = (option[0] == "<") ? $(option).text() : option;
            $(select).append(`<option value="${text}">${text}</option>`)
          }
        }

        options["initComplete"] = function () {
          filterThead.insertBefore(thead);
          filterThead.addClass("filter-thead");

          this.api().columns().every(function (i) {
            let th = $(`th:nth(${i})`, filterThead),
              column = this;

            if (columnSelects.includes(i)) {
              let select = $('<select><option value=""></option></select>');
              th.html(select);

              select.on('change', function () {
                let val = $(this).val();

                column
                  .search(val ? val : '', false, true)
                  .draw();
              });
              column.data().unique().each(addOption(select));
            } else if (columnFilters.includes(i)) {
              let input = $(`<input type="text" placeholder="Filter ${th.text()}" />`);
              th.html(input);

              input.on('keyup change clear', function () {
                if (column.search() !== this.value) {
                  column.search(this.value)
                    .draw();
                }
              })
            } else {
              th.html("");
            }
          })
        }
      };


      // override default callbacks to prevent different state data for detail tables
      // e.g. campaign_parts in a campaign#show view
      // persist table state only for one session
      options["stateDuration"] = -1;
      options["stateSaveCallback"] = function (settings, data) {
        localStorage.setItem("DT-" + settings.sInstance, JSON.stringify(data));
      };
      options["stateLoadCallback"] = function (settings) {
        return JSON.parse(localStorage.getItem("DT-" + settings.sInstance));
      };

      // install dtPlatinClassFilterFunction if necessary
      if ($.fn.dataTable.ext.search.indexOf(dtPlatinClassFilterFunction) == -1) {
        $.fn.dataTable.ext.search.push(dtPlatinClassFilterFunction);
      }

      dt = $(el).DataTable(options);
      $(".platin-class-filter.dropdown").on("click", "input", dt.draw);

      if (defaultButtons) {
        dtAddDefaultButtons(dt);
        $(".insert-after-csv-button a, .insert-after-csv-button button").insertAfter($(".dt-buttons :nth-child(0n+2)"));

      }
      $(el).on("preDraw.dt", hideTooltips);
      $(el).on("preXhr.dt", showBusy);
      $(el).on("xhr.dt", hideBusy);

    }) /* end of resolve handler for importing dt-absolute */
  }) /* end of resolve handler for importing dt */
};

function dtPlatinClassFilterFunction(settings, data, dataIndex) {
  const filterString = $(settings.aoData[dataIndex].nTr).data("filter");
  let filters, shown = true;
  if ($(".platin-class-filter.dropdown").length == 0) {
    return true;
  }
  if (filterString) {
    filters = filterString.split(" ");
    let checkbox;
    for (let filter of filters) {
      checkbox = $(`.platin-class-filter.dropdown input#show-${filter}`);
      if (checkbox.length > 0) {
        shown = shown && checkbox.is(":checked");
      }
    }
  }
  return shown;
}

initializers.push(initDataTables);

const initHistoryTable = function () {
  var maxHeight = $("#history-dialog").dialog("option", "maxHeight");
  var scrollHeight = Math.min($("#history-dialog").innerHeight() - 40, maxHeight - 200);
  $("table.history-table").DataTable({
    dom: "ftr",
    scrollY: scrollHeight,
    paging: false,
    order: [
      [1, "asc"]
    ]
  });
};

export {
  dtAddDefaultButtons,
  initDataTables,
  initHistoryTable,
  initSingleDataTable
};