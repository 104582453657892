import {
  postInit,
  preInit
} from "platin";
import $ from "jquery";
import consumer from "./channels/consumer";

function showHideTabsBasedOnCategory(cat){
  switch (cat) {
  case "pb_erh":
  case "pb_test":
  case "komm_erh":
    $("[href=\"#service-features-tab\"]").hide();
    $("[href=\"#fsa-tab\"]").show();
    break;
  default:
    $("[href=\"#service-features-tab\"]").show();
    $("[href=\"#fsa-tab\"]").hide();
  }
  $("[data-hide-for]").each(function () {
    const hideList = $(this).data().hideFor;
    $(this).closest(".form-group.row").toggle(!hideList.includes(cat));
  });

}

function handleCategoryChange() {
  const cat = $("option:selected", this).val();
  showHideTabsBasedOnCategory(cat);
}

/*
  the tdb_category is either taken from the select menu or, if this is not visible
  due to access restrictions, from the hidden_field replacing it
*/
function initForm() {
  const select = $("[name=\"mission[tdb_category]\"]"),
    cat = $("option:selected", select).val() || $("#mission_tdb_category").val();
  select.change(handleCategoryChange);
  showHideTabsBasedOnCategory(cat);
}

function initShow() {
  const dossierMailerModal = $("#dossier-mailer-modal");

  function installEditLinkToggler() {
    const editMissionLink = $("#edit-mission-link"),
      editTdbLink = $("#edit-tdb-link");

    function toggleEditLink(event) {
      const target = event.target;
      if ($(target).attr("href") == "#tdb-tab") {
        editTdbLink.show();
        editMissionLink.hide();
      } else {
        editTdbLink.hide();
        editMissionLink.show();
      }
    }
    editTdbLink.insertAfter(editMissionLink);
    editTdbLink.hide();
    $("ul.nav-tabs").on("shown.bs.tab", toggleEditLink);
  }

  function installNumTeamsValidator() {

    function checkForNumTeams(event) {
      const numTeams = $("#num-teams-value").data("present");
      if (!numTeams) {
        event.preventDefault();
        alert("Um das Drehbuch initialisieren zu können muss die Anzahl Teams (Attribut «# Teams» im Reiter «Ablauf») bekannt sein!");
        return false;
      }
    }

    $("#initialize-tdb-link").click(checkForNumTeams);

  }

  function subscribeToDossierChannel() {
    const modalBody = $(".modal-body", dossierMailerModal),
      filesTarget = $("#files-target", dossierMailerModal);

    filesTarget.html("");
    $(".card.dossier", modalBody).remove();

    const subscription = consumer.subscriptions.create("DossierChannel", {
      connected: function () {},
      // Called when the subscription is ready for use on the server

      disconnected: function () {},
      // Called when the subscription has been terminated by the server

      received: function (data) {
        let header, body, card;
        switch (data.status) {
        case "start_dossier":
          card = $(`<div class="card mt-2 dossier" data-id="${data.id}"></div>`);
          header = $(`<div class="card-header">${data.title}</div>`);
          body = $("<div class='card-body pl-2'></div>");
          modalBody.append(card);
          card.append(header);
          card.append(body);
          body.append($(`<div>${data.message}</div>`));
          break;
        case "dossier_update":
          card = $(`.card[data-id="${data.id}"]`);
          body = $(".card-body", card);
          body.append($(`<div>${data.message}</div>`));
          break;
        case "file_update":
          filesTarget.append($(`<div>${data.message}</div>`));
          break;
        }
      }
    });

    dossierMailerModal.on("hide.bs.modal", () => consumer.subscriptions.remove(subscription));

  }

  function showDossierMailerProgress() {

    $("a.show-dossier-mailer-modal").click(function (event) {
      let url = event.target.href,
        safetyQuestion = $(this).data("ask");
      event.preventDefault();
      if (confirm(safetyQuestion)) {
        subscribeToDossierChannel();
        dossierMailerModal.modal("show");
        $.get(url, "json");
      }

    });
  }

  installEditLinkToggler();
  showDossierMailerProgress();
  installNumTeamsValidator();
  showHideVisitorWarning();
}

function showHideVisitorWarning() {
  if ($("ul.staff-list.vis").length > 0) {
    $(".alert.visitor-instructions").show();
  } else {
    $(".alert.visitor-instructions").hide();
  }
}

function toggleForceableLinks() {
  const forceableLinks = $("a.forceable-download");
  $("body").keydown(function (e) {
    if (e.altKey) {
      forceableLinks.addClass("force");
      forceableLinks.each(function () {
        $(this).attr("href", $(this).attr("href") + "&force=true");
      });
    }
  }).keyup(function (e) {
    if (!e.altKey) {
      forceableLinks.removeClass("force");
      forceableLinks.each(function () {
        $(this).attr("href", $(this).attr("href").replace("&force=true", ""));
      });
    }
  });
}

function initIndex() {
  const table = $("#dt-missions"),
    showPastButton = $("#show-past"),
    platinClassFilter = $(".platin-class-filter input[type=checkbox]"),
    url = table.data("ajax"),
    ajaxObj = {
      url: url,
      data: function (dtData) {
        dtData.show_past = showPastButton.is(":checked");
        platinClassFilter.each(function () {
          dtData[$(this).attr("name")] = $(this).is(":checked");
        });
      }
    };
  table.data("ajax", ajaxObj);
  showPastButton.click(() => table.DataTable().draw());
}

function initCovidRevoked(){
  $("#covid19-status-change-dialog").on("show.bs.modal", function(e){
    const editLink = e.relatedTarget,
      id = editLink.dataset.id,
      tr = editLink.closest("tr"),
      eid = tr.children[0].textContent,
      name = tr.children[2].textContent;
    $("span.eid", this).html(eid);
    $("span.mission-name", this).html(name);
    $("#covid19-status-change-dialog a").each(function(){
      if (!this.dataset.origHref) {
        this.dataset.origHref = this.href;
      }
      this.editLink = editLink;
      this.href = this.dataset.origHref.replace("_ID_", id);
    });
  });

  $("#covid19-status-change-dialog").on("ajax:success", "a", function(e){
    $(e.target.editLink).closest("td").prev("td").html(e.detail[0].status);
    $("#covid19-status-change-dialog").modal("hide");
  });  
}

postInit("missions#show", initShow);
postInit("missions#show", toggleForceableLinks);
preInit("missions#index", initIndex);
preInit("campaign_parts#show", initIndex);
preInit("campaigns#show", initIndex);
preInit("meetings#show", initIndex);
preInit("people#show", initIndex);
preInit("people#spoc_tnt_cockpit", initIndex);
preInit("tnts#show", initIndex);
postInit("missions#edit", initForm);
postInit("missions#new", initForm);
postInit("missions#covid_revoked", initCovidRevoked);
export {
  showHideVisitorWarning
};
// postInit("missions#show", tepasNrToggler);