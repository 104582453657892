/* 2018-03-07 / Matthias Kummer */
/* global locale */

import {
  initializers
} from "./platin";
import $ from "jquery";

const initInlineEditFields = function () {
  var inlineUpdateReset = function (event) {
    var input = $(event.target),
      td = input.closest("td"),
      origText = td.data("editable-orig-text");
    td.removeData("editing");
    td.html("").text(origText);
  };

  var inlineUpdateSuccess = function (data) {
    let id = data.id,
      input = $("[data-editable-id=" + id + "] input"),
      td, attr;
    td = $(input).closest("td");
    attr = td.data("editable-attr");
    //td.html("").text(data[attr]);
    $(`[data-editable-id=${id}][data-editable-attr=${attr}]`).each(function () {
      let oldBg = $(this).css("background-color");
      $(this).text(data[attr])
        .css({
          "background-color": "green"
        })
        .animate({
          "background-color": oldBg
        }, 1000);
    });

    $(input).closest("table.dataTable").DataTable().columns.adjust().draw();
  };

  var inlineUpdate = function (event) {
    if (event.key != "Enter") {
      return;
    }
    var input = $(event.target),
      td = input.closest("td"),
      value = input.val(),
      id = td.data("editable-id"),
      ctrl = td.data("editable-ctrl") || $("body").data("controller"),
      klass = td.data("editable-klass") || $("body").data("controller"),
      url = td.data("editable-url") || `/${locale}/${ctrl}/${id}`,
      attr = td.data("editable-attr"),
      data = {};
    event.preventDefault();
    td.removeData("editing");
    data[klass] = {};
    data[klass][attr] = value;
    data["inline"] = true;
    $.ajax({
      type: "PATCH",
      url: url,
      data: data,
      dataType: "json",
      success: inlineUpdateSuccess
    });
  };

  $("[data-editable-id]").click(function () {
    let td = $(this),
      text, input;

    if (td.data("editing") == true) {
      return;
    }
    td.data("editing", true);

    text = td.text();
    input = $("<input type='text' class='inline-edit-field' value='" + text + "'></input>");
    td.text("").data("editable-orig-text", text);
    input.appendTo(td).focus();
    //input[0].selectionStart = input[0].selectionEnd = input[0].value.length;
    input.keyup(inlineUpdate);
    input.focusout(inlineUpdateReset);
  });

};

initializers.push(initInlineEditFields);

export {
  initInlineEditFields
};