/* 2017-03-27 / Matthias Kummer
# add data-cookie='cookie-key' to any ul.nav-tabs
# add a data-default-tab="tab-id-of-tab-to-open" to pre-open
# that tab */

import {
    initializers
} from "./platin";
import Cookies from "js-cookie/src/js.cookie";
import $ from "jquery";
import {
    initSingleDataTable
} from "./datatable-initializers";
import {
    initTableFilter
} from "./table-controls";


function getDefaultTab(ulNavTabs) {
    const tabId = ulNavTabs.data("default-tab");
    return (tabId && $("[data-toggle=\"tab\"][href=\"#" + tabId + "\"]"));
}

// extracts the name of the cookie set for the nav-tabs
function getCookieName(ulNavTabs) {
    return $(ulNavTabs).data("cookie");
}

// if a cookie exists for the nav-tabs, it contains the href of the
// tab to auto-open
function getTabHrefFromCookie(cookieName) {
    return cookieName && Cookies.get(cookieName);
}

function getTabFromCookie(ulNavTabs) {
    const cookieName = getCookieName(ulNavTabs),
        href = getTabHrefFromCookie(cookieName);
    if (cookieName && href) {
        return $(`[data-toggle=\"tab\"][href=\"${href}\"]`);
    } else { // wrong / stale cookie, no such tab
        Cookies.remove(cookieName);
    }
}

// tab is a nav.nav-tabs a[data-toggle=tab] element
function installShownEventHandler(ulNavTabs) {
    const cookieName = getCookieName(ulNavTabs);
    $(ulNavTabs).on("shown.bs.tab", "a[data-toggle=\"tab\"]", function (event) {
        const tabID = $(event.target).attr("href");
        let tables;

        // set cookie if needed
        if (cookieName) {
            Cookies.set(cookieName, tabID);
        }

        // redraw data tables
        tables = $(`${tabID} table.dataTable`);
        if (tables.length > 0) {
            import( /* webpackChunkName: "dt" */ "packs/datatables").then(() => {
                tables.DataTable().columns.adjust().draw();
            });
        }

    });
}

function openCorrectTabOnLoading(ulNavTabs) {
    let anchorTab, cookieTab, defaultTab, tabToShow;
    // anchor?
    if (location.hash) {
        anchorTab = $(`[data-toggle=\"tab\"][href=\"${location.hash}\"]`);
        if (anchorTab.length > 0) {
            tabToShow = anchorTab.first();
        }
    }

    // cookie?
    if (cookieTab = getTabFromCookie(ulNavTabs)) {
        tabToShow = cookieTab;
    }

    // default tab
    if (defaultTab = getDefaultTab(ulNavTabs)) {
        tabToShow = defaultTab;
    }

    // make shure, shown.bs.tab is called even when the open tab is not changing
    if (tabToShow && (tabToShow.length > 0)) {
        if (tabToShow.hasClass("active")) {
            tabToShow.trigger("shown.bs.tab");
        } else {
            $(".tab-pane.active").removeClass("show").removeClass("active");
            $(tabToShow.attr("href")).addClass("show").addClass("active");
            tabToShow.tab("show");
        }
    } else {
        $("a.active", ulNavTabs).trigger("shown.bs.tab");
    }
}

function initNavTabs() {
    $("ul.nav-tabs").each(function () {
        const ulNavTabs = $(this);
        installShownEventHandler(ulNavTabs);
        openCorrectTabOnLoading(ulNavTabs);
    })
}

/* 2019-09-28 / Matthias Kummer
    automates deferred loading of tab content
    usage:
    - add data-load-from="ajax_url" to the tab header <a> element
    - the ajax action must return a JSON encoded HTML template which replaces the content of the tab
    - datatables in the content are automatically initialized
*/
const handleCustomDeferredLoading = function () {
    $("a[data-load-from]").on("shown.bs.tab", function () {
        const id = $(this).attr("href"),
            container = $(id),
            url = $(this).data("load-from");
        if (!$(this).data("loaded")) {
            container.html("<i class=\"fa fa-spin fa-refresh\">");
            $.get(url, function (content) {
                container.html(content);
                $("table.datatables", container).each(function () {
                    initSingleDataTable(this);
                });
                initTableFilter();
            });
            $(this).data("loaded", true)
        }
    });
}

/* 2019-12-31 / Matthias Kummer
    automates deferred loading of tab content
    usage:
    - add data-deferred-template="template name", data-item-type, data-item-id to the tab header <a> element
    - the ajax action must return a JSON encoded HTML template which replaces the content of the tab
    - datatables in the content are automatically initialized

    this is automated via TabHelper#tab_header_entry :load_deferred option
*/
const handleStandardDeferredLoading = function () {
    $("a[data-deferred-template]").on("shown.bs.tab", function () {
        const id = $(this).attr("href"),
            container = $(id),
            url = `/${window.locale}/shared/load_tab`,
            template = $(this).data().deferredTemplate,
            itemType = $(this).data().itemType,
            itemId = $(this).data().itemId,
            data = {
                template: template,
                item_type: itemType,
                item_id: itemId
            };
        if (!$(this).data("loaded")) {
            container.html("<i class=\"fa fa-spin fa-refresh\">");
            $.get(url, data, function (content) {
                container.html(content);
                $("table.datatables", container).each(function () {
                    initSingleDataTable(this);
                });
                initTableFilter();
            });
            $(this).data("loaded", true)
        }
    });
}

initializers.push(handleCustomDeferredLoading);
initializers.push(handleStandardDeferredLoading);
initializers.push(initNavTabs);