/* global data, train_db, rbc_km_domain, stationNameKeys, stationNames, train_path */
/* global backgroundMarkings, train_cats, message_svg_path, vany_svg_path, zoomreset_png_path */
/* the above mentioned globals (except $ and d3 of course) are defined by means of inline JS code */
/* in the view graphical_timetable_d3.html */

import { postInit } from "./platin";
import * as d3 from "d3";
import $ from "jquery";
import moment from "moment";
import { initSingleDataTable } from "./datatable-initializers";

function initGraphicalTimetable() {
  var trainTable = $("table#train-table"), // the table underneath the plotting area for displaying train details
    trainNrTD = $("td#train-nr"),
    obuTD = $("td#obu"),
    timesTD = $("td#times"),
    numPosTD = $("td#num-pos"),
    trainCatVmaxTD = $("td#train-cat-vmax"),
    trainLenTD = $("td#train-len"),
    axleLoadTD = $("td#axle-load"),
    gbtTD = $("td#gbt"),
    registerTD = $("td#register-events"),
    unregisterTD = $("td#unregister-events"),
    l2TD = $("td#l2");
  // textMsgsDlg = $("div#text-msgs-dlg");

  // the core data variable containing all the trains, segments and position reports
  // with the following structure:
  // - array of trains, where each
  // - train is an object
  //   tr_id: train ID (database id)
  //   segments: array of
  // - segment
  //   seg_id: segment ID (database id)
  //   pos_arr: array of
  // - position_reports, which are arrays created by the following code (in TrainsController#graphical_timetable_d3):
  //   [PositionReport.normalized_km(pos,tr),ms,tr,sp,seg_id,tr_id,pos/10_000.0,tp]

  // calculate the dimensions of the plot
  var fullwidth = $("#chart-container").width(),
    fullheight = $("#chart-container").closest(".row").height() * 0.62,
    margin = {
      top: 50,
      right: 20,
      bottom: 0,
      left: 50,
    },
    width = fullwidth - margin.left - margin.right,
    height = fullheight - margin.top - margin.bottom;

  // the x scale is a linear scale scaling the km values from 10 to 240 over the width of the plot
  var x = d3.scaleLinear().range([0, width]).domain(rbc_km_domain);

  // the y scale is a linear time scale scaling the time values over the height of the plot
  // to calculate of the extent of the time values, we take the extent over all the time extents of all segments
  var y = d3
    .scaleTime()
    .range([0, height])
    .domain(
      d3.extent(
        d3.merge(
          d3.merge(
            data.map(function (train) {
              return train.segments.map(function (segment) {
                return d3.extent(
                  segment.pos_arr.map(function (pr) {
                    return pr[1];
                  })
                );
              });
            })
          )
        )
      )
    );

  // for setting focus on a position report/segment/train, we build a Voronoi Diagram to
  // determine the nearest point of interest (either a pos report or a text message)
  // our voronoi builder below needs an array of objects {x, y, [optional additional object]}
  var msgArrayForVornonoi = d3
    .merge(
      Object.keys(train_db)
        .map(function (train_id) {
          var textMessages = train_db[train_id].text_messages;
          return textMessages.length > 0 ? textMessages : undefined;
        }) // now we have an array of either undefined or arrays of {time, km, message}
        .filter(function (e) {
          return e != undefined;
        }) // got rid of undefined's
    )
    .map(function (e) {
      return {
        x: e.km,
        y: e.time,
        msg: e,
      }; // translate to the object structure required by d3.voronoi
    });

  // arrayForVoronoi will be an array of {x, y, ({time, km message} | {posrep})}
  var arrayForVoronoi = d3.merge(
    [msgArrayForVornonoi].concat(
      d3.merge(
        data.map(function (tr) {
          return tr.segments.map(function (seg) {
            return seg.pos_arr.map(function (pr) {
              return {
                x: pr[0],
                y: pr[1],
                pr: pr,
              };
            });
          });
        })
      )
    )
  );

  var msgArrayForFind = d3
    .merge(
      Object.keys(train_db)
        .map(function (train_id) {
          var textMessages = train_db[train_id].text_messages;
          return textMessages.length > 0 ? textMessages : undefined;
        }) // now we have an array of either undefined or arrays of {time, km, message}
        .filter(function (e) {
          return e != undefined;
        }) // got rid of undefined's
    )
    .map(function (e) {
      return {
        x: x(e.km),
        y: y(e.time),
        msg: e,
        train_id: e.train_id,
      }; // translate to the object structure required by d3.voronoi
    });
  var arrayForFind = d3.merge(
    [msgArrayForFind].concat(
      d3.merge(
        data.map(function (tr) {
          return tr.segments.map(function (seg) {
            return seg.pos_arr.map(function (pr) {
              return {
                x: x(pr[0]),
                y: y(pr[1]),
                pr: pr,
                train_id: pr[5],
              };
            });
          });
        })
      )
    )
  );

  var voronoiLayout = d3
    .voronoi()
    .x(function (d) {
      return x(d.x);
    })
    .y(function (d) {
      return y(d.y);
    })
    .size([width, height]);
  var voronoiDiagram = voronoiLayout(arrayForVoronoi);
  var voronoiRadius = 8;

  // build the D3 plot starting from the svg#graphTimetableChart element
  // first set the dimension attributes
  var svg = d3
    .select("svg#graphTimetableChart")
    .attr("width", fullwidth)
    .attr("height", fullheight);

  // then add the outermost <g id="outerG"> with the origin of the coordinate system offset
  // by margin (measured right and down from the top left corner)
  // the outerG has width and height, whereas svg has fullwidth and fullheight
  var outerG = svg
    .append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
    .attr("id", "outerG");

  // svg <defs> for setting clipPaths
  var defs = outerG.append("defs");

  // clipPath for plot area
  defs
    .append("clipPath")
    .attr("id", "clip")
    .append("rect")
    .attr("x", 0)
    .attr("y", 0)
    .attr("width", width)
    .attr("height", height);

  // clipPath for entire x axis (from -margin.top down to the very bottom of the plot due to the long tick lines)
  defs
    .append("clipPath")
    .attr("id", "clipXaxis")
    .append("rect")
    .attr("x", 0)
    .attr("y", -margin.top)
    .attr("width", width)
    .attr("height", height + margin.top);

  var xClipContainer = outerG.append("g").attr("clip-path", "url(#clipXaxis)");

  var clipContainer = outerG.append("g").attr("clip-path", "url(#clip)");

  // the <g> that will contain all the graphics elements of the x axis
  // we're adding this to the xClipContainer instead of outerG (which doesn't have a clipPath!)
  // to make sure no x axis elements are ever drawn outside of the intended area
  var xaxis_g = xClipContainer.append("g").attr("class", "axis axis-x");
  // let's build an x axis on top (based on the x scaling function defined above)
  // xaxis contains the generator function
  var xaxis = d3
    .axisTop(x)
    .tickSizeInner(-height) // ticks extending on the inside all the way to the bottom of the plot
    .tickSizeOuter(0) // but not on the outside
    .tickValues(stationNameKeys)
    .tickFormat(function (km) {
      return stationNames[km];
    });
  // calling the generator with the containing <g> will render the axis elements as children of xaxis_g
  xaxis(xaxis_g);
  // customize the x axis by rotating the tick labels…
  xaxis_g
    .selectAll("text")
    .attr("y", "-0.35em")
    .attr("x", 8)
    .attr("transform", "rotate(-90)")
    .attr("dy", ".7em")
    .style("text-anchor", "start");
  // … and adding a title
  xaxis_g
    .append("text")
    .attr("fill", "#000")
    .attr("class", "axis-label")
    .attr("transform", "translate(" + width / 2 + "," + -36 + ")")
    .text("Strecke");

  // now the same for the y axis
  var yaxis_g = outerG.append("g").attr("class", "axis axis-y");
  var yaxis = d3.axisLeft(y).tickFormat(d3.timeFormat("%H:%M"));
  yaxis(yaxis_g);
  yaxis_g
    .append("text")
    .attr("class", "axis-label")
    .attr("fill", "#000")
    .attr("y", -margin.left)
    .attr("x", -height / 2)
    .attr("dy", "0.71em")
    .attr("transform", "rotate(-90)")
    .style("text-anchor", "middle")
    .text("Uhrzeit");

  // zoomContainer is the <g> element containing all (plot) graphics elements which
  // we need to zoom and pan while ensuring they are never drawn outside of clipContainer
  // zoomContainer will receive transform operations upon zoom  and pan events, so every
  // child of it will be zoomed / panned automatically
  var zoomContainer = clipContainer
    .append("g")
    .attr("class", "graphics-contents");

  // children of backgroundMarkingsG are simply <rect>s filled with a (light) color, created
  // from the backgroundMarkings array (see above)
  var bgMarkingsG = zoomContainer
    .append("g")
    .attr("class", "background-markings");

  bgMarkingsG
    .selectAll("rect.background-markings")
    .data(backgroundMarkings)
    .enter()
    .append("rect")
    .attr("class", "background-markings")
    .attr("x", function (d) {
      return x(d.xaxis.from);
    })
    .attr("y", -height)
    .attr("width", function (d) {
      return x(d.xaxis.to) - x(d.xaxis.from);
    })
    .attr("height", 3 * height)
    .attr("fill", function (d) {
      return d.color;
    });

  // all elements of one train are collected withing one <g class="train_cat train" data-train-id="train_id">
  var trains = zoomContainer
    .selectAll("g.train")
    .data(data)
    .enter()
    .append("g")
    .attr("class", function (d) {
      return train_cats[d.tr_id] + " train";
    })
    .attr("data-train-id", function (d) {
      return d.tr_id;
    });

  // each segment is represented by a <g class="segment"> child of its parent train <g>
  var segments = trains
    .selectAll("g.segment")
    .data(function (d) {
      return d.segments;
    })
    .enter()
    .append("g")
    .attr("class", "segment");

  // finally, let's add the <circle class="posrep">s to each segment
  segments
    .selectAll("circle.posrep")
    .data(function (d) {
      return d.pos_arr;
    })
    .enter()
    .append("circle")
    .attr("class", "posrep point")
    .attr("cx", function (d) {
      return x(d[0]);
    })
    .attr("cy", function (d) {
      return y(d[1]);
    })
    .attr("r", 0.8)
    .attr("stroke-width", 0.0);

  const adlSpeedRegex = /v opt = (\d+) km/,
    adlDistRegex = /v opt = DIST/,
    adlSpeedOrDistRegex = /v opt =/,
    adlEndRegex = /v opt END/;

  // filter functions for identifying different message types
  const adlSpeedFilter = (d) => d.message.match(adlSpeedRegex),
    adlDistFilter = (d) => d.message.match(adlDistRegex),
    adlEndFilter = (d) => d.message.match(adlEndRegex),
    genericMsgFilter = (d) =>
      !adlSpeedOrDistRegex.test(d.message) && !adlEndRegex.test(d.message);

  let txtMsgG = trains
    .selectAll("g.txt-msg")
    .data((d) => train_db[d.tr_id].text_messages)
    .enter()
    .append("g")
    .attr("class", "txt-msg")
    .attr("transform", function (d) {
      return "translate(" + x(d.km) + "," + y(d.time) + ")";
    });

  txtMsgG
    .append("circle")
    .attr("class", "point")
    .attr("cx", 0)
    .attr("cy", 0)
    .attr("r", 1)
    .attr("fill", "black")
    .attr("stroke-color", "black");

  // add generic text image for unspecific messages
  txtMsgG
    .filter(genericMsgFilter)
    .append("image")
    .attr("x", -7.2)
    .attr("y", -9.1)
    .attr("width", 10)
    .attr("height", 10)
    .attr("xlink:href", message_svg_path);

  let txtMsgGAdlSpeed = txtMsgG
    .filter(adlSpeedFilter)
    .attr("data-adl", (d) => adlSpeedFilter(d)[1])
    .style("display", "none");

  let txtMsgGAdlDist = txtMsgG
    .filter(adlDistFilter)
    .attr("data-adl", "DIST")
    .style("display", "none");

  let txtMsgGAdlEnd = txtMsgG
    .filter(adlEndFilter)
    .attr("data-adl", "END")
    .style("display", "none");

  // for speed messages, display the vopt within the circle
  txtMsgGAdlSpeed
    .append("circle")
    .attr("cy", -6)
    .attr("cx", 0)
    .attr("r", 4)
    .attr("stroke", "red")
    .attr("stroke-width", 1)
    .attr("fill", "white");
  txtMsgGAdlSpeed
    .append("text")
    .attr("class", "adl")
    .attr("y", -6)
    .attr("x", 0)
    .attr("dy", "0.35em")
    .attr("text-anchor", "middle")
    .attr("font-size", "3pt")
    .attr("stroke", "none")
    .attr("fill", "black")
    .text((d) => adlSpeedFilter(d)[1]);

  // for DIST messages, display the vopt within the circle
  txtMsgGAdlDist
    .append("circle")
    .attr("cy", -6)
    .attr("cx", 0)
    .attr("r", 4)
    .attr("stroke", "red")
    .attr("stroke-width", 1)
    .attr("fill", "white");
  txtMsgGAdlDist
    .append("text")
    .attr("class", "adl")
    .attr("y", -6)
    .attr("x", 0)
    .attr("dy", "0.35em")
    .attr("text-anchor", "middle")
    .attr("font-size", "3pt")
    .attr("stroke", "none")
    .attr("fill", "black")
    .text("<40");

  txtMsgGAdlEnd
    .append("image")
    .attr("x", -4.5)
    .attr("y", -10)
    .attr("width", 8.9)
    .attr("height", 8.9)
    .attr("xlink:href", vany_svg_path);

  var line = d3
    .line()
    .x(function (d) {
      return x(d[0]);
    })
    .y(function (d) {
      return y(d[1]);
    });

  segments
    .append("path")
    .datum(function (d) {
      return d.pos_arr;
    })
    .attr("class", "line")
    .attr("d", line);

  var zoomBeh = d3
    .zoom()
    .scaleExtent([0.5, 20])
    .on("zoom", zoomed)
    .on("end", adjustPointSize);

  var overlay = outerG
    .append("rect")
    .attr("class", "overlay")
    .attr("width", width)
    .attr("height", height)
    .style("pointer-events", "all")
    .call(zoomBeh)
    .on("mousemove", mousemove)
    .on("click", navigateToTrain)
    .on("mouseleave", function () {
      highlight(null);
    });

  var focus = zoomContainer
    .append("g")
    .attr("class", "focus")
    .style("display", "none");

  focus.append("circle").attr("cx", 0).attr("cy", 0).attr("r", 5);

  var focusTooltip = focus.append("g").attr("class", "focusTooltip");

  focusTooltip.append("rect").attr("rx", 4).attr("ry", 4);

  focusTooltip
    .append("text")
    .attr("class", "d3-tooltip")
    .attr("y", 12)
    .attr("dy", "0.35em")
    .attr("fill", "black");

  var resetZoomButton = d3
    .select("#chart-container")
    .append("button")
    .attr("id", "reset-zoom")
    .attr("class", "btn btn-sm btn-default")
    .style("position", "absolute")
    .style("top", margin.top + 7 + "px")
    .style("right", margin.right + 14 + "px")
    .attr("title", "Reset zoom")
    .on("click", function () {
      overlay
        .transition()
        .duration(1000)
        .call(zoomBeh.transform, d3.zoomIdentity);
    });
  $(resetZoomButton.node()).tooltip();
  resetZoomButton
    .append("img")
    .attr("src", zoomreset_png_path)
    .attr("style", "font-size:8pt")
    .attr("width", 16)
    .attr("height", 16);

  d3.select("#chart-container")
    .append("button")
    .attr("id", "show-legend")
    .attr("class", "btn btn-sm btn-primary")
    .attr("style", "font-size:8pt")
    .attr("title", "Legende in Form einer Liste aller dargesteller Züge")
    .style("position", "absolute")
    .style("top", margin.top + 35 + "px")
    .style("right", margin.right + "px")
    .html("Legende");

  d3.select("#chart-container")
    .append("button")
    .attr("id", "show-text-msgs")
    .attr("class", "btn btn-sm btn-primary")
    .attr("style", "font-size:8pt")
    .attr("title", "Text-Meldungen auf dem DMI")
    .style("position", "absolute")
    .style("top", margin.top + 62 + "px")
    .style("right", margin.right + "px")
    .html("DMI-Mld.");

  d3.select("#chart-container")
    .append("button")
    .attr("id", "show-hide-adl")
    .attr("class", "btn btn-sm btn-primary")
    .attr("style", "font-size:8pt")
    .attr("title", "ADL-Meldungen ein/ausblenden")
    .attr("data-state", "off")
    .style("position", "absolute")
    .style("top", margin.top + 89 + "px")
    .style("right", margin.right + "px")
    .html("ADL");

  // event handler for zoom events:
  // - apply zoom/pan transformation to the zoomContainer
  // - redraw x- and y-axis
  function zoomed() {
    var tr = d3.event.transform;
    zoomContainer.attr("transform", tr);
    xaxis_g.call(xaxis.scale(d3.event.transform.rescaleX(x)));
    yaxis_g.call(yaxis.scale(d3.event.transform.rescaleY(y)));
  }

  // 2017-05-24 / Matthias Kummer
  // ajusts the point size according to the current zoom factor
  function adjustPointSize() {
    var radius =
      0.8 / Math.sqrt(Math.max(d3.zoomTransform(overlay.node()).k, 1));
    svg.selectAll("circle.point").attr("r", radius);
  }

  function showTrainTable(train_id) {
    var train = train_db[train_id];
    trainNrTD.html(train.train_nr + "<br/>(" + train.train_category + ")");
    var uic_str = train.uic ? "<br/>UIC-Nr. " + train.uic : "";
    obuTD.html("OBU-ID " + train.obu_id + ": " + train.obu + uic_str);
    timesTD.html(train.times);
    numPosTD.html(train.num_pos);
    trainCatVmaxTD.html(train.vmax + " / " + train.train_cat);
    axleLoadTD.html(train.axle_load);
    trainLenTD.html(train.train_len);
    gbtTD.html(train.gbt ? "<i class='fa fa-check'></i>" : "");
    registerTD.html(train.register_events);
    unregisterTD.html(train.unregister_events);
    l2TD.html(train.l2 ? "<i class='fa fa-check'></i>" : "");
    trainTable.removeClass("d-none");
  }

  function highlightTrain(trainG) {
    if (trainG) {
      trainG.classed("highlighted", true);
    } else {
      d3.select("g.train.highlighted").classed("highlighted", false);
    }
  }

  // d is an object {x, y, pr}
  function highlight(d) {
    if (!d) {
      focus.style("display", "none");
      highlightTrain(null);
      trainTable.addClass("d-none");
    } else {
      var str, train_id;
      if (d.pr) {
        str =
          train_db[d.pr[5]].train_nr +
          ": " +
          moment(d.pr[1]).format("HH:mm:ss") +
          ", " +
          d.pr[3] +
          " km/h, km " +
          d.pr[6] +
          " (" +
          d.pr[2] +
          ")";
        train_id = d.pr[5];
      } else {
        str = d.msg.message;
        train_id = d.msg.train_id;
      }
      /*if (d.data.pr) {
        var pr = d.data.pr;
        str = train_db[pr[5]].train_nr + ": " + moment(pr[1]).format("HH:mm:ss") + ", " + pr[3] + " km/h, km " + pr[6] + " (" + pr[2] + ")";
        train_id = pr[5];
      } else {
        str = d.data.msg.message;
        train_id = d.data.msg.train_id;
      }*/

      highlightTrain(null);
      highlightTrain(d3.select("[data-train-id='" + train_id + "']"));
      var s = "scale(" + 1 / d3.zoomTransform(overlay.node()).k + ")";
      focus
        .style("display", "")
        .attr("transform", "translate(" + x(d.x) + "," + y(d.y) + ")");
      //.attr("transform", "translate(" + d.x + "," + d.y + ")")
      focus.select(".focusTooltip").attr("transform", s);
      var text = focus.select("text").text(str);
      if (x(d.x) > width / 2) {
        text.attr("text-anchor", "end").attr("x", -12);
      } else {
        text.attr("text-anchor", "start").attr("x", 12);
      }

      var bbox = text.node().getBBox();
      focus
        .select("rect")
        .attr("x", bbox.x - 4)
        .attr("y", bbox.y - 4)
        .attr("width", bbox.width + 8)
        .attr("height", bbox.height + 8);
      showTrainTable(train_id);
    }
  }

  // returns the closest position report to the mouse coordinates mx/my
  // returns null if mx/my isn't close enough to any position report
  function findPosRep(mx, my) {
    var site = null,
      minDist = 64;
    for (var i = 0; i < arrayForFind.length; i++) {
      var c = arrayForFind[i];
      if (mx - c.x < 8 && my - c.y < 8) {
        var dist = (mx - c.x) * (mx - c.x) + (my - c.y) * (my - c.y);
        if (dist < minDist) {
          minDist = dist;
          site = c;
        }
      }
      if (site && minDist < 10) {
        break;
      }
    }
    return site;
  }

  function mousemove() {
    var m = d3.mouse(zoomContainer.node());
    var mx = m[0],
      my = m[1];
    var site = voronoiDiagram.find(mx, my, voronoiRadius);
    //console.log(site.data);
    //var site = findPosRep(mx, my);
    if (site != null) {
      highlight(site && site.data);
    } else {
      highlight(null);
    }
    return false;
  }

  function navigateToTrain() {
    var m = d3.mouse(zoomContainer.node());
    var mx = m[0],
      my = m[1];
    var site = findPosRep(mx, my);
    if (site) {
      var url = train_path.replace("__ID__", site.train_id);
      window.open(url, "_blank");
    }
  }

  function highlightTrainFromTable() {
    var trainId = $(this).data("train-id");
    if (trainId) {
      highlightTrain(d3.select("[data-train-id='" + trainId + "']"));
      showTrainTable(trainId);
    }
  }
  function hideTrainFromTable() {
    highlightTrain(null);
    trainTable.addClass("d-none");
  }

  function highlightTimeshift(e) {
    let a = $(e.target),
      x1 = $(a).data("xmin"),
      x2 = $(a).data("xmax"),
      y1 = $(a).data("start-time"),
      y2 = $(a).data("end-time"),
      cs = $(a).data("case");
    bgMarkingsG
      .append("rect")
      .attr("id", "timeshift-overlay")
      .attr("class", `timeshift case-${cs}`)
      //.attr("fill", "#aaa")
      .attr("opacity", "0.5")
      .attr("x", x(x1))
      .attr("width", x(x2) - x(x1))
      .attr("y", y(y1))
      .attr("height", y(y2) - y(y1));
  }

  function hideTimeshift() {
    $("#timeshift-overlay").remove();
  }

  $("#train-legend-dialog")
    .dialog({
      title: "Legende",
      width: 460,
      height: 600,
      autoOpen: false,
      position: {
        my: "top",
        at: "bottom",
        of: $("#show-legend"),
      },
    })
    .removeClass("d-none");

  $("#train-legend-dialog tr.train").hover(
    highlightTrainFromTable,
    hideTrainFromTable
  );
  initSingleDataTable($("#train-legend-dialog table.datatable"));

  const msgSel = "#text-msgs-dlg";
  $(msgSel)
    .dialog({
      title: "Textmeldungen auf dem DMI",
      width: 600,
      height: 760,
      autoOpen: false,
      position: {
        my: "top",
        at: "bottom",
        of: $("#show-text-msgs"),
      },
      open: () => $("table.datatables", msgSel).DataTable().columns.adjust(),
    })
    .removeClass("d-none"); // d-none is needed to not destroy the layout before this code is run

  $("#show-legend")
    .click(function (e) {
      var sel;
      e.preventDefault();
      sel = "#train-legend-dialog";
      if ($(sel).parent().css("display") === "block") {
        return $(sel).dialog("close");
      } else {
        $(sel).dialog("open");
        $("table.datatables", sel).DataTable().columns.adjust();
      }
    })
    .tooltip();

  $("#show-text-msgs")
    .on("click", function (e) {
      let msg, msgs, train_data, train_id, _i, _len;
      const sel = "#text-msgs-dlg",
        t = $(`${sel} table`).DataTable();
      e.preventDefault();
      // init table data from train_db if empty
      if (t.data().length == 0) {
        for (train_id in train_db) {
          train_data = train_db[train_id];
          if ((msgs = train_data.text_messages) != null) {
            for (_i = 0, _len = msgs.length; _i < _len; _i++) {
              msg = msgs[_i];
              let row = t.row.add([
                moment(msg.time).format("HH:mm:ss"),
                train_data.train_nr,
                msg.message,
              ]);
              t.row(row).node().setAttribute("data-train-id", train_id);
            }
          }
        }
        $(`${sel} table`)
          .on("mouseenter", "tr", highlightTrainFromTable)
          .on("mouseleave", "tr", hideTrainFromTable);
        t.draw();
      }
      // open or close dialog
      if ($(sel).parent().css("display") === "block") {
        $(sel).dialog("close");
      } else {
        $(sel).dialog("open");
      }
    })
    .tooltip();

  $("#show-hide-adl")
    .click(function (e) {
      e.preventDefault();
      if ($(this).data("state") === "off") {
        $("[data-adl]").show("fade");
        return $(this).data("state", "on");
      } else {
        $("[data-adl]").hide("fade");
        return $(this).data("state", "off");
      }
    })
    .tooltip();

  $("li.timeshift-link a").hover(highlightTimeshift, hideTimeshift);
}

postInit("trains#graphical_timetable_d3", initGraphicalTimetable);
