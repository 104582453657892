import { initializers } from "./platin"
import "jquery-validation/dist/jquery.validate"
import "jquery-validation/dist/localization/messages_de"

validationStatusForSubmitButton = (form) ->
  button = $(form).find("input[name=\"commit\"]")
  if form.valid()
    button.prop("disabled", false)
  else
    button.prop("disabled", "disabled")

validDate = (ddmmyyyy) ->
  components = ddmmyyyy.split('.')
  d = new Date(components[2], components[1]-1, components[0])
  return ((d.getMonth() == components[1]-1) && (d.getDate() == parseInt(components[0])) && (d.getYear() < 200) )

installValidation = (additional_options = {}) ->
  options =
    errorElement: "div"
    ignore: "" # override default ignore :hidden to make validation work also on hidden tabs
    errorPlacement: (error, element) ->
      if $(element).parent().hasClass("input-group")
        error.insertAfter($(element).parent()).addClass("invalid")
      else
        error.insertAfter(element).addClass("invalid")
    invalidHandler: (event, validator) ->
      validator.currentForm.classList.add("was-validated")
  $.validator.addMethod "anyDate", (value, element) ->
    value.match /^(0?[1-9]|[12][0-9]|3[0-1])[/., -](0?[1-9]|1[0-2])[/., -](19|20)?\d{2}$/

  $.validator.addMethod("validDate", (value, element) ->
      return @.optional(element) || validDate(value)
    "Bitte ein gültiges Datum eingeben"
  )

  $(form).validate($.extend(options, additional_options)) for form in $("form.validate")

  $("form.validate input,textarea").on "keyup blur change", ->
    validationStatusForSubmitButton $(@).closest('form')
  $("form.validate select").on "change", ->
    validationStatusForSubmitButton $(@).closest('form')

initializers.push installValidation

export { validationStatusForSubmitButton, installValidation }
