import {
  initializers
} from "./platin";
import {
  hideTooltips
} from "./utils";
import $ from "jquery";

function showHistory(target) {
  return function (data) {
    target.popover({
      html: true,
      content: $(data),
      title: "Versionshistory",
      template: "<div class=\"popover attribute-history\" role=\"tooltip\"><div class=\"arrow\"></div><h4 class=\"popover-header\"></h3><div class=\"popover-body\"></div></div>"
    }).popover("show");
  };
}

function installAttributeHistoryHandler() {
  $(document).on("click", ".attribute-history", function () {
    const target = $(this),
      popover = target.data("bs.popover");
    if (typeof (popover) == "undefined") {
      const recordType = target.data("history-class"),
        recordId = target.data("history-id"),
        attribute = target.data("history-attribute");
      hideTooltips();
      $.get("/versioning/attribute_history", {
        record_type: recordType,
        record_id: recordId,
        attribute: attribute
      }, showHistory(target));
    } else {
      target.popover("dispose");
    }
  });
}

initializers.push(installAttributeHistoryHandler);