/* 2018-02-21 / Matthias Kummer */
/* globals $               */

import { initializers } from "./platin";

function openCollapseHandler() {

  $(document).on("shown.bs.collapse", function(event){
    const dt = $("table.dataTable", event.target);
    if (dt.length > 0){
      dt.DataTable().columns.adjust().draw();
    }
  });
}

initializers.push(openCollapseHandler);
