import $ from "jquery";
import {
  initializers
} from "./platin";

/* 2019-07-13 / Matthias Kummer
   use this to e.g. reset all selects of a datetime select to
   empty values
   pass container of selects
*/
function handleSelectEraser() {
  $(document).on("click", ".select-eraser", function (e) {
    e.preventDefault();
    $(this).closest(".form-group").find("select").val("");
  });
}

function removeCollectionElement() {
  var contentDiv = $(this).closest("div.content"),
    collection = contentDiv.data("collection"),
    index = collection.findIndex(el => el.id == $(this).data("id"));
  if (typeof (collection) != "object") {
    collection = [];
  } else {
    collection.splice(index, 1);
  }

  contentDiv.data("collection", collection);
  initCollectionSelector.call(contentDiv);
}

function addCollectionElement() {
  var id = $("option:selected", this).val(),
    text = $("option:selected", this).text(),
    contentDiv = $(this).closest("div.content"),
    collection = contentDiv.data("collection"),
    unique = contentDiv.data("unique");

  if (typeof (collection) != "object") {
    collection = [];
  }
  if (!unique || typeof (collection.find(el => el.id == id)) == "undefined") {
    collection.push({
      id: id,
      text: text
    });
  }

  contentDiv.data("collection", collection);
  initCollectionSelector.call(contentDiv);
}

function initCollectionSelector() {
  var collection = $(this).data("collection"),
    parentValue = $("span.parent-value", $(this)),
    hiddenFieldName = $(this).data("name"),
    emptyElement,
    options, select, wrapper, contents;

  function collectionElement(el) {
    return "<span class='badge badge-secondary'>" + el.text +
      "<input type=hidden name=\"" + hiddenFieldName + "\" value=\"" + el.id + "\">" +
      "<i class=\"fa fa-trash remove-collection-element\" data-id=\"" + el.id + "\"/></span>";
  }

  options = "<option>Hinzufügen…</option>" + $(this).data("set").map(function (el) {
    return "<option value = \"" + el.id + "\">" + el.text + "</option>";
  }).join();
  select = $("<select>").html(options);
  if (typeof (collection) != "object") {
    collection = [];
  }
  contents = collection.map(collectionElement).join("") + select.prop("outerHTML");
  /* if no collectionElement is present, we need an empty one; otherwise no param with the needed name is returned */
  emptyElement = `<input type=hidden name="${hiddenFieldName}" value="">`;
  wrapper = $("<div class=\"form-control collection-selector\">");
  wrapper.html(emptyElement + contents);
  $(this).html(wrapper);
  $(this).append(parentValue);
}

const initCollectionSelectors = function () {
  $(".content[data-collection]")
    .on("click", "i.remove-collection-element", removeCollectionElement)
    .on("change", "select", addCollectionElement)
    .each(initCollectionSelector);
};

function formatSubmitButton() {
  const submitButton = $("input.ctx-bar[type=\"submit\"]"),
    form = submitButton.closest("form"),
    ctxBar = $("nav.context-links");
  if ((submitButton.length == 1) && (ctxBar.length == 1)) {
    submitButton.appendTo(ctxBar).addClass("btn-sm");
    ctxBar.appendTo(form);
  }
}

initializers.push(initCollectionSelectors);
initializers.push(handleSelectEraser);
initializers.push(formatSubmitButton);

export { handleSelectEraser };