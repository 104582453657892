/* global $ */

import {
  postInit
} from "./platin";

const editTntUnResponsibles = function () {
  return ($("[data-toggle='edit-tnt-un-responsibles']").hasClass("fa-unlock"));
};

const installTntUnResponsiblesLinkHandlers = function () {
  $("#edit-tnt-un-responsible").on("shown.bs.modal", function () {
    if ($("#person_name").is(":visible")) {
      $("#person_name").focus();
    }
  });

  // on click on edit button, populate modal with TNT Un responsible data
  $("body").on("click", "[data-target='#edit-tnt-un-responsible']", function (event) {
    var modal = $("#edit-tnt-un-responsible"),
      editLink = $(event.currentTarget);
    if (editLink.data("person-id")) {
      //$("h5.modal-title", modal).html("Un-Verantwortliche(n) bearbeiten");
      $("#person_name", modal).typeahead("val", editLink.data("person-name"));
      $("#person_id", modal).val(editLink.data("person-id"));
      $("#scope", modal).val(editLink.data("scope"));
      $("#stv", modal).prop("checked", editLink.data("stv"));
      $("#tnt_un_responsible_id", modal).val(editLink.data("tnt-un-responsible-id"));
    } else {
      //$("h5.modal-title", modal).html("Un-Verantwortilche(n) hinzufügen");
      $("#tnt_un_responsible_id", modal).val(null);
      $("#person_name", modal).typeahead("val", "").show();
      $("#person_id", modal).val("");
      $("#scope", modal).val("");
      $("#stv", modal).prop("checked", false);
    }
  });
};

postInit("tnts#show", function () {
  const installEditTntUnResponsiblesHandler = function () {
    $("body").on("click", "[data-toggle='edit-tnt-un-responsibles']", function (event) {
      event.preventDefault();
      $(".edit-tnt-un-responsibles").toggleClass("hidden");
      if ($(this).hasClass("fa-lock")) {
        $(this).removeClass("fa-lock").addClass("fa-unlock");
      } else {
        $(this).removeClass("fa-unlock").addClass("fa-lock");
      }
    });
  };

  installEditTntUnResponsiblesHandler();
  installTntUnResponsiblesLinkHandlers();
});

export {
  editTntUnResponsibles,
  installTntUnResponsiblesLinkHandlers
};