import {
  postInit
} from "./platin";
import $ from "jquery";
import {
  hideTooltips
} from "./utils";
import {
  activateAutocomplete
} from "./autocomplete";

function keyToSelector(key) {
  if (key.match(/^CD/)) {
    return "CD";
  } else if (key.match(/^MDE/)) {
    return "MDE";
  } else {
    return key.replace(".", "-");
  }
}

function prepareSvgForDisplay(svg, locKeys) {
  $("g.occupied", svg).removeClass("occupied");
  $("g.active", svg).removeClass("active");
  for (let key of locKeys) {
    const g = $(`g[id='${keyToSelector(key)}']`, svg);
    if (key.match(/^EB/)) {
      g.addClass("active");
    } else if (key.match(/^FL/)) {
      g.addClass("poweroff");
    } else if (key.match(/^BTGY/)) {
      $("g#BTGY", svg).addClass("occupied");
    } else if (key.match(/^BTGV/)) {
      $("g#BTGV", svg).addClass("occupied");
    } else {
      g.addClass("occupied");
    }
  }
  $(svg).parent().tooltip({
    selector: "[title]"
  });

  $(svg).click(() => {
    svg.toggleClass("highlighted");
  });

}

const initLocationsViewer = function () {
  const container = $("#locations-map"),
    svg = $("svg", container),
    locKeys = container.data("locs");
  prepareSvgForDisplay(svg, locKeys);
};

const initLocationsEditor = function () {

  const locSel = "input[id$='_loc']";
  const toSel = "input[id$='_to']";
  const viaSel = "input[id$='_via']";
  const routeSel = ".route";
  const addBtnSel = ".add-line";
  const destroyFlagSelector = "[id$='_destroy']";
  const table = $("table.location-specs");
  const nameBase = "mission[location_specs_attributes][";
  const nameSel = `[name^=\"${nameBase}\"]`;
  const nameRegex = /(mission\[location_specs_attributes\]\[)(\d+)(.+)/;
  const idRegex = /(mission_location_specs_attributes_)(\d+)(.+)/;

  function hide(el) {
    el.addClass("invisible");
  }

  function addLine(e) {
    e.preventDefault();
    hideTooltips();
    let oldTr = $(this).closest("tr"),
      newTr = oldTr.clone(),
      toEl = $(toSel, newTr),
      viaEl = $(viaSel, newTr),
      inputs = $(nameSel, newTr),
      ipNode,
      elIdx = oldTr.siblings("tr").length + 1;
    $("input, textarea", newTr).val("").removeAttr("data-category");
    $("select", newTr).val([]);
    $(".empty-on-clone", newTr).html("");
    /* update name and id attributes according to Rails array naming convention */
    for (ipNode of inputs) {
      let input = $(ipNode),
        nameMatch = input.attr("name").match(nameRegex),
        idMatch = input.attr("id").match(idRegex);
      input.attr("name", nameMatch[1] + elIdx + nameMatch[3]);
      input.attr("id", idMatch[1] + elIdx + idMatch[3]);
    }
    hide(toEl);
    hide(viaEl);
    $(destroyFlagSelector, newTr).val("0");
    newTr.removeClass("to-remove");
    $("[data-autocomplete-url]", newTr).each(activateAutocomplete);
    newTr.insertAfter(oldTr);
    $(locSel, newTr).focus();
  }

  function removeLine(e) {
    e.preventDefault();
    let tr = $(this).closest("tr"),
      destroyAttribute = $(destroyFlagSelector, tr);
    if (tr.hasClass("to-remove")) {
      tr.removeClass("to-remove");
      destroyAttribute.val("0");
    } else {
      tr.addClass("to-remove");
      destroyAttribute.val("1");
    }
  }

  function updateDetails(el) {
    let handler = function (data) {
      let tr = $(el).closest("tr"),
        routeEl = $(routeSel, tr);
      $(el).val(data.normalized);
      routeEl.html(data.result);
    };
    return handler;
  }

  function fetchDetails(e) {
    const input = $(e.target),
      tr = input.closest("tr"),
      query = {
        query: $(locSel, tr).val()
      };
    $.get("/locations/expand_multi_string", query, updateDetails(input));
  }

  $(table).on("click", addBtnSel, addLine);
  $(table).on("click", ".remove-line", removeLine);
  $(table).on("focusout", locSel, fetchDetails);
};

// postInit("missions#show", initLocationsViewer);
postInit("missions#edit", initLocationsEditor);

export {
  prepareSvgForDisplay,
  keyToSelector,
  initLocationsViewer
};