import $ from "jquery";
import * as d3 from "d3";
import {
  postInit
} from "./platin";

const sysconStatChart = function () {

  const createPieChart = function (data, selector) {

    const container = $(selector);
    const width = $(container).attr("width") || 48;
    const height = $(container).attr("height") || 48;
    const radius = Math.min(width, height) / 2 - 4;

    const getSvg = function (selector) {
      let svg = d3.select(selector)
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", `translate(${width / 2}, ${height / 2})`)
        .attr("class", "syscon-color");
      return svg;
    };

    /* 2019-01-16 / Matthias Kummer
       to keep the color values of SysKon status values in one single place (the CSS file),
       we need to find out programmatically the actual color value by creating a temporary div in the DOM
       having the correct class and determine it's backgroundColor value
    */
    const getBackgroundColorOfStatus = function (status) {
      var el = $(`<div class='syscon-color ${status}'>`).appendTo($("body")),
        color = getComputedStyle(el[0]).backgroundColor;
      el.remove();
      return color;
    };

    const pie = d3.pie()
      .value(d => d.count)
      .sort(null);

    const arc = d3.arc()
      .innerRadius(0)
      .outerRadius(radius);

    const svg = getSvg(selector);
    const path = svg.selectAll("path")
      .data(pie(data));

    path.enter().append("path")
      .attr("fill", (d) => getBackgroundColorOfStatus(d.data.status))
      .attr("d", arc)
      .attr("stroke", "white")
      .attr("stroke-width", "0px")
      .each(function (d) {
        this._current = d;
      });
  };

  d3.json("/syscon_objects/stats.json").then(data_per_location => {
    const locs = new Set(["all", "line", "btgy", "btgv"]);
    for (let loc of locs) {
      let data = data_per_location[loc];
      createPieChart(data, `#stat-chart-${loc}`);
    }
  });
};

const installSysconOverviewLinkHandler = function () {
  $("[data-syscon-object-path]").click(function () {
    window.open($(this).data("syscon-object-path"), "_blank").focus();
  });
};

postInit("syscon_objects#menu", sysconStatChart);
postInit("syscon_objects#stats", sysconStatChart);
postInit("syscon_objects#overview", installSysconOverviewLinkHandler);
postInit("syscon_objects#overview_btgy", installSysconOverviewLinkHandler);
postInit("syscon_objects#overview_btgv", installSysconOverviewLinkHandler);