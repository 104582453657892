/* global $, App */
/* 2021-09-04 / Matthias Kummer
   experimental code for realizing websocket based data feed for a datatables table
   eventually dropped in favor of standard scroller functionality (AJAX based)
*/
import {
  postInit
} from "./platin";
//import * as d3 from "d3";
import consumer from "./channels/consumer";

let subscription, dt,
  dataToComplete = [], fetchIndex = 0;


function getDt(){
  //if (!dt){
  dt = $("#dt-adl-events").DataTable();
  //}
  return dt;
}

function handleNewFields(fields){
  let ctxLinksUL = $(".context-links"),
    progressID = "adl-progress",
    progressContainer = $("div.progress", ctxLinksUL),
    progress = $(`#${progressID}`, ctxLinksUL);
  if (progress.length == 0){
    progressContainer = $("<div class=\"progress my-auto mx-1 float-right\" style=\"width:100px\"></div>").appendTo(ctxLinksUL);
    progress = $(`<div id="${progressID}" class="progress-bar" style="transition:none"></div>`).appendTo(progressContainer);
    progress.html("Lade Zugdaten…");
  }
  let row = getDt().row( (i,d) => d.adl_id == fields.adl_id);
  if (row && row.length > 0) {
    row.data().train_nr = fields.train_nr;
    row.data().time_from = fields.time_from;
    row.data().time_until = fields.time_until;
    row.data().vavg = fields.vavg;
    row.data().vavg2 = fields.vavg2;
    row.data().track_from = fields.track_from;
    row.data().track_until = fields.track_until;
    row.invalidate();
  }
  fetchIndex++;
  if (fetchIndex < dataToComplete.length){
    progressContainer.removeClass("d-none");
    progress.css("width", `${fetchIndex / dataToComplete.length * 100}%`);
    requestFields(dataToComplete[fetchIndex].adl_id);
  } else {
    progressContainer.addClass("d-none");
  }
}

function receiveMessage(message) {
  if (message.type == "add-fields") {
    handleNewFields(message.fields);
  }
}

function receiveIndexMessage(message) {
  let progress;
  switch (message.type) {
  case "start-import":
    $("#import-adl-events-modal").modal("show");
    $("#import-adl-events-modal #channel-output").html(`Lese ${message.file}…`);
    break;
  case "progress-import":
    progress = 100.0 * (message.current / message.total);
    $("#import-adl-events-modal #channel-output").html(`Verarbeite Zeile ${message.current} von ${message.total}`);
    $("#import-adl-events-modal .progress-bar").css("width", `${progress}%`);
    break;
  case "save-import":
    $("#import-adl-events-modal #channel-output").html("Schreibe in Datenbank…");
    break;
  case "done-import":
    $("#import-adl-events-modal").modal("hide");
    break;
  }
}

function requestFields(adl_id){
  subscription.send({type: "request-fields", adl_id: adl_id});
}

function scrollerDidUpdate(){
  dataToComplete = getDt().rows().data();
  fetchIndex = 0;
  requestFields(dataToComplete[0].adl_id);
}

function startEventsSubscription(){
  if (!subscription){
    subscription = consumer.subscriptions.create(
      { channel: "ADLEventsChannel" },
      { received: receiveMessage }
    );
  }
}

function startIndexSubscription(){
  if (!subscription){
    subscription = consumer.subscriptions.create(
      { channel: "ADLEventsChannel" },
      { received: receiveIndexMessage }
    );
  }
}
function stopSubsription(){
  if (subscription){
    App.consumer.subscriptions.remove(subscription);
  }
  subscription = null;
}

function initADLEventsEvents(){
  startEventsSubscription();
  $("#dt-adl-events").on("processing.dt", function (_e, settings, processing){
    //console.log("processing", processing);
    if (processing == false){
      scrollerDidUpdate();
    }
  });
  window.onbeforeunload = stopSubsription;
}

function initADLEventsIndex(){
  startIndexSubscription();
  window.onbeforeunload = stopSubsription;
}

postInit("adl_events#events", initADLEventsEvents);
postInit("adl_events#index", initADLEventsIndex);