import $ from "jquery";
import { postInit } from "platin";

function installQualificationsHandler() {
  $("#edit-training-set-modal").on("show.bs.modal", function (event) {
    const triggeringLink = $(event.relatedTarget),
      trainingIds = triggeringLink.data().trainingIds,
      select = $("select", this);
    let trainingSetIndex = triggeringLink.data().trainingSetIndex;
    $("option", select).prop("selected", null);
    if (!(typeof (trainingIds) == "undefined")) {
      for (let trainingId of trainingIds) {
        $(`option[value="${trainingId}"`, select).prop("selected", "selected");
      }
    }
    if ((typeof (trainingSetIndex) == "undefined")) {
      trainingSetIndex = null;
    }
    $("#training_set_index", this).val(trainingSetIndex);
  }).on("shown.bs.modal", function () { $("select", this).focus(); });
}

postInit("qualifications#show", installQualificationsHandler);