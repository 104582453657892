import {
  postInit
} from "./platin";
import $ from "jquery";
import {
  hideTooltips
} from "./utils";

const attributes = ["id", "_destroy", "train", "pos", "rail_car_type_id", "rail_car_id"],
  selectors = attributes.reduce((obj, val) => {
    obj[val] = `[id$=${val}]`;
    return obj;
  }, {}),
  nameRegex = /^(mission\[missions_rail_cars_attributes\]\[)\d+(\].+)$/,
  idRegex = /^(mission_missions_rail_cars_attributes_)\d+(_.+)$/,
  dialog = $("#rail-car-disposition-dialog");

const installRailCarDisposition = function () {

  const modal = $("#rail-car-disposition-dialog");
  let table;

  function recalculatePositions() {
    {
      $("tbody tr", table).not(".to-remove").each(function (i, tr) {
        let tdPos = $("td.pos", this);
        $("span", tdPos).html(i + 1);
        $("input", tdPos).val(i + 1);
      });

      $("tbody tr", table).each(function (i, tr) {
        /* adjust all name and id attributes for the updated index */
        $("[name^='mission[missions_rail_cars_attributes][']", tr).each(function () {
          let res = $(this).attr("name").match(nameRegex);
          $(this).attr("name", `${res[1]}${i}${res[2]}`);
          if ($(this).attr("id") && (res = $(this).attr("id").match(idRegex))) {
            $(this).attr("id", `${res[1]}${i}${res[2]}`);
          }
        })
      });
    }
  }

  function addLine(e) {
    e.preventDefault();
    let oldTr = $(this).closest("tr"),
      newTr = oldTr.clone(true, true);
    $("select", newTr).val([]);
    $("select[id$=rail_car_id", newTr).html("");
    $("input[name$='[id]']", newTr).val("");
    $(".empty-on-add", newTr).html("");
    newTr.insertAfter(oldTr);
    $(selectors.rail_car_type_id, newTr).focus();
    recalculatePositions()
  }

  function duplicateLine(e) {
    e.preventDefault();
    let oldTr = $(this).closest("tr"),
      newTr = oldTr.clone(true, true);

    $("input[type=hidden][id$=id]", newTr).val("");
    newTr.insertAfter(oldTr);
    recalculatePositions();
    $(selectors.location, newTr).focus();
  }

  function removeLine(e) {
    e.preventDefault();
    let tr = $(this).closest("tr"),
      destroyAttribute = $(selectors._destroy, tr);
    // new line (not persisted)? => just remove
    if ($("input[name$='id]']", tr).attr("value") == "") {
      hideTooltips();
      tr.remove();
    } else { // persisted line => toggle mark as to be destroyed
      if (tr.hasClass("to-remove")) {
        tr.removeClass("to-remove");
        destroyAttribute.val("0");
      } else {
        tr.addClass("to-remove");
        destroyAttribute.val("1");
      }
    }
    recalculatePositions();
  }

  function populateRailCarSelect(select) {
    const sel = select;
    return function populateSelect(data) {
      sel.html("");
      for (let railCar of data) {
        sel.append(`<option value=${railCar.id}>${railCar.name}</option>`)
      }
    }
  }

  function handleRailCarTypeChange() {
    const val = $(this).val(),
      railCarSelect = $(this).closest("tr").find("select[id$='rail_car_id']");
    $.get(`/rail_car_types/${val}/rail_cars.json`, populateRailCarSelect(railCarSelect));
  }

  function initForm(event) {
    const form = $(event.relatedTarget).data("form"),
      trainIndex = $(event.relatedTarget).data("train-index");
    $("form", event.target).replaceWith(form);
    //console.log(form);
    table = $("table#missions-rail-cars");
    $(".modal-title").html(`Fahrzeugdisposition Zug ${trainIndex}`);
    import( /* webpackChunkName: "tablednd" */ "tablednd/js/jquery.tablednd").then(() => {
      table.tableDnD({
        dragHandle: ".drag-handle",
        onDragStop: recalculatePositions
      });
    })
  }

  $("th.recalculate-positions").addClass("pointer").click(recalculatePositions);
  modal.on("click", ".add-line", addLine);
  modal.on("click", ".duplicate-line", duplicateLine);
  modal.on("click", ".remove-line", removeLine);
  modal.on("show.bs.modal", initForm);
  modal.on("change", "#rail_car_type_id", handleRailCarTypeChange);
}

export {
  installRailCarDisposition
}