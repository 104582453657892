/*
    2018-07 / Matthias Kummer

    handles clicks on
        - <i class="explain">
        - <any class="label add-explanation">
        - <any class="label"><label class="add-explanation">
    to display a popover with contents fetched by AJAX from
    /explanations/explain with params class_name, attribute_name and optional value

    the optional value allows for separate explanations for specific values of enums
*/
import $ from "jquery";
import {
  getActionKey
} from "./utils";
import {
  initializers
} from "./platin";

$(document).on("click", "i.explain, .label.add-explanation, .label label.add-explanation", function () {
  const className = $(this).data("class-name"),
    attributeName = $(this).data("attribute-name"),
    value = $(this).data("value"),
    jQExplainButton = $(this);

  const showExplanation = function (response) {
    const popover = jQExplainButton.data("bs.popover");
    let title = "Erklärung";

    if (response.url) {
      title += `<a class="fa fa-edit" href="${response.url}" data-remote="true" id="test"></a>`;
    }

    const contentFn = function () {
      return response.content;
    };

    $("[data-original-title]").popover("hide"); // always show only one popover at a time
    if ((response.action == "show") || (response.action == "excuse")) {
      if (popover && ($(".popover-body", popover.getTipElement()).html() != response.content)) {
        jQExplainButton.popover("dispose"); // content cannot be updated; popover needs to be rebuilt from scratch if content changes
      }

      jQExplainButton.popover({
        template: "<div class=\"popover explanation\" role=\"tooltip\"><div class=\"arrow\"></div><h3 class=\"popover-header\"></h3><div class=\"popover-body\"></div></div>",
        title: title,
        content: contentFn,
        html: true,
        trigger: "manual"
      });
      jQExplainButton.popover("show")
        .on("shown.bs.popover", function () {
          $(".popover.explanation a.fa-edit").attr("data-remote", "true");
        });

    } else if (response.action == "create") {
      $.get("/explanations/new.js?" + $.param({
        class_name: response.class_name,
        attribute_name: response.attribute_name,
        value: value,
        update_label: "true"
      }));
    }
  };

  $.get(
    "/explanations/explain", {
      class_name: className,
      attribute_name: attributeName,
      value: value
    },
    showExplanation,
    "json"
  );
  $(".attribute-list .label, form.platin .label label").removeClass("add-explanation");
});

function installExplainIconHandler() {
  $("body").on("click", "#add-explanations-link", function (e) {
    e.preventDefault();
    $(".attribute-list .label, form.platin .label label").addClass("add-explanation");
  });
}

function installInfoPanelHandler() {
  const card = $(".card"),
    cardBody = $(".card-body.tab-content", card);
  if (cardBody.length > 0) {
    const infoPanel = $("<div class=\"alert alert-info alert-dismissible info-panel mb-0 d-none\"></div>");

    /* 2020-03-06 / Matthias Kummer
       add hiding of help-icon for tabs with no info panel
    */
    card.on("show.bs.tab", ".card-header a[data-toggle=\"tab\"]", function () {
      $(".help-icon").toggle($(this).data("info-panel"));
    });

    card.on("click", ".card-header .help-icon", function () {
      const actionKey = getActionKey(),
        tabLink = $(this).closest(".card-header").find(".nav-link.active");

      function showInfoPanel(response) {
        const href = tabLink.attr("href"),
          activeTabPane = $(href, card),
          existingInfoAlert = $(".alert-info", activeTabPane);
        if (existingInfoAlert.length > 0) {
          infoPanel.insertAfter(existingInfoAlert.addClass("mb-0"));
        } else {
          cardBody.prepend(infoPanel);
        }

        infoPanel.html("").append(response.content);
        infoPanel.removeClass("d-none");
        tabLink.on("hide.bs.tab", () => infoPanel.addClass("d-none"));
      }

      if (!infoPanel.hasClass("d-none")) {
        infoPanel.addClass("d-none");
      } else {
        let tabID, keys;
        if (tabLink.length > 0) {
          tabID = tabLink.attr("href");
          keys = {
            routes: actionKey,
            tab: tabID
          };
          $.get("/info_panels/find", keys, showInfoPanel);
        }
      }
    });
  }
}

$("html").on("click", function (e) {
  if (typeof $(e.target).data("original-title") == "undefined" && !$(e.target).parents().is(".popover.in")) {
    $("[data-original-title]").popover("hide");
  }
});

initializers.push(installInfoPanelHandler);
initializers.push(installExplainIconHandler);