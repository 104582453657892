import {
    postInit
} from "./platin";
import $ from "jquery";

const render = function (event, element) {
    if (!$("#show_" + event.class).is(":checked")) {
        return false;
    }
    element.attr("title", event.description);
    element.tooltip({
        html: true
    });
    element.addClass(event.status);
    element.addClass(event.class);
    if (event.bikeboards){
        element.addClass("bikeboards")
    }
};

const clickHandler = function (calEvent) {
    let url;
    if (calEvent.class == "meeting") {
        url = `/${window.locale}/meetings/${calEvent.id}`;
    } else if (calEvent.class == "training_session") {
        url = `/${window.locale}/training_sessions/${calEvent.id}`;
    }
    window.open(url, "_blank");
};

const initCalendar = function () {
    let cal;
    import( /* webpackChunkName: "fc" */ 'fullcalendar/dist/fullcalendar').then(() => {
        import( /* webpackChunkName: "fc" */ "fullcalendar/dist/locale/de-ch").then(() => {
            //    import( /* webpackChunkName: "fc" */ 'fullcalendar').then(() => {
            cal = $("#calendar").fullCalendar({
                header: {
                    left: "today prev,next",
                    center: "title",
                    right: "month,agendaWeek,agendaDay"
                },
                locale: "de",
                events: "/home/calendar_events.json",
                defaultView: "month",
                height: "parent",
                eventRender: render,
                eventClick: clickHandler,
                themeSystem: "bootstrap4"
            });
            $(".calendar-filter input").change(function () {
                cal.fullCalendar("rerenderEvents");
            });
        });
    });
};

postInit("home#calendar", initCalendar);