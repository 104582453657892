import {
  postInit
} from "./platin";

var syncRestrictionDates = function () {
  var a;

  function sync(e1, e2) {
    e1.change(event, function () {
      e2.val(e1.val());
    });
  }
  sync($("#environment_restriction_start_time_3i"), $("#environment_restriction_end_time_3i"));
  sync($("#environment_restriction_start_time_2i"), $("#environment_restriction_end_time_2i"));
  sync($("#environment_restriction_start_time_1i"), $("#environment_restriction_end_time_1i"));
};

postInit("environment_restrictions#new", syncRestrictionDates);
postInit("environment_restrictions#edit", syncRestrictionDates);