import {
    postInit
} from "./platin";
import $ from "jquery";

const contactDetailFormHandler = function () {

    function replaceOperationalUnitSelect(data) {
        $(".form-group.organizational-units").replaceWith(data)
    }

    function replaceCDForm(data) {
        $("#contact-details-tab").html(data);
    }

    $("select[name='person[main_contact_detail_id]']").change(function () {
        $.post("update_main_contact_detail_form", {
            contact_detail_id: $("option:selected", this).val()
        }, replaceCDForm, "json");
    });

    $("body").on("change", "#person_main_contact_detail_attributes_organization_id", function () {
        var id = $("option:selected", this).val();
        $.get({
            url: "/people/update_organizational_unit_select?organization_id=" + id,
            success: replaceOperationalUnitSelect,
            dataType: "json"
        })
    });
};

postInit("people#edit", contactDetailFormHandler);
postInit("people#new", contactDetailFormHandler);