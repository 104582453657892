import $ from "jquery";
import {
  postInit
} from "./platin";
import * as d3 from "d3";
import {
  serializeAndSendSVG,
  d3FormatDateWkday,
  d3FormatTimeNoS
} from "d3utils";
import {
  platinClassFilter
} from "./table-controls";

const caseHierarchyValues = {
  Bl: 10,
  Sp: 15,
  St: 20,
  P: 30,
  C: 40,
  CO: 41,
  CW: 41,
  D: 42,
  B: 50,
  "AS": 60,
  A: 61
};
const fontFamily = "Frutiger LT 45 Light, Frutiger LT 45, Helvetica, sans-serif";
const axisLabelFontSize = "12pt";

// ts are objects with keys tnt, name, date, case
function caseHierarchy(ts1, ts2) {
  return caseHierarchyValues[ts1.case] - caseHierarchyValues[ts2.case];
}

function tooltipText(d) {
  let datestring = d3FormatDateWkday(new Date(d.date)),
    timestring = `${d3FormatTimeNoS(new Date(d.start_time))}–${d3FormatTimeNoS(new Date(d.end_time))}`;
  return `${datestring} ${timestring}, ${d.cp}`;
}

const initPlanningOverview = function () {
  const containerSelector = "#plan-container",
    canReadCampaignParts = $(containerSelector).data("readCampaignParts");

  var margin = {
      top: 10,
      right: 30,
      bottom: 80,
      left: 160
    },
    width = $("#plan-container").innerWidth() - margin.left - margin.right,
    height = 550 - margin.top - margin.bottom,
    id = $(containerSelector).data("id"),
    svg, enclosingG;


  d3.json(`/planning_scenarios/${id}/timeshifts_flat.json`, {
    credentials: "same-origin"
  }).then(function (data) {
    if (data.timeshifts.length == 0) {
      return;
    }
    var dateRange = d3.extent(data.timeshifts, function (el) {
      return new Date(el.date);
    });
    dateRange[1].setDate(dateRange[1].getDate() + 1);
    var by_tnt = d3.nest()
      .key(function (d) {
        return d.tnt;
      })
      .entries(data.timeshifts);

    var x = d3.scaleTime()
      .domain(d3.extent(dateRange))
      .range([0, width]);
    var y = d3.scaleBand()
      .domain(data.tnts.map(function (d) {
        return d.tnt_id;
      }))
      .range([0, height])
      .padding([0.1]);

    var xAxis = d3.axisBottom(x)
      .ticks(d3.timeWeek)
      .tickSize(-height)
      .tickFormat(d3.timeFormat("%d.%m"));

    var yAxis = d3.axisLeft(y)
      .tickSize(0)
      .tickFormat(function (v) {
        return data.tnt_names[v] + " ";
      });

    var tooltipContainer = $("<div>");
    tooltipContainer.appendTo($("#plan-container"));

    svg = d3.select("#plan-container")
      .append("svg")
      .attr("class", "d3-chart")
      .attr("font-family", fontFamily)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom);

    enclosingG = svg.append("g")
      .attr("class", "enclosing")
      .attr("font-family", fontFamily)
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    enclosingG.append("g")
      .attr("class", "axis axis--x")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxis)
      .selectAll(".tick line").attr("stroke", "#ccc");

    enclosingG.append("g")
      .attr("class", "axis axis--y")
      .call(yAxis)
      .select(".domain").remove();

    enclosingG.selectAll("g.tick text")
      .attr("font-size", axisLabelFontSize);

    const numDays = d3.timeDay.count(dateRange[0], dateRange[1]);

    var tntGs = enclosingG.selectAll("g.tnt-bar")
      .data(by_tnt)
      .enter()
      .append("g")
      .attr("class", "tnt-bar");

    tntGs.selectAll("rect")
      .data(function (d) {
        return d.values.sort(caseHierarchy);
      })
      .enter()
      .append("rect")
      .attr("class", d => `timeshift case-${d.case} platin-class-filter filter-tnt-${d.tnt} platin-class-filter filter-status-${d.status}`)
      .attr("x", d => x(new Date(d.date)) + 1)
      .attr("y", d => y(d.tnt))
      .attr("width", width / numDays - 2)
      .attr("height", y.bandwidth())
      .attr("title", tooltipText)
      .on("click", showCampaignPart);

    $("g.tnt-bar rect").tooltip({
      container: tooltipContainer,
      animation: false
    });

    y.domain().forEach(function (yValue) {
      enclosingG.append("line")
        .attr("x1", 0)
        .attr("x2", width)
        .attr("y1", y(yValue) - 1)
        .attr("y2", y(yValue) - 1)
        .attr("stroke", "#ccc");
    });

    let legend = svg.append("g")
      .attr("class", "legend")
      .attr("transform", "translate(0, " + (height + 50) + ")");

    let legendData = [{
        text: "Fall A",
        case: "A"
      },
      {
        text: "Fall AS",
        case: "AS"
      },
      {
        text: "Fall B",
        case: "B"
      },
      {
        text: "Fall C",
        case: "C"
      },
      {
        text: "Fall CO",
        case: "CO"
      },
      {
        text: "Fall CW",
        case: "CW"
      },
      {
        text: "Fall D",
        case: "D"
      },
      {
        text: "Fall P",
        case: "P"
      },
      {
        text: "statisch",
        case: "St"
      },
      {
        text: "Info",
        case: "I"
      },
      {
        text: "gesperrt",
        case: "Sp"
      }
    ];

    legend.append("g")
      .attr("transform", `translate(${margin.left - 10},0)`)
      .attr("font-family", fontFamily)
      .append("text")
      .attr("dy", "1.1em")
      .attr("text-anchor", "end")
      .text("Legende:");

    const legendEntryWidth = 100,
      legendEntryHeight = 24;
    let legendEntries = legend.selectAll("g.legendEntry")
      .data(legendData)
      .enter()
      .append("g")
      .attr("transform", function (d, i) {
        return `translate(${margin.left + i * legendEntryWidth},0)`;
      })
      .attr("class", function (d) {
        return `legendEntry case-${d.case}`;
      });
    legendEntries.append("rect")
      .attr("width", legendEntryWidth)
      .attr("height", legendEntryHeight);
    legendEntries.append("text")
      .attr("dy", "1.1em")
      .attr("x", legendEntryWidth / 2)
      .attr("text-anchor", "middle")
      .text(function (d) {
        return d.text;
      });

    $("#plan-container svg")[0].prepareSVGForDownload = prepareSVGForDownload;

    //table_filter($(".table-controls"));
    platinClassFilter();
  });

  $("#download-button").click(function (event) {
    let svg = prepareSVGForDownload();
    event.preventDefault();
    serializeAndSendSVG(svg, $(this).data("svg-filename") || "Tunnelbelegung.svg");
  });

  /* 2018-06-26 / Matthias Kummer
  create a new svg element with inline styles which can be serialized
  and downloaded as a file 
  */
  const prepareSVGForDownload = function () {
    let clonedSvg = document.querySelector("#plan-container svg").cloneNode(true),
      body = document.getElementsByTagName("BODY")[0],
      rects, axes, legendTexts;

    body.appendChild(clonedSvg);

    // set inline fill styles for all relevant elements, as many SVG editors cannot deal with CSS stylesheets
    // convert rgba to rgb/opacity, as some SVG editors cannot deal with rgba…
    rects = clonedSvg.querySelectorAll("rect.timeshift, g.legend rect");
    for (let i = 0; i < rects.length; ++i) {
      let fill = window.getComputedStyle(rects[i]).fill;
      rects[i].setAttribute("fill", fill);
    }
    legendTexts = clonedSvg.querySelectorAll("g.legend text");
    for (let i = 0; i < legendTexts.length; ++i) {
      let fill = window.getComputedStyle(legendTexts[i]).fill;
      legendTexts[i].setAttribute("fill", fill);
      legendTexts[i].setAttribute("font-size", "16pt");
      legendTexts[i].setAttribute("dy", "0.9em");
    }
    axes = clonedSvg.querySelectorAll("g.axis");
    for (let i = 0; i < axes.length; ++i) {
      axes[i].removeAttribute("font-family");
    }

    d3.select(clonedSvg).selectAll("g.axis--y .tick text")
      .attr("font-size", "16pt");

    body.removeChild(clonedSvg);
    return clonedSvg;
  };

  const showCampaignPart = function () {
    let datum = d3.select(this).datum(),
      url;
    if (!canReadCampaignParts) {
      return false;
    }
    if (!datum.access) {
      return false;
    }
    if (typeof (datum.mission_id) === "undefined") {
      url = `/campaign_parts/${datum.cp_id}`;
      window.open(url, "_blank");
    } else {
      url = `/missions/${datum.mission_id}`;
      window.open(url, "_blank");
    }
  };


};

postInit("planning_scenarios#show", initPlanningOverview);
postInit("planning_scenarios#current", initPlanningOverview);