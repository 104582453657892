/* global $ */

import {
  postInit
} from "./platin";
import {
  setParticipantsDraggable
} from "./meetings";

const installEditMeetingSeriesParticipantsHandler = function () {
  const editModal = $("#edit-meeting-series-participant.modal"),
    selectPersonForm = $("form", editModal),
    modalSubmitButton = $("input.btn[type='submit']", selectPersonForm),
    continueField = $("<input type='hidden' name='continue' value='true'></input>");

  $("body").on("click", "[data-toggle='edit-participants']", function (event) {
    event.preventDefault();
    $(".edit-participants").toggleClass("hidden");
    if ($(this).hasClass("fa-lock")) {
      $(this).removeClass("fa-lock").addClass("fa-unlock");
    } else {
      $(this).removeClass("fa-unlock").addClass("fa-lock");
    }
    setParticipantsDraggable();
  });

  // on click on edit button, populate modal with participant data
  $("body").on("click", "[data-target='#edit-meeting-series-participant']", function (event) {
    var editLink = $(event.currentTarget);
    if (editLink.data("person-id")) {
      $("h5.modal-title", editModal).html("Teilnehmer " + editLink.data("person-name") + " bearbeiten");
      $("#person_name", editModal).hide().prop("required", false);
      $("#person_id", editModal).val(editLink.data("person-id"));
      $("#meeting_series_participant_id", editModal).val(editLink.data("meeting-series-participant-id"));
      $("#category_" + editLink.data("category"), editModal).prop("checked", "checked");
      selectPersonForm.valid();
    } else {
      resetEditMeetingSeriesParticipantForm();
    }
  });

  $("body").on("keydown keyup", "#edit-meeting-series-participant.modal", function (event) {
    if (event.keyCode == 18) {
      if (event.type == "keydown") {
        modalSubmitButton.val("Speichern, nächste Person erfassen…");
        selectPersonForm.append(continueField);
      } else {
        modalSubmitButton.val("Speichern");
        continueField.remove();
      }
    }
  });
};

const installMeetingSeriesParticipantDragDropHandler = function () {
  $("body").on("dragstart", "[data-drag='participant-link']", function (event) {
    event.originalEvent.dataTransfer.setData("text", $(event.target).data("meeting-series-participant-id").toString());
  });

  $("body").on("dragenter", "[data-ondrop='meeting-series-participant']", function (event) {
    event.preventDefault();
    $(event.target).addClass("dragover");
  }).on("dragover", "[data-ondrop='meeting-series-participant']", function (event) {
    event.preventDefault();
  }).on("dragleave", "[data-ondrop='meeting-series-participant']", function (event) {
    event.preventDefault();
    $(event.target).removeClass("dragover");
  }).on("drop", "[data-ondrop='meeting-series-participant']", function (event) {
    event.preventDefault();
    var meetingSeriesParticipantId = event.originalEvent.dataTransfer.getData("text"),
      meetingSeriesID = $(this).data("meeting-series-id"),
      attribute = $(event.target).data("attribute"),
      value = $(event.target).data("value"),
      data = {};
    data[attribute] = value;
    $.post("/meeting_series/__meetingSeriesID__/update_participant?meeting_series_participant_id=__ID__".replace("__meetingSeriesID__", meetingSeriesID).replace("__ID__", meetingSeriesParticipantId), data, "script");
  });
};

const resetEditMeetingSeriesParticipantForm = function () {
  const editModal = $("#edit-meeting-series-participant.modal");
  $("h5.modal-title", editModal).html("Teilnehmer hinzufügen");
  $("#meeting_series_participant_id", editModal).val(null);
  $("input#category_to", editModal).prop("checked", "checked");
  $("#person_name", editModal).typeahead("val", "").show().prop("required", true);
  $("#person_id", editModal).val("");
};

const editMeetingSeriesParticipantsFeature = function () {
  const editModal = $("#edit-meeting-series-participant.modal");

  $(editModal).on("shown.bs.modal", function () {
    if ($("#person_name").is(":visible")) {
      $("#person_name").focus();
    }
  });

  setParticipantsDraggable();
  installMeetingSeriesParticipantDragDropHandler();
  installEditMeetingSeriesParticipantsHandler();
};

postInit("meeting_series#show", editMeetingSeriesParticipantsFeature);

export {
  resetEditMeetingSeriesParticipantForm
};