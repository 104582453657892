/* 2017-12-21 / Matthias Kummer
   provides basic AJAX-based autocomplete functionality based on
   Twitter typeahead and Bloodhound.

   Simply add a data-automplete-url attribute to a text input
   the url must deliver a JSON array of string values used as suggestion
   based on a "query" attribute
*/

import {
    initializers
} from "./platin";
import Bloodhound from "corejs-typeahead/dist/bloodhound";
import $ from "jquery";

const activateAutocomplete = function (index, input) {
    var url = $(input).data("autocomplete-url");
    $(input).attr("autocomplete", "off");
    if ($(input).parent().hasClass("twitter-typeahead")) {
        $(input).typeahead("destroy");
    }
    var bloodhound = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace,
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
            url: url + "?query=%QUERY",
            wildcard: "%QUERY"
        }
    });
    $(input).typeahead({
        hint: false,
        highlight: true,
        minLength: 2
    }, {
        source: bloodhound,
        limit: 20,
    });
};

const initUrlAutocomplete = function () {
    var autocompleteInputs = $("[data-autocomplete-url]");
    autocompleteInputs.each(activateAutocomplete);
};

export {
    initUrlAutocomplete,
    activateAutocomplete
};

initializers.push(initUrlAutocomplete);