import {
  initializers
} from "./platin";
import {
  hideTooltips
} from "./utils";
import $ from "jquery";

function handleRolesSelect() {
  $(".roles label.btn").click(function () {
    hideTooltips();
    $("input[type=checkbox]", $(this)).attr("checked", $(this).hasClass("active") ? null : "checked");
  });
  $(".roles input[type=checkbox]").each(function (index, checkbox) {
    $(checkbox).attr("checked", $(checkbox).closest("label.btn").hasClass("active") ? "checked" : null);
  });
}

initializers.push(handleRolesSelect);