/* global $ */

import {
  postInit
} from "./platin";

const installEditMeetingParticipantsHandler = function () {
  const selectPersonModal = $("#select-person.modal"),
    selectPersonForm = $("form", selectPersonModal),
    modalSubmitButton = $("input.btn[type='submit']", selectPersonForm),
    continueField = $("<input type='hidden' name='continue' value='true'></input>");

  $("body").on("click", "[data-toggle='edit-participants']", function (event) {
    event.preventDefault();
    $(".edit-participants").toggleClass("hidden");
    $(".edit-participants-static").toggleClass("disabled");
    if ($(this).hasClass("fa-lock")) {
      $(this).removeClass("fa-lock").addClass("fa-unlock");
    } else {
      $(this).removeClass("fa-unlock").addClass("fa-lock");
    }
    $("[data-toggle='tooltip']").tooltip("hide");
    setParticipantsDraggable();
  });

  // on click on edit button, populate modal with participant data
  $("body").on("click", "[data-target='#select-person']", function (event) {
    var editLink = $(event.currentTarget);
    if (editLink.data("person-id")) {
      $("h5.modal-title", selectPersonModal).html("Teilnehmer " + editLink.data("person-name") + " bearbeiten");
      $("#person_name", selectPersonModal).hide().prop("required", false);
      $("#person_id", selectPersonModal).val(editLink.data("person-id"));
      $("#meeting_participant_id", selectPersonModal).val(editLink.data("meeting-participant-id"));
      $("#schedule_" + editLink.data("schedule"), selectPersonModal).prop("checked", "checked");
      $("#confirmation_" + editLink.data("confirmation"), selectPersonModal).prop("checked", "checked");
      $("#meeting_presence_" + editLink.data("meeting_presence"), selectPersonModal).prop("checked", "checked");
      $("#all_future_meetings", selectPersonModal).closest("tr").hide();
      selectPersonForm.valid();
    } else {
      resetSelectPersonForm();
      $("#all_future_meetings", selectPersonModal).closest("tr").show();
    }
  });

  $("body").on("keydown keyup", "#select-person.modal", function (event) {
    if (event.keyCode == 18) {
      if (event.type == "keydown") {
        modalSubmitButton.val("Speichern, nächste Person erfassen…");
        selectPersonForm.append(continueField);
      } else {
        modalSubmitButton.val("Speichern");
        continueField.remove();
      }
    }
  });
};

const installMeetingParticipantDragDropHandler = function () {
  $("body").on("dragstart", "[data-drag='participant-link']", function (event) {
    event.originalEvent.dataTransfer.setData("text", $(event.target).data("meeting-participant-id").toString());
  });

  $("body").on("dragenter", "[data-ondrop='meeting-participant']", function (event) {
    event.preventDefault();
    $(event.target).addClass("dragover");
  }).on("dragover", "[data-ondrop='meeting-participant']", function (event) {
    event.preventDefault();
  }).on("dragleave", "[data-ondrop='meeting-participant']", function (event) {
    event.preventDefault();
    $(event.target).removeClass("dragover");
  }).on("drop", "[data-ondrop='meeting-participant']", function (event) {
    event.preventDefault();
    var meetingParticipantId = event.originalEvent.dataTransfer.getData("text"),
      meetingID = $(this).data("meetingid"),
      attribute = $(event.target).data("attribute"),
      value = $(event.target).data("value"),
      data = {};
    data[attribute] = value;
    data["no_dialog"] = true;
    $.post("/meetings/__meetingID__/update_participant?meeting_participant_id=__ID__".replace("__meetingID__", meetingID).replace("__ID__", meetingParticipantId), data, "script");
  });
};

const editParticipants = function () {
  return ($("[data-toggle='edit-participants']").hasClass("fa-unlock"));
};

const resetSelectPersonForm = function () {
  const selectPersonModal = $("#select-person.modal");
  $("h5.modal-title", selectPersonModal).html("Teilnehmer hinzufügen");
  $("#meeting_participant_id", selectPersonModal).val(null);
  $("input#schedule_schedule_to", selectPersonModal).prop("checked", "checked");
  $("input#confirmation_confirmation_none", selectPersonModal).prop("checked", "checked");
  $("input#meeting_presence_none", selectPersonModal).prop("checked", "checked");
  $("#person_name", selectPersonModal).typeahead("val", "").show().prop("required", true);
  $("#person_id", selectPersonModal).val("");
};

const setParticipantsDraggable = function () {
  if (editParticipants()) {
    $("[data-drag='participant-link']").attr("draggable", true);
  } else {
    $("[data-drag='participant-link']").attr("draggable", false);
  }
};

const editParticipantsFeature = function () {
  $("#select-person").on("shown.bs.modal", function () {
    if ($("#person_name").is(":visible")) {
      $("#person_name").focus();
    }
  });

  setParticipantsDraggable();
  installMeetingParticipantDragDropHandler();
  installEditMeetingParticipantsHandler();
};


var syncMeetingDates = function () {
  function sync(e1, e2) {
    e1.change(event, function () {
      e2.val(e1.val());
    });
  }
  sync($("#meeting_start_time_3i"), $("#meeting_end_time_3i"));
  sync($("#meeting_start_time_2i"), $("#meeting_end_time_2i"));
  sync($("#meeting_start_time_1i"), $("#meeting_end_time_1i"));
};

postInit("meetings#show", editParticipantsFeature);
postInit("meetings#new", syncMeetingDates);
postInit("meetings#edit", syncMeetingDates);

export {
  setParticipantsDraggable,
  resetSelectPersonForm,
  editParticipants
};