import $ from "jquery";
import { postInit } from "./platin";
import * as d3 from "d3";
import {
  serializeAndSendSVG,
  d3FormatWkdayTimestampNoS,
  d3FormatTimeNoS,
  d3FormatDateWkday,
} from "d3utils";

const fontFamily =
  "Frutiger LT 45 Light, Frutiger LT 45, Helvetica, sans-serif";
const axisLabelFontSize = "10pt";

function tooltipText(d) {
  let startString = d3FormatWkdayTimestampNoS(new Date(d.start_time)),
    endString = d3FormatTimeNoS(new Date(d.end_time));
  return `${startString}–${endString}, Anzahl: ${d.number}<br>${d.m_name}`;
}

const getName = function (obj) {
  return obj.name;
};

const initDailyCategoryChart = function () {
  const totalHeight = window.innerHeight - 200,
    containerSelector = "#daily-category-chart",
    container = $(containerSelector),
    perimeter = container.data("perimeter"),
    startDate = container.data("start-date"),
    endDate = container.data("end-date");

  const margin = {
    top: 10,
    right: 30,
    bottom: 80,
    left: 180,
  };
  let width = $(containerSelector).innerWidth() - margin.left - margin.right,
    height = totalHeight - margin.top - margin.bottom,
    svg,
    enclosingG,
    data,
    categories,
    trainNrGroups,
    x,
    y,
    xAxis,
    yAxis,
    x0domain,
    y0domain;

  var brush = d3.brushX().on("end", brushended),
    idleTimeout,
    idleDelay = 0;

  function titleText(d) {
    let header = `${d3FormatDateWkday(d.data.day)}<br/><br/>Total: ${
        d.data.total
      }<br><br>`,
      counts = categories
        .map((c) => `${trainNrGroups[c]}: ${d.data[c]}`)
        .join("<br>");
    return header + counts;
  }

  function brushended() {
    var s = d3.event.selection;
    if (!s) {
      if (!idleTimeout) return (idleTimeout = setTimeout(idled, idleDelay));
      x.domain(x0domain);
      y.domain(y0domain);
    } else {
      let i1 = Math.round((s[0] - x.range()[0]) / x.step()),
        i2 = Math.round((s[1] - x.range()[0]) / x.step()),
        dom = x0domain.slice(i1, i2);
      x.domain(dom);
      svg.select(".brush").call(brush.move, null);
    }
    zoom();
  }

  function idled() {
    idleTimeout = null;
  }
  function zoom() {
    var t = svg.transition().duration(750);
    svg.select(".axis--x").transition(t).call(xAxis);
    svg.select(".axis--y").transition(t).call(yAxis);
    svg
      .selectAll("g.count-bar rect")
      .transition(t)
      .attr("x", (d) => x(d.data.day))
      .attr("y", (d) => y(d[1]))
      .attr("height", (d) => y(d[0]) - y(d[1]))
      .attr("width", x.bandwidth());
  }

  import(/* webpackChunkName: "d3" */ "d3").then((d3) => {
    d3.json(
      `/trains/daily_category_chart.json?perimeter=${perimeter}&start_date=${startDate}&end_date=${endDate}`,
      {
        credentials: "same-origin",
      }
    ).then(function (response) {
      data = response.counts;
      if (data.length == 0) {
        return;
      }
      trainNrGroups = response.groups;
      categories = Object.keys(trainNrGroups);
      data.forEach((d) => {
        d.day = new Date(d.day);
        /* add 0 for missing categories; stack can't handle missing values */
        categories.forEach((c) => (d[c] = d[c] || 0));
        d.total = categories.map((c) => d[c]).reduce((t, e) => t + e, 0);
      });
      data = data.sort((a, b) => a.day - b.day);

      x = d3.scaleBand().padding(0).align(0).range([0, width]);
      y = d3.scaleLinear().rangeRound([height, 0]);

      var stack = d3.stack();

      x0domain = data.map(function (d) {
        return d.day;
      });
      x.domain(x0domain);
      y0domain = [
        0,
        d3.max(data, function (d) {
          return d.total;
        }),
      ];
      y.domain(y0domain).nice();

      var modFactor = Math.round(x.domain().length / 20);
      xAxis = d3
        .axisBottom(x)
        .tickValues(
          x.domain().filter(function (d, i) {
            return !(i % modFactor);
          })
        )
        .tickFormat(d3.timeFormat("%d.%m.%Y"));

      yAxis = d3.axisLeft(y).tickSize(0);

      svg = d3
        .select(containerSelector)
        .append("svg")
        .attr("class", "d3-chart")
        .attr("font-family", fontFamily)
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom);

      enclosingG = svg
        .append("g")
        .attr("class", "enclosing")
        .attr("font-family", fontFamily)
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      enclosingG
        .append("g")
        .attr("class", "axis axis--x")
        .attr("transform", "translate(0," + height + ")")
        .call(xAxis);

      enclosingG
        .append("g")
        .attr("class", "axis axis--y")
        .call(d3.axisLeft(y).ticks(10, "s"))
        .append("text")
        .attr("x", 2)
        .attr("y", y(y.ticks(10).pop()))
        .attr("dy", "0.35em")
        .attr("text-anchor", "start")
        .attr("fill", "#000")
        .text("Züge");

      var rsGs = enclosingG
        .selectAll("g.count-bar")
        .data(stack.keys(categories)(data))
        .enter()
        .append("g")
        .attr("class", (d) => `count-bar ${d.key}`)
        .selectAll("rect")
        .data((d) => d)
        .enter()
        .append("rect")
        .attr("title", titleText)
        .attr("data-toggle", "tooltip")
        .attr("x", (d) => x(d.data.day))
        .attr("y", (d) => y(d[1]))
        .attr("height", (d) => y(d[0]) - y(d[1]))
        .attr("width", x.bandwidth());

      svg.append("g").attr("class", "brush").call(brush);

      $("svg", container)[0].prepareSVGForDownload = prepareSVGForDownload;
    });
  });

  $("#download-button").click(function (event) {
    let svg = prepareSVGForDownload();
    event.preventDefault();
    serializeAndSendSVG(
      svg,
      $(this).data("svg-filename") || "Tunnelbelegung.svg"
    );
  });

  /* 2018-06-26 / Matthias Kummer
    create a new svg element with inline styles which can be serialized
    and downloaded as a file 
    */
  const prepareSVGForDownload = function () {
    let clonedSvg = document
        .querySelector(`${containerSelector} svg`)
        .cloneNode(true),
      body = document.getElementsByTagName("BODY")[0],
      rects,
      axes,
      legendTexts;

    body.appendChild(clonedSvg);

    // set inline fill styles for all relevant elements, as many SVG editors cannot deal with CSS stylesheets
    // convert rgba to rgb/opacity, as some SVG editors cannot deal with rgba…
    rects = clonedSvg.querySelectorAll("rect.timeshift, g.legend rect");
    for (let i = 0; i < rects.length; ++i) {
      let fill = window.getComputedStyle(rects[i]).fill;
      rects[i].setAttribute("fill", fill);
    }
    legendTexts = clonedSvg.querySelectorAll("g.legend text");
    for (let i = 0; i < legendTexts.length; ++i) {
      let fill = window.getComputedStyle(legendTexts[i]).fill;
      legendTexts[i].setAttribute("fill", fill);
      legendTexts[i].setAttribute("font-size", "16pt");
      legendTexts[i].setAttribute("dy", "0.9em");
    }
    axes = clonedSvg.querySelectorAll("g.axis");
    for (let i = 0; i < axes.length; ++i) {
      axes[i].removeAttribute("font-family");
    }

    body.removeChild(clonedSvg);
    return clonedSvg;
  };

  const showMission = function () {
    if (!canReadMissions) {
      return false;
    }
    var url = `/missions/${d3.select(this).datum().m_id}`;
    window.open(url, "_blank");
  };
};

postInit("trains#daily_category_chart", initDailyCategoryChart);
