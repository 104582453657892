import $ from "jquery";
import * as d3 from "d3";
import { postInit } from "platin";
import moment from "moment";

const tunnelOccupationCompact = function(){
    const margin = {top: 10, right: 10, bottom: 30, left: 90},
        width = $("#plan-container").innerWidth() - margin.left - margin.right,
        height = 360 - margin.top - margin.bottom,
        id = $("#plan-container").data("id");

    var startDate, endDate;

    d3.json(`/planning_scenarios/${id}/timeshifts_with_times.json`,{credentials: "same-origin"}).then(function(data){
        function extractDate(date){
            return (new Date(date)).setHours(0,0,0,0);
        }
        function extractTime(d){
            var date = new Date(d);
            return date.getHours() + date.getMinutes()/60;
        }
        
        startDate = moment(data.start_time).startOf("day");
        endDate = moment(data.end_time).endOf("day");

        var x = d3.scaleTime()
            .domain(d3.extent([startDate, endDate]))
            .range([0, width]);
        var y = d3.scaleLinear()
            .domain([0, 24])
            .range([0, height]);

        var xAxis = d3.axisBottom(x)
            .ticks(d3.timeWeek)
            .tickSize(-height)
            .tickFormat(d3.timeFormat("%d. %b"));

        var yAxis = d3.axisLeft(y);

        var svg = d3.select("#plan-container")
            .append("svg")
            .attr("class", "d3-chart")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        svg.append("g")
            .attr("class", "axis axis--x")
            .attr("transform", "translate(0," + height + ")")
            .call(xAxis)
            .selectAll(".tick line").attr("stroke", "#aaa");

        svg.append("g")
            .attr("class", "axis axis--y")
            .call(yAxis)
            .select(".domain").remove();

        // y axis label
        svg.append("text")
            .attr("text-anchor", "middle")
            .attr("class", "axis axis--y title")
            .attr("font-size", 12)
            .attr("transform", "translate("+ -30 +","+(height/2)+")rotate(-90)")
            .text("Uhrzeit [h]");

        const numDays = d3.timeDay.count(startDate, endDate);

        var shiftGs = svg.selectAll("g.timeshift")
            .data(data.timeshifts)
            .enter()
            .append("g")
            .attr("class", function(d){return "timeshift case-"+d.case;})
            .attr("data-id", function(d){return d.id;});

        shiftGs.selectAll("rect")
            .data(function(d){return d.segments;})
            .enter()
            .append("rect")
            .attr("x", function(d){return x(extractDate(d.start_time));})
            .attr("y", function(d){return y(extractTime(d.start_time));})
            .attr("width", width/numDays)
            .attr("height", function(d){return y(extractTime(d.end_time))-y(extractTime(d.start_time));});
    });
};

postInit("planning_scenarios#tunnel_occupation_compact", tunnelOccupationCompact);