const $ = require("jquery");
const d3 = require("d3");
import Cookies from "js-cookie/src/js.cookie";
import {
  postInit
} from "platin";
import {
  busy,
  hideBusy,
  hideTooltips,
  flashMessage
} from "utils";
import moment from "moment";
import {
  serializeAndSendSVG,
  d3FormatWkdayTimestampNoS,
  d3FormatTimeNoS
} from "d3utils";
import {
  table_filter,
  tableFilterOnlyHide,
  platinClassFilter
} from "./table-controls";
import consumer from "./channels/consumer";

const initTunnelOccupation = function () {
  const containerSelector = "#tunnel-occupation-container",
    margin = {
      top: 16,
      right: 0,
      bottom: 30,
      left: 10
    },
    dateColWidth = 70,
    tunnelSeparatorWidth = 10,
    height = 2000 - margin.top - margin.bottom,
    ck = Cookies.get("occupation-plan-labels"),
    dateFormat = d3.timeFormat("%a %d.%m.%Y"),
    oneDayInMs = 24 * 3600000,
    fontFamily = "Frutiger LT 45 Light, Frutiger LT 45, Helvetica, sans-serif";

  let width, tunnelWidth, eastTunnelLeft, startDate, endDate,
    dayHeight,
    baseSvg, svg,
    xWest, xEast,
    y, yinv,
    pt,
    datePointer;

  const prepareSvg = function () {
    let someDay = new Date(2020, 2, 1);
    baseSvg = d3.select(containerSelector)
      .append("svg")
      .attr("class", "d3-chart")
      .attr("font-family", fontFamily)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom);
    svg = baseSvg
      .append("g")
      .attr("class", "enclosing")
      .attr("font-family", fontFamily)
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")"),
      xWest = d3.scaleLinear()
      .domain([0, 24])
      .range([dateColWidth, dateColWidth + tunnelWidth]),
      xEast = d3.scaleLinear()
      .domain([0, 24])
      .range([eastTunnelLeft, eastTunnelLeft + tunnelWidth]),
      y = d3.scaleLinear()
      .domain(d3.extent([startDate, endDate]))
      .range([0, height]);
    yinv = y.invert;
    dayHeight = y(new Date(someDay.getTime() + oneDayInMs)) - y(someDay);
    pt = baseSvg.node().createSVGPoint();
    $(baseSvg.node()).mousemove(moveDatePointer);
    datePointer = baseSvg.append("polygon")
      .attr("id", "datePointer")
      .attr("points", "0,0 6,3 0,6 0,0")
      .attr("transform", "translate(0, " + margin.top + dayHeight / 2 + ")")
      .attr("display", "none")
      .attr("fill", "blue");
    $(containerSelector + " svg")[0].prepareSVGForDownload = prepareSVGForDownload;
  };

  // specialDatesMs is an array of arrays [startTimeInMilliseconds, endTimeInMilliseconds, "title"]
  const drawGrid = function (specialDatesMs) {
    let specialDates = specialDatesMs.map(function (ds) {
      return {
        start: new Date(ds.start),
        end: new Date(ds.end),
        text: ds.text
      };
    });
    svg.selectAll("line.gridX")
      .data(d3.timeDays(startDate, endDate))
      .enter()
      .append("line")
      .attr("class", "gridX")
      .attr("x1", 0)
      .attr("x2", eastTunnelLeft + tunnelWidth)
      .attr("y1", function (d) {
        return y(d);
      })
      .attr("y2", function (d) {
        return y(d);
      })
      .attr("stroke", "grey")
      .attr("stroke-width", "0.5");
    svg.append("rect")
      .attr("class", "borderBox")
      .attr("x", 0.5)
      .attr("width", width - 1)
      .attr("y", y.range()[0] + 0.5)
      .attr("height", height - 1)
      .attr("stroke", "grey")
      .attr("stroke-width", "1")
      .attr("fill", "none");
    svg.append("line")
      .attr("x1", dateColWidth - 0.5)
      .attr("x2", dateColWidth - 0.5)
      .attr("y1", y.range()[0])
      .attr("y2", y.range()[1])
      .attr("stroke", "grey")
      .attr("stroke-width", "1");
    svg.append("rect")
      .attr("class", "tunnelSeparator")
      .attr("x", eastTunnelLeft - tunnelSeparatorWidth)
      .attr("width", tunnelSeparatorWidth)
      .attr("y", y.range()[0] + 0.5)
      .attr("height", height - 1)
      .attr("stroke", "grey")
      .attr("stroke-width", "0.5")
      .attr("fill", "grey");

    // mark all saturdays and sundays
    svg.selectAll("rect.dateWeekend")
      .data(d3.timeDays(startDate, endDate).filter(function (d) {
        let wkday = d.getDay();
        return wkday == 0 || wkday == 6;
      }))
      .enter()
      .append("rect")
      .attr("class", "dateWeekend")
      .attr("x", 1)
      .attr("y", function (d) {
        return y(d) + 0.5;
      })
      .attr("width", dateColWidth - 2)
      .attr("height", dayHeight - 1);

    // mark all special days
    svg.selectAll("rect.dateInfo")
      .data(specialDates)
      .enter()
      .append("rect")
      .attr("class", "dateInfo")
      .attr("x", 1)
      .attr("y", function (d) {
        return y(d.start) + 0.5;
      })
      .attr("width", dateColWidth - 2)
      .attr("height", function (d) {
        return y(d.end) - y(d.start) - 1;
      });

    svg.selectAll("text.dateLabel")
      .data(d3.timeDays(startDate, endDate))
      .enter()
      .append("text")
      .attr("class", "dateLabel")
      .attr("x", 2)
      .attr("y", function (d) {
        return y(d) + dayHeight / 2;
      })
      .attr("font-size", 8)
      .attr("dy", "0.3em")
      .text(function (d) {
        return dateFormat(d);
      });

    // tooltips for dateInfo rects (above labels for improved user experience)
    svg.selectAll("rect.dateInfoTooltipTarget")
      .data(specialDates)
      .enter()
      .append("rect")
      .attr("class", "dateInfoTooltipTarget")
      .attr("x", 1)
      .attr("y", function (d) {
        return y(d.start) + 0.5;
      })
      .attr("width", dateColWidth - 2)
      .attr("height", function (d) {
        return y(d.end) - y(d.start) - 1;
      })
      .attr("title", function (d) {
        return d.text;
      })
      .attr("fill", "rgba(255,255,255,0)");

    $("rect.dateInfoTooltipTarget").tooltip({
      container: "body",
      placement: "left"
    });

    svg.append("text")
      .attr("x", margin.left + dateColWidth + tunnelWidth / 2)
      .attr("y", 0)
      .attr("dy", "-0.35em")
      .text("West-Tunnel (7000)")
      .attr("font-size", 12)
      .attr("text-anchor", "middle");
    svg.append("text")
      .attr("x", eastTunnelLeft + tunnelWidth / 2)
      .attr("y", 0)
      .attr("dy", "-0.35em")
      .text("Ost-Tunnel (6000)")
      .attr("font-size", 12)
      .attr("text-anchor", "middle");
  };

  const extractDate = function (date) {
    return (new Date(date)).setHours(0, 0, 0, 0);
  };

  const extractTime = function (d) {
    var date = new Date(d);
    return date.getHours() + date.getMinutes() / 60;
  };

  const highlightG = function () {
    var missionId = this.attributes.getNamedItem("data-mission-id");
    if (missionId) {
      $("g[data-mission-id='" + missionId.value + "']").addClass("highlight");
    } else {
      $(this).addClass("highlight");
    }
  };

  const unHighlightG = function () {
    var missionId = this.attributes.getNamedItem("data-mission-id");
    if (missionId) {
      $("g[data-mission-id='" + missionId.value + "']").removeClass("highlight");
    } else {
      $(this).removeClass("highlight");
    }
  };

  const showCampaignPart = function () {
    var url,
      datum = d3.select(this).datum(),
      cpid = datum.campaign_part_id,
      mid = datum.mission_id;
    // debugger;
    if (!datum.access) {
      return false;
    }
    if (mid) {
      url = "/missions/__ID__".replace("__ID__", mid);
    } else {
      url = "/campaign_parts/__ID__".replace("__ID__", cpid);
    }
    window.open(url, "_blank");
  };

  function changeClass(timeshift) {
    let c = "";
    if ($.isEmptyObject(timeshift.changes)) {
      c = " unchanged";
    }
    if (timeshift.changes.start_time) {
      c += " changed-time";
    }
    return c;
  }

  function timeshiftTooltip(d) {
    let title = d3FormatWkdayTimestampNoS(d.ts_st) + "-" + d3FormatTimeNoS(d.ts_et) + "<br>TNT " + d.tnt_name + ", " + d.eid + "<br>" + d.name;
    if (d.old_st) {
      title += "<br>alte Startzeit: " + d3FormatWkdayTimestampNoS(new Date(d.old_st)) + "-" + d3FormatTimeNoS(new Date(d.old_st));
    }
    return title;
  }

  // eastWest == 'east' / 'west'
  const drawTunnel = function (data, svg, eastWest) {
    const numDays = d3.timeDay.count(startDate, endDate);
    var x;

    if (eastWest == "east") {
      x = xEast;
    } else {
      x = xWest;
    }

    var shiftGs = svg.selectAll("g.timeshift." + eastWest)
      .data(data)
      .enter()
      .append("g")
      .attr("font-size", 8)
      .attr("data-id", d => d.id)
      .attr("data-mission-id", d => d.mission_id)
      .attr("class", d => `timeshift ${eastWest} case-${d.case} platin-class-filter filter-tnt-${d.tnt_id} ${changeClass(d)} ${d.fixed ? "fixed" : ""} platin-class-filter filter-status-${d.status}`)
      .on("mouseover", highlightG)
      .on("mouseout", unHighlightG)
      .on("click", showCampaignPart);

    var segmentGs = shiftGs.selectAll("g.segment")
      .data(d => d.segments)
      .enter()
      .append("g")
      .attr("class", "segment")
      .attr("title", timeshiftTooltip);

    $(baseSvg.node()).tooltip({
      selector: "g.segment",
      animation: false,
      placement: "right",
      container: "header",
      html: true
    });

    segmentGs
      .append("rect")
      .attr("class", "segment")
      .attr("x", d => x(extractTime(d.start_time)))
      .attr("y", d => y(extractDate(d.start_time)))
      .attr("width", d => x(extractTime(d.end_time)) - x(extractTime(d.start_time)))
      .attr("height", height / numDays);

    if ($("#show_fixed-lock").length > 0) {
      segmentGs.filter((d) => d.fixed)
        .append("text")
        .classed("fixed-lock", true)
        .attr("x", d => x(extractTime(d.end_time)) - 8)
        .attr("y", d => y(extractDate(d.end_time)) + 8)
        .attr("text-anchor", "right")
        .style("font-family", "FontAwesome")
        .style("font-size", 8)
        .text("\uf023");
    }

    segmentGs
      .append("text")
      .classed("name", true)
      .attr("x", d => (x(extractTime(d.end_time)) + x(extractTime(d.start_time))) / 2)
      .attr("y", d => y(extractDate(d.start_time)) + height / numDays / 2)
      .attr("text-anchor", "middle")
      .attr("dy", "0.35em")
      .style("opacity", 0)
      .text(d => d.name_trunc);

    segmentGs
      .append("text")
      .classed("eid", true)
      .attr("x", d => (x(extractTime(d.end_time)) + x(extractTime(d.start_time))) / 2)
      .attr("y", d => y(extractDate(d.start_time)) + height / numDays / 2)
      .attr("text-anchor", "middle")
      .attr("dy", "0.35em")
      .style("opacity", 0)
      .text(d => d.eid);

    segmentGs
      .append("text")
      .classed("tnt", true)
      .attr("x", d => (x(extractTime(d.end_time)) + x(extractTime(d.start_time))) / 2)
      .attr("y", d => y(extractDate(d.start_time)) + height / numDays / 2)
      .attr("text-anchor", "middle")
      .attr("dy", "0.35em")
      .style("opacity", 0)
      .text(d => d.tnt_name);
  };

  const showLabels = function (link) {
    //var klass = $("select[name='toggle[labels]'] option:selected").val();
    if (link) {
      $(".dropdown.label-selector a").removeClass("active");
      $(link).addClass("active");
    }
    const labelType = $(".dropdown.label-selector a.active").data("label");
    Cookies.set("occupation-plan-labels", labelType);
    if (labelType == "id") {
      d3.selectAll("text.name").transition().style("opacity", 0);
      d3.selectAll("text.tnt").transition().style("opacity", 0);
      d3.selectAll("text.eid").transition().style("opacity", 1);
    } else if (labelType == "name") {
      d3.selectAll("text.name").transition().style("opacity", 1);
      d3.selectAll("text.tnt").transition().style("opacity", 0);
      d3.selectAll("text.eid").transition().style("opacity", 0);
    } else {
      d3.selectAll("text.name").transition().style("opacity", 0);
      d3.selectAll("text.tnt").transition().style("opacity", 1);
      d3.selectAll("text.eid").transition().style("opacity", 0);
    }
  };

  function moveDatePointer(event) {
    let cursorPoint, date;
    pt.x = event.clientX;
    pt.y = event.clientY;
    cursorPoint = pt.matrixTransform(baseSvg.node().getScreenCTM().inverse());
    date = new Date(new Date(yinv(cursorPoint.y + dayHeight / 2)).setHours(0, 0, 0, 0));
    datePointer
      .attr("transform", "translate(0, " + Math.max((y(date) - 3), margin.top) + ")")
      .attr("display", null);
  }

  function calcDimensions() {
    width = Math.round($(containerSelector).innerWidth()) - margin.left - margin.right - 20;
    tunnelWidth = (width - dateColWidth - tunnelSeparatorWidth) / 2,
      eastTunnelLeft = dateColWidth + tunnelWidth + tunnelSeparatorWidth;
  }

  const triggerPlanningRun = function (event) {
    event.preventDefault();
    busy();
    calcDimensions();
    $("#planner-dialog").modal("show");
    subscribeToPlannerChannel();
    d3.json("/planning_scenarios/__ID__/planning_run?format=json".replace("__ID__", $(containerSelector).data("id")), {
      credentials: "same-origin"
    }).then(function (data) {
      //window.test = data;
      $("#planner-dialog").modal("hide");
      hideBusy();
      hideTooltips();
      if (typeof (data.east) == "undefined" || typeof (data.west) == "undefined") {
        hideBusy();
        return;
      }
      d3.select(containerSelector).node().planData = data; // store the received json data for returning to the server for persisting later
      $("#persist-link").show(); // enable 
      if (ck != undefined) {
        $("input[data-class=" + ck + "]").attr("checked", "checked");
      }
      startDate = moment(data.start_time).startOf("day");
      endDate = moment(data.end_time).endOf("day");
      $(containerSelector).html("");
      prepareSvg();
      drawTunnel(data.east.concat(data.both || []), svg, "east");
      drawTunnel(data.west.concat(data.both || []), svg, "west");
      drawGrid(data.specialDatesMs);
      showLabels();
      $("#show-tnt").change();
      /*$("body").off("change", "select[name='toggle[labels]']").on("click", "input[name='toggle[labels]']", function () {
        showLabels();
      });*/
      flashMessage(data.message);
      if (data.failedShiftsCount > 0) {
        showFailedShiftfsDialog(data.failedShiftsDialog);
      }
      table_filter($(".table-controls"));
      platinClassFilter();
    });
  };

  const persistCurrentPlanning = function (event) {
    function tsStartTime(ts) {
      return Math.min(...$.map(ts.segments, function (seg) {
        return seg.start_time;
      }));
    }

    function tsEndTime(ts) {
      return Math.max(...$.map(ts.segments, function (seg) {
        return seg.end_time;
      }));
    }

    function tsData(ts) {
      return {
        cp: ts.campaign_part_id,
        tp: ts.shift_type,
        st: tsStartTime(ts),
        et: tsEndTime(ts),
        sn: ts.shift_nr,
        bl: ts.blocking_for_id
      };
    }

    function afterPersist(response) {
      $("#persist-link").hide();
      hideBusy();
      flashMessage(response.message);
    }

    event.preventDefault();
    let url = "/planning_scenarios/__ID__/persist?format=json".replace("__ID__", $(containerSelector).data("id"));
    let planData = $(containerSelector)[0].planData;
    let payload = {
      east: $.map(planData.east, tsData),
      west: $.map(planData.west, tsData),
      both: [...$.map(planData.both, tsData), ...$.map(planData.none, tsData)]
    };
    busy();
    $.post(url, payload, afterPersist, "json");
  };

  const showFailedShiftfsDialog = function (failedShiftsDialogHTML) {
    let dialog = $(failedShiftsDialogHTML);
    $(dialog).on("hidden.bs.modal", function () {
      this.remove();
    }).modal("show");
  };

  function selectTnt() {
    var selector, id = $(this, "option:selected").val();
    if (id) {
      selector = "g.tnt-" + id;
      $("g.timeshift").hide();
      $(selector).show();
    } else {
      $("g.timeshift").show();
    }
    tableFilterOnlyHide();
  }

  /*  2018-06-26 / Matthias Kummer
      create a new svg element with inline styles which can be serialized
      and downloaded as a file 
  */
  const prepareSVGForDownload = function () {
    let clonedSvg = document.querySelector("#tunnel-occupation-container svg").cloneNode(true),
      body = document.getElementsByTagName("BODY")[0],
      rects;

    body.appendChild(clonedSvg);

    // set inline fill styles for all relevant elements, as many SVG editors cannot deal with CSS stylesheets
    // convert rgba to rgb/opacity, as some SVG editors cannot deal with rgba…
    rects = clonedSvg.querySelectorAll("g.timeshift rect, g.timeshift text, rect.dateWeekend, rect.dateInfo, rect.dateInfoTooltipTarget");
    for (let i = 0; i < rects.length; ++i) {
      let fill = window.getComputedStyle(rects[i]).fill,
        rgba = fill.match(/rgba\(([\d\.]+), ([\d\.]+), ([\d\.]+), ([\d\.]+)/);
      if (rgba) {
        rects[i].setAttribute("fill", "rgb(" + rgba[1] + ", " + rgba[2] + ", " + rgba[3] + ")");
        rects[i].setAttribute("opacity", rgba[4]);
      } else {
        rects[i].setAttribute("fill", fill);
      }
    }
    clonedSvg.getElementById("datePointer").style.display = "none";
    clonedSvg.querySelectorAll("text.fixed-lock").forEach(n => n.remove());

    body.removeChild(clonedSvg);
    return clonedSvg;
  };

  // start initialization 
  busy();
  calcDimensions();
  $("#autoplan-link").click(triggerPlanningRun);
  $("#persist-link").click(persistCurrentPlanning).hide(); // initially disable save button (we receive already persisted ata)
  $(".dropdown.label-selector a").click(function (e) {
    e.preventDefault();
    showLabels(e.target);
  });
  d3.json("/planning_scenarios/__ID__/timeshifts?format=json".replace("__ID__", $(containerSelector).data("id")), {
    credentials: "same-origin"
  }).then(function (data) {
    //window.test = data;
    if (typeof (data.east) == "undefined" || typeof (data.west) == "undefined") {
      hideBusy();
      return;
    }
    if (ck != undefined) {
      $("input[data-class=" + ck + "]").attr("checked", "checked");
    }
    startDate = moment(data.start_time).startOf("day");
    endDate = moment(data.end_time).endOf("day");
    prepareSvg();
    drawTunnel(data.east.concat(data.both || []).concat(data.none || []), svg, "east");
    drawTunnel(data.west.concat(data.both || []).concat(data.none || []), svg, "west");
    drawGrid(data.specialDatesMs);
    showLabels();
    $(".table-controls #show_unchanged").prop("checked", true);
    /*$("body").off("change", "select[name='toggle[labels]']").on("change", "select[name='toggle[labels]']", function () {
      showLabels();
    });*/
    $("#show-tnt").change(selectTnt);
    $("#download-button").click(function () {
      let svg = prepareSVGForDownload();
      serializeAndSendSVG(svg, $(this).data("svg-filename") || "Tunnelbelegung.svg");
    });
    table_filter($(".table-controls"));
    platinClassFilter();
  });
  hideBusy();
};

function subscribeToPlannerChannel() {
  const cpTarget = $("#campaign-part-target"),
    missionTarget = $("#mission-target"),
    afterTarget = $("#after-target"),
    progress = $("#planning-progress");

  const subscription = consumer.subscriptions.create("PlannerChannel", {
    connected: function () {},
    // Called when the subscription is ready for use on the server

    disconnected: function () {},
    // Called when the subscription has been terminated by the server

    received: function (raw) {
      const data = JSON.parse(raw);
      cpTarget.html(data.cp);
      missionTarget.html(data.mission);
      afterTarget.html(moment(data.after).format("DD.MM.YYYY HH:mm"));
      progress.css("width", data.percentage).html(data.percentage);
    }
  });

  $("#planner-dialog").on("hide.bs.modal", () => consumer.subscriptions.remove(subscription));

}

postInit("planning_scenarios#tunnel_occupation", initTunnelOccupation);