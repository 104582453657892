/* global Turbolinks */

import { postInit } from "./platin";
import { keyToSelector } from "./locations";
import $ from "jquery";

function prepareLocationMap(_, svg) {
  const container = $(svg).closest("div"),
    locKeyArrays = container.data("locs");
  let missionIndex = 0;

  $("g.occupied", svg).removeClass("occupied");
  $("g.active", svg).removeClass("active");

  for (let locKeys of locKeyArrays) {
    for (let key of locKeys) {
      const g = $(`g[id='${keyToSelector(key)}']`, svg);
      if (key.match(/^EB/)) {
        g.addClass("active");
      } else if (key.match(/^FL/)) {
        g.addClass("poweroff");
      } else {
        if (g.hasClass("occupied")) {
          g.addClass("conflict");
        }
        g.addClass(`occupied mission-${missionIndex}`);
      }
    }
    missionIndex = missionIndex + 1;
  }
  $(svg).parent().tooltip({
    selector: "[title]",
    container: container
  });

  $(svg).click(() => {
    svg.toggleClass("highlighted");
  });

}

function initPerimeterMaps() {
  const svgs = $(".locations-map svg");
  svgs.each(prepareLocationMap);

  $("tr.highlight-mission").mouseover(function (e) {
    const index = $(this).data("mission-index"),
      svg = $(e.target).closest(".card").find("svg");
    svg.addClass(`highlight-mission-${index}`);
  });
  $("tr.highlight-mission").mouseout(function (e) {
    const index = $(this).data("mission-index"),
      svg = $(e.target).closest(".card").find("svg");
    svg.removeClass(`highlight-mission-${index}`);
  });

  /* extracts the indices from mission-n classes on el as an array of numbers */
  function getMissionIndices(el) {
    return $(el).attr("class")
      .split(" ")
      .filter(s => s.match(/^mission-\d+$/))
      .map(s => parseInt(s.match(/^mission-(\d+)$/)[1]));
  }

  function highlightTableRow(hoverOnEl, on) {
    const missionIndices = getMissionIndices(hoverOnEl),
      svg = $(hoverOnEl).closest("svg");
    for (let missionIndex of missionIndices) {
      if (on) {
        $(`tr.highlight-mission[data-mission-index="${missionIndex}"]`, svg).addClass("highlighted");
      } else {
        $(`tr.highlight-mission[data-mission-index="${missionIndex}"]`, svg).removeClass("highlighted");
      }
    }

  }

  $("svg .occupied").mouseover(function () { highlightTableRow(this, true); });
  $("svg .occupied").mouseout(function () { highlightTableRow(this, false); });
}

postInit("fsa#overview", initPerimeterMaps);