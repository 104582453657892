/* globals $, App */

import {
  initializers
} from "./platin";
import "corejs-typeahead/dist/typeahead.jquery";
import Bloodhound from "corejs-typeahead/dist/bloodhound";

let peopleBloodhound;

const personSelect = function () {
  const personSelects = $("[data-select-person]");

  if (personSelects.length > 0) {
    peopleBloodhound = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace("name_with_code"),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      identify: Bloodhound.tokenizers.obj.whitespace("id"),
      prefetch: {
        url: "/people/json_names.json",
        cache: false
        //url: "/people/json_names.json?query=%QUERY",
        //wildcard: '%QUERY'
        //filter: data => console.log(data)
        //thumbprint: lastModified,
        //cache: false
      }
    });
  }

  personSelects.each(function () {
    const fieldName = $(this).data("select-person"),
      selBase = `#${fieldName}`,
      idField = $(`${selBase}_id`),
      nameField = $(`${selBase}_name`),
      emailField = $(`${selBase}_email`),
      orgField = $(`${selBase}_org`);

    const onIdle = function () {
      if (!this.selection) {
        idField.val("");
        nameField.typeahead("val", "");
      } else {
        nameField.typeahead("val", this.selection.name);
      }
    };

    const onChange = function (evt, obj) {
      if (obj == null) {
        idField.val("");
        nameField[0].selection = null;
      } else {
        idField.val(obj.id);
        //nameField.typeahead("val", obj.name);
        nameField[0].selection = obj;
        if (emailField.length > 0) {
          emailField.val(obj.email);
        }
        if (orgField.length > 0) {
          orgField.val(obj.org);
        }
      }
    };

    const onClose = function () {
      //$(this).typeahead("val", $(this).data("selection"));
      //$(this).typeahead("val", $(this)[0].selection.name);
    };

    const onSelect = function (...args) {
      onChange.call(this, ...args);
    };

    const onCursorchange = function () {
      //onChange.call(this, ...args);
      if ($(this)[0].selection) {
        $(this).typeahead("val", $(this)[0].selection.name);
      }
    };

    const onAutocomplete = function (...args) {
      $(this)[0].selection = args[1];
      onChange.call(this, ...args);
      //alert("autocomplete")
    };

    const clearData = function (el) {
      const fieldName = $(el).data("select-person"),
        selBase = `#${fieldName}`,
        idField = $(`${selBase}_id`),
        emailField = $(`${selBase}_email`),
        orgField = $(`${selBase}_org`);

      idField.val("");
      el.selection = null;
      if (emailField.length > 0) {
        emailField.val("");
      }
      if (orgField.length > 0) {
        orgField.val("");
      }
    };

    const clearIfEmpty = function (evt) {
      if (evt.key == "Backspace" || evt.key == "Delete" && this.value == "") {
        clearData(this);
      } else if (evt.keyCode > 64 && evt.keyCode < 91) {
        clearData(this);
      }
    };

    $(this).typeahead({
      hint: true,
      highlight: true,
      minLength: 2
    }, {
      name: "people",
      display: "name_comp",
      source: peopleBloodhound,
      limit: 20,
    })
      .on("typeahead:idle", onIdle)
      .on("typeahead:close", onClose)
      .on("typeahead:select", onSelect)
      .on("typeahead:cursorchange", onCursorchange)
      .on("typeahead:autocomplete", onAutocomplete)
      .on("keyup", clearIfEmpty);

    $("input[autofocus]").focus(); // restore focus on autofocus element as TT removes it…
  });
};

initializers.push(personSelect);

export {
  personSelect,
  peopleBloodhound
};