/* global $ */
/*
    simply add data-select-document containing the full parameter name in underscore format to input fields
    e.g. mission_siko_attributes_document
    optionally add data-filter-class and data-filter-ids attributes
    data-filter-ids can be a comma separated list of ids
    to restrict the autocompleted documents to those linked to a specific object
    (or a list of objects)
*/

import {
    initializers
} from "./platin";
import Bloodhound from "corejs-typeahead/dist/bloodhound";

const documentSelect = function () {
    var field;

    function idElement(paramName) {
        return $("[id='" + paramName + "_id']");
    }

    function nameElement(paramName) {
        return $("[id='" + paramName + "_name']");
    }

    const onLeave = function () {
        var paramName = $(this).data("select-document");
        if ($(this).val() == "") {
            idElement(paramName).val("");
        } else {
            nameElement(paramName).typeahead("val", nameElement(paramName).data("selection"));
        }
    };

    const onChange = function (evt, obj) {
        var paramName = $(this).data("select-document");
        if (obj == null) {
            idElement(paramName).val("");
        } else {
            idElement(paramName).val(obj.id);
            nameElement(paramName).data("selection", obj.name);
            nameElement(paramName).val(obj.name);
        }
    };

    const onClose = function () {
        this.value = $(this).data("selection");
    };

    const clearIfEmpty = function (evt) {
        if (evt.key == "Backspace" || evt.key == "Delete" && this.value == "") {
            var paramName = $(this).data("select-document");
            idElement(paramName).val("");
        }
    };

    for (field of $("[data-select-document]")) {
        var ajaxURL = "/documents/json_names?",
            documentsBloodhound,
            filterClass = $(field).data("filter-class"),
            filterIDs = $(field).data("filter-ids");

        if (filterClass && filterIDs) {
            ajaxURL += `filter_class=${filterClass}&filter_ids=${filterIDs}&`;
        }
        ajaxURL += "query=%QUERY";

        documentsBloodhound = new Bloodhound({
            datumTokenizer: Bloodhound.tokenizers.obj.whitespace("name"),
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            identify: Bloodhound.tokenizers.obj.whitespace("id"),
            remote: {
                url: ajaxURL,
                wildcard: "%QUERY",
            }
        });

        $(field).typeahead({
                hint: false,
                highlight: true,
                minLength: 2
            }, {
                name: "documents",
                display: "name_comp",
                source: documentsBloodhound,
                limit: 20,
            })
            .on("typeahead:idle", onLeave)
            .on("typeahead:close", onClose)
            .on("typeahead:select typeahead:cursorchange typeahead:autocomplete", onChange)
            .on("keyup", clearIfEmpty);
    }
};

initializers.push(documentSelect);

export {
    documentSelect
};