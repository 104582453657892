var actionInitializersPre = {};
var actionInitializersPost = {};
var initializers = [];
const preInit = function (actionKey, proc) {
  if (typeof actionInitializersPre[actionKey] === "undefined") {
    actionInitializersPre[actionKey] = [];
  }
  actionInitializersPre[actionKey].push(proc);
};
const postInit = function (actionKey, proc) {
  if (typeof actionInitializersPost[actionKey] === "undefined") {
    actionInitializersPost[actionKey] = [];
  }
  actionInitializersPost[actionKey].push(proc);
};

export {
  actionInitializersPre,
  actionInitializersPost,
  initializers,
  preInit,
  postInit
};