import { postInit } from "./platin";
import $ from "jquery";
import { personSelect } from "./person-select";
import { documentSelect } from "./document-select";

function updateSourceSelect(data){
    var container = $("div.source-select"),
        newContainer;
    if (data == null) {
        return true; // empty source -> don't replace anything
    }
    newContainer = $(data);    
    container.replaceWith(newContainer);
    switch (newContainer.data("sourceType")){
    case "Person":
        personSelect();
        break;
    case "Document":
        documentSelect();
        break;
    }
}

function fetchSourceSelect(sourceType, sourceId){
    $.get("/information_elements/source_select/", {
        source_type: sourceType,
        source_id: sourceId
    },
    updateSourceSelect);
}
function sourceTypeSelectChanged(){
    var sourceType = $("option:selected", this).val();
    fetchSourceSelect(sourceType, null);
}
    
const initInformationElementForm = function(){
    var sourceSelect = $("div.source-select");
    $("select#information_element_source_type").change(sourceTypeSelectChanged);
    fetchSourceSelect(sourceSelect.data("sourceType"), sourceSelect.data("sourceId"));
};    

postInit("information_elements#new", initInformationElementForm);
postInit("information_elements#create", initInformationElementForm);
postInit("information_elements#edit", initInformationElementForm);