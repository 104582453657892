/* global chartOptions, modes, data */
"use strict";

import $ from "jquery";
import * as d3 from "d3";

import {
  postInit
} from "./platin";

const initTrainPlot = function () {
  var pointSize = 1.5,
    lineThickness = 0.4;

  var svg = d3.select("svg#train-plot"),
    parentRect, // -> adjustPlotWidth()
    margin = {
      top: 20,
      right: 90,
      bottom: 30,
      left: 86
    },
    width, // -> adjustPlotWidth()
    height = +svg.attr("height") - margin.top - margin.bottom,
    initDomainPos = [chartOptions.km_min, chartOptions.km_max],
    initDomainSpeed = [0, 300],
    initDomainAccel = [-1.5, 1.5],
    initDomainLevel = ["", 0, 1, 2, ""],
    initDomainTime,
    clipContainer,
    plotContainer,
    coordG = svg.append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
      .attr("id", "coordG"),
    defs = coordG.append("defs"),
    clipPath = defs.append("clipPath")
      .attr("id", "clip")
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("height", height);

  var x, yPos, ySpeed, yAccel, yLevel, yMode, yEoa, yPower; // scales
  var xAxis, posAxis, speedAxis, levelAxis, modeAxis, eoaAxis, powerAxis; // axes functions
  var xAxisG;
  let rightAxes = ["axis--level", "axis--mode"]; // keep track of all the axes on the right side, as they are dynamic
  var pos, speed, accel, level, mode, eoa, ur, or, power; // lines
  var posPlot, speedPlot, accelPlot, levelPlot, modePlot, eoaPlot, urPlot, orPlot, lmcePlot, eventsPlot, powerPlot; // plots (G elements with point and line plots)
  var brush, brushArea, cursor, tooltip, lmceTooltip, eventsTooltip; // interaction elements
  var showAccelCurve = false;
  var additionalData;
  var loadTime;
  let powerData;

  var bisectDate = d3.bisector(d => d.tm).left;
  var timestampFormat;

  function localizeD3() {
    d3.timeFormatDefaultLocale({
      "dateTime": "%A, der %e. %B %Y, %X",
      "date": "%d.%m.%Y",
      "time": "%H:%M:%S",
      "periods": ["AM", "PM"],
      "days": ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
      "shortDays": ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
      "months": ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
      "shortMonths": ["Jan", "Feb", "Mrz", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"]
    });
    timestampFormat = d3.timeFormat("%a %d.%m.%Y, %H:%M:%S");
  }

  function timeFormat(extentInHours) {
    if (extentInHours < 0.2) {
      return d3.timeFormat("%H:%M:%S");
    } else {
      return d3.timeFormat("%H:%M");
    }
  }

  function calculateAcceleration() {
    var delta_speed, delta_ms; // speed in km/h, ms in milliseconds
    data.train.pos_reports.forEach(function (pr, index, arr) {
      if (((index == 1) || (index == arr.length - 1)) && ((+arr[index].tm - +arr[index - 1].tm) > 0)) {
        delta_speed = +arr[index].sp - +arr[index - 1].sp;
        delta_ms = +arr[index].tm - +arr[index - 1].tm;
      } else if (index > 1 && ((+arr[index].tm - +arr[index - 1].tm) > 0)) {
        delta_speed = (+arr[index].sp + arr[index + 1].sp) / 2 - (+arr[index].sp + arr[index - 1].sp) / 2,
        delta_ms = (+arr[index].tm + arr[index + 1].tm) / 2 - (+arr[index - 2].tm + arr[index - 1].tm) / 2;
      } else {
        delta_speed = 0;
        delta_ms = 1;
      }
      if ((index > 0) && (+arr[index].tm - +arr[index - 1].tm) == 0) {
        arr[index].ac = arr[index - 1].ac;
      } else {
        arr[index].ac = (delta_speed / 3.6) / (delta_ms / 1000);
      }

      if (isNaN(arr[index].ac)) {
        throw ("error");
      }
    });
  }

  function adjustPlotWidth() {
    parentRect = svg.node().parentNode.getBoundingClientRect();
    margin.right = rightAxes.length * 50;
    width = parentRect.width - margin.left - margin.right;
    x.rangeRound([0, width]);
    clipPath.attr("width", width);
    d3.select("button#reset-zoom").style("left", (width) + "px");
    svg.attr("width", width + margin.left + margin.right);
  }

  function defineScales() {
    x = d3.scaleTime();

    yPos = d3.scaleLinear()
      .rangeRound([height, 0]);
    ySpeed = d3.scaleLinear()
      .rangeRound([height, 0]);
    yAccel = d3.scaleLinear()
      .rangeRound([height, 0]);
    yLevel = d3.scaleOrdinal()
      .range([height, height * 5 / 6, height / 2, height / 6, 0]);
    yMode = d3.scaleOrdinal()
      .range(modes.map(function (v, i) {
        return height - ((i + 1) * height / modes.length);
      }));
    yEoa = d3.scaleLinear()
      .rangeRound([height, 0]);
    yPower = d3.scaleLinear()
      .range([height, 0]);
  }

  function defineAxes() {
    xAxis = d3.axisBottom(x)
      .tickSizeInner(-height)
      .tickFormat(timeFormat((x.domain()[1] - x.domain()[0]) / 3600000));
    posAxis = d3.axisLeft(yPos)
      .tickValues(chartOptions.station_ticks.map(function (t) {
        return t[0];
      }))
      .tickFormat(function (d, i) {
        return chartOptions.station_ticks[i][1];
      })
      .tickSizeInner(-width);
    speedAxis = d3.axisLeft(ySpeed);
    levelAxis = d3.axisRight(yLevel);
    modeAxis = d3.axisRight(yMode);
    eoaAxis = d3.axisRight(yEoa);
    powerAxis = d3.axisRight(yPower);
  }

  function defineLineFunctions() {
    pos = d3.line()
      .x(d => x(d.tm))
      .y(d => yPos(d.km));

    speed = d3.line()
      .x(d => x(d.tm))
      .y(d => ySpeed(d.sp));

    accel = d3.line()
      .x(d => x(d.tm))
      .y(d => yAccel(d.ac));

    level = d3.line()
      .x(d => x(d.tm))
      .y(d => yLevel(d.lv));

    mode = d3.line()
      .x(d => x(d.tm))
      .y(d => yMode(d.md));

    eoa = d3.line()
      .x(d => x(d.tm))
      .y(d => yEoa(d.eoa));

    ur = d3.line()
      .x(d => x(d.tm))
      .y(d => ySpeed(d.ur));
    or = d3.line()
      .x(d => x(d.tm))
      .y(d => ySpeed(d.or));

    power = d3.line()
      .x(d => x(d.tm))
      .y(d => yPower(d.mw));
  }

  function setScaleDomains() {
    initDomainTime = d3.extent(data.train.pos_reports, function (d) {
      return d.tm;
    });
    initDomainTime[0] = initDomainTime[0] - 5 * 60 * 1000;
    initDomainTime[1] = initDomainTime[1] + 5 * 60 * 1000;
    x.domain(initDomainTime);
    yPos.domain(initDomainPos);
    ySpeed.domain(initDomainSpeed);
    yAccel.domain(initDomainAccel);
    yLevel.domain(initDomainLevel);
    yMode.domain(modes);
  }

  function createPlots() {
    levelPlot = plotSeries("level", d => yLevel(d.lv), level, data.train.pos_reports);
    modePlot = plotSeries("mode", d => yMode(d.md), mode, data.train.pos_reports);
    posPlot = plotSeries("pos", d => yPos(d.km), pos, data.train.pos_reports);
    speedPlot = plotSeries("speed", d => ySpeed(d.sp), speed, data.train.pos_reports);
    accelPlot = plotSeries("accel", d => yAccel(d.ac), accel, data.train.pos_reports);
  }

  function addAxes() {
    xAxisG = coordG.append("g")
      .attr("class", "axis--x")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxis);

    coordG.append("g")
      .call(posAxis)
      .attr("class", "axis--pos");

    coordG.append("g")
      .attr("transform", "translate(" + "-42" + ",0)")
      .attr("class", "axis--y")
      .attr("class", "axis--speed")
      .call(speedAxis)
      .append("text")
      .attr("class", "axis-label")
      .attr("transform", "rotate(-90)")
      .attr("x", -height / 2)
      .attr("y", -40)
      .attr("dy", "0.71em")
      .attr("text-anchor", "middle")
      .text("V [km/h]");

    coordG.append("g")
      .attr("transform", "translate(" + width + ",0)")
      .attr("class", "axis--y")
      .attr("class", "axis--level")
      .call(levelAxis)
      .append("text")
      .attr("class", "axis-label")
      .attr("transform", "rotate(90)")
      .attr("x", height / 2)
      .attr("y", -30)
      .attr("dy", "0.71em")
      .attr("text-anchor", "middle")
      .text("Level");

    coordG.append("g")
      .attr("transform", "translate(" + (width + 50) + ",0)")
      .attr("class", "axis--y")
      .attr("class", "axis--mode")
      .call(modeAxis)
      .append("text")
      .attr("class", "axis-label")
      .attr("transform", "rotate(90)")
      .attr("x", height / 2)
      .attr("y", -38)
      .attr("dy", "0.71em")
      .attr("text-anchor", "middle")
      .text("Mode");

    var btn = coordG
      .append("g")
      .attr("id", "reset-zoom")
      .attr("title", "Zoom out")
      .attr("data-toggle", "tooltip")
      .attr("transform", "translate(" + (width + 14) + ",4)");
    btn.append("rect")
      .attr("fill", "white")
      .attr("stroke", "grey")
      .attr("rx", "4")
      .attr("x", "-9.2")
      .attr("y", "0")
      .attr("width", "18")
      .attr("height", "18");
    btn.append("text")
      .attr("font-family", "FontAwesome")
      .attr("dy", "1.15em")
      .attr("text-anchor", "middle")
      .text("\uf0b2");
  }

  function addClip() {
    clipContainer = coordG.append("g")
      .attr("clip-path", "url(#clip)");
    plotContainer = clipContainer.append("g")
      .attr("class", "plot-container");
  }

  function addMarkings() {
    plotContainer.append("g")
      .attr("class", "markings")
      .selectAll("rect.markings")
      .data(chartOptions.markings)
      .enter()
      .append("rect")
      .attr("class", "markings")
      .attr("fill", function (d) {
        return d.color;
      })
      .attr("x", 0)
      .attr("width", width)
      .attr("y", function (d) {
        return yPos(d.yaxis.to);
      })
      .attr("height", function (d) {
        return yPos(d.yaxis.from) - yPos(d.yaxis.to);
      });
  }

  function addLegend() {
    var legendG = plotContainer.append("g")
      .attr("class", "legend")
      .attr("transform", "translate(8,0)");
    legendG.append("rect")
      .attr("class", "legend-background")
      .attr("width", 46)
      .attr("height", 52);
    var legendEntries = legendG
      .selectAll("g")
      .data([
        ["km", "pos"],
        ["V", "speed"],
        ["Level", "level"],
        ["Mode", "mode"],
        ["EOA", "eoa"],
        ["Under-reading", "ur"],
        ["Over-reading", "or"],
        ["Leistung", "power"]
      ])
      .enter()
      .append("g")
      //.attr("class", "legend")
      .attr("class", function (d) {
        return d[1];
      })
      .attr("transform", function (d, i) {
        return "translate(0," + i * 14 + ")";
      });
    legendEntries
      .append("rect")
      .attr("class", "showhide")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", 8)
      .attr("height", 8);
    legendEntries
      .append("text")
      .attr("dy", "0.65em")
      .attr("x", 14)
      .text(function (d) {
        return d[0];
      });
    legendEntries
      .append("text")
      .attr("font-family", "FontAwesome")
      .attr("x", 0)
      .attr("dy", "0.65em")
      .attr("display", "none")
      .attr("class", "showhide")
      .text("\uf070");

    $("g.legend .eoa").hide();
    $("g.legend .ur").hide();
    $("g.legend .or").hide();
    $("g.legend .power").hide();

    $("g.legend g").click(function () {
      var cl = $(this).attr("class");
      $("g.plot-g." + cl).toggle();
      $(".showhide", this).toggle();
    });
  }

  function plotSeries(seriesName, yFunction, lineFunction, posReports) {
    var plotG = plotContainer.select("g.plot-g." + seriesName);
    if (plotG.size() != 1) {
      plotG = plotContainer.append("g")
        .attr("class", `plot-g ${seriesName}${seriesName == "power" && posReports.length < 30 ? " iprix" : ""}`);
    }
    var points = plotG.selectAll("circle")
      .data(posReports);
    points.enter()
      .append("circle")
      .attr("r", pointSize)
      .merge(points)
      .attr("cx", d => x(d.tm))
      .attr("cy", yFunction);
    points.exit().remove();

    var path = plotG.select("path");
    if (path.size() != 1) {
      path = plotG.append("path")
        .attr("class", "line")
        .attr("stroke-width", lineThickness);
    }
    path
      .datum(posReports)
      .attr("d", lineFunction);
    return plotG;
  }

  function plotEvents(eventsArray) {
    // add symbols
    var plotG = coordG.append("g")
      .attr("class", "plot-g emergency-stops");
    var points = plotG.selectAll("image")
      .data(eventsArray);
    var link = $("#run-analysis");
    var icons = {
      UesToTrain: link.data("red-flash"),
      CesConsidered: link.data("attention"),
      MovementAuthority: link.data("chevron")
    };
    points.enter()
      .append("image")
      .attr("xlink:href", function (d) {
        return icons[d.type];
      })
      .attr("height", "10")
      .attr("width", "10")
      .attr("class", function (d) {
        return "rbc-event " + d.type;
      })
      .attr("x", function (d) {
        return x(d.tm) - 5;
      })
      .attr("y", -10)
      .on("mouseenter", eventsTooltip.show)
      .on("mouseleave", eventsTooltip.hide);
    return plotG;
  }

  function plotLevelModeChangeEvents(lmceArray) {
    // add symbols
    var plotG = coordG.append("g")
      .attr("class", "plot-g level-mode-change-events");
    var points = plotG.selectAll("image")
      .data(lmceArray);
    var link = $("#run-analysis");
    var icon = link.data("lmce");
    points.enter()
      .append("image")
      .attr("xlink:href", icon)
      .attr("height", "10")
      .attr("width", "10")
      .attr("class", function (d) {
        return "lmce " + d.md + " " + d.lv;
      })
      .attr("x", function (d) {
        return x(d.tm) - 5;
      })
      .attr("y", -20)
      .on("mouseenter", lmceTooltip.show)
      .on("mouseleave", lmceTooltip.hide);
    return plotG;
  }

  function hideCursor() {
    cursor.style("display", "none");
    tooltip.container.style("display", "none");
  }

  function addInteraction() {
    // interaction overlay
    var focus = plotContainer.append("g")
      .attr("class", "plot-area")
      .on("mousemove", mousemove)
      .on("mouseleave", hideCursor);

    brush = d3.brushX()
      .extent([
        [0, 0],
        [width, height]
      ])
      .on("end", brushed);
    var brushClearing = false; // will be set to true when clearing so we can ignore the resulting brush event
    brushArea = focus.append("g")
      .attr("class", "brush")
      .call(brush);

    function addCursor() {
      var newCursor = clipContainer.append("line")
        .attr("class", "d3-cursor")
        .attr("y1", 0)
        .attr("y2", height)
        .attr("stroke", "red")
        .style("display", "none");
      return newCursor;
    }

    function addTooltip() {
      let container = clipContainer.append("g")
          .attr("class", "d3-tooltip")
          .style("display", "none"),
        ttBox = container.append("rect")
          .attr("class", "tt-box")
          .attr("rx", 4)
          .attr("ry", 4),
        ttText =  container.append("text");
      return {
        container,
        ttBox,
        posCircle: container.append("circle")
          .attr("class", "pos")
          .attr("r", 4)
          .attr("stroke-width", 1),
        speedCircle: container.append("circle")
          .attr("class", "speed")
          .attr("r", 4)
          .attr("stroke-width", 1),
        accelCircle: container.append("circle")
          .attr("class", "accel")
          .attr("r", 4)
          .attr("stroke-width", 1),
        levelCircle: container.append("circle")
          .attr("class", "level")
          .attr("r", 4)
          .attr("stroke-width", 1),
        modeCircle: container.append("circle")
          .attr("class", "mode")
          .attr("r", 4)
          .attr("stroke-width", 1),
        eoaCircle: container.append("circle")
          .attr("class", "eoa")
          .attr("r", 4)
          .attr("stroke-width", 1),
        urCircle: container.append("circle")
          .attr("class", "ur")
          .attr("r", 4)
          .attr("stroke-width", 1),
        orCircle: container.append("circle")
          .attr("class", "or")
          .attr("r", 4)
          .attr("stroke-width", 1),
        powerCircle: container.append("circle")
          .attr("class", "power")
          .attr("r", 4)
          .attr("stroke-width", 1),
        ttText
      };          
    }

    function addLMCETooltip() {
      // separate tooltip for level/mode change events
      var container = clipContainer.append("g")
          .attr("class", "d3-tooltip lmce")
          .style("display", "none"),
        ttBox = container.append("rect")
          .attr("class", "tt-box")
          .attr("rx", 4)
          .attr("ry", 4)
          .attr("width", 200)
          .attr("height", 100),
        ttText = container.append("text")
          .attr("x", 12)
          .attr("y", 68),
        ttTextMode = ttText
          .append("tspan")
          .attr("dy", "-2.4em"),
        ttTextLevel = ttText
          .append("tspan")
          .attr("dy", "-1.2em"),
        ttTextTimestamp = ttText
          .append("tspan")
          .attr("dy", "0em");

      function show() {
        function line1(item) {
          return "Mode: " + item.om + " => " + item.md;
        }

        function line2(item) {
          return "Level: " + item.ol + " => " + item.lv;
        }
        var datum = d3.select(this).datum(),
          textBBox,
          textX, textY,
          y = 0,
          valueX = x(datum.tm);

        container.attr("transform", "translate(" + valueX + "," + y + ")")
          .style("display", "");

        ttTextTimestamp
          .text(timestampFormat(datum.tm));
        ttTextMode
          .text(line1(datum));
        ttTextLevel
          .text(line2(datum));
        textBBox = ttText.node().getBBox();
        if (valueX + textBBox.width <= width - 8) {
          textX = 20;
        } else {
          textX = -20 - textBBox.width;
        }
        textY = 2 + textBBox.height;
        ttBox
          .attr("width", textBBox.width + 16)
          .attr("height", textBBox.height + 16)
          .attr("x", textX - 2)
          .attr("y", textY - textBBox.height);

        ttTextTimestamp
          .attr("x", textX + 6)
          .attr("y", textY + 4);
        ttTextMode
          .attr("x", textX + 6)
          .attr("y", textY + 4);
        ttTextLevel
          .attr("x", textX + 6)
          .attr("y", textY + 4);

        /* show cursor */
        cursor
          .style("display", "")
          .attr("x1", valueX)
          .attr("x2", valueX);
      }

      function hide() {
        cursor.style("display", "none");
        container.style("display", "none");
      }

      return {
        container: container,
        ttTextMode: ttTextMode,
        ttTextLevel: ttTextLevel,
        ttTextTimestamp: ttTextTimestamp,
        show: show,
        hide: hide
      };
    }

    /* 2019-02-24 / Matthias Kummer
           returns an object with the following attributes:
           - container: the <g> container of the tooltip
           - ttTTextType: the <tspan> element for the event type string
           - ttTextIdentifier: the <tspan> element for the identifier string
           - ttTextTimestamp: the <tspan> element for the timestamp
           - show: callback for showing the tooltip
           - hide: callback for hiding it
        */
    function addEventsTooltip() {
      // separate tooltip for events
      var container = clipContainer.append("g")
          .attr("class", "d3-tooltip emergency-stops")
          .style("display", "none"),
        ttBox = container.append("rect")
          .attr("class", "tt-box")
          .attr("rx", 4)
          .attr("ry", 4)
          .attr("width", 200)
          .attr("height", 100),
        ttText = container.append("text")
          .attr("x", 12)
          .attr("y", 68),
        ttTextType = ttText
          .append("tspan")
          .attr("dy", "-2.4em"),
        ttTextIdentifier = ttText
          .append("tspan")
          .attr("dy", "-1.2em"),
        ttTextTimestamp = ttText
          .append("tspan")
          .attr("dy", "0em");

      function show() {
        function line1(item) {
          var l;
          switch (item.type) {
          case "MovementAuthority":
            l = "Movement Authority";
            break;
          case "UesToTrain":
          case "CesConsidered":
            l = item.label;
            break;
          }
          return l;
        }

        function line2(item) {
          var l;
          switch (item.type) {
          case "MovementAuthority":
            l = "EoA bei BG " + item.country_id + "-" + item.bg + " + " + item.len + " m";
            break;
          case "UesToTrain":
          case "CesConsidered":
            l = "Identifier: " + item.id;
            break;
          }
          return l;
        }
        var datum = d3.select(this).datum(),
          textBBox,
          textX, textY,
          y = 0,
          valueX = x(datum.tm);

        container.attr("transform", "translate(" + valueX + "," + y + ")")
          .style("display", "");

        ttTextTimestamp
          .text(timestampFormat(datum.tm));
        ttTextType
          .text(line1(datum));
        ttTextIdentifier
          .text(line2(datum));
        textBBox = ttText.node().getBBox();
        if (valueX + textBBox.width <= width - 8) {
          textX = 20;
        } else {
          textX = -20 - textBBox.width;
        }
        textY = 2 + textBBox.height;
        ttBox
          .attr("width", textBBox.width + 16)
          .attr("height", textBBox.height + 16)
          .attr("x", textX - 2)
          .attr("y", textY - textBBox.height);

        ttTextTimestamp
          .attr("x", textX + 6)
          .attr("y", textY + 4);
        ttTextType
          .attr("x", textX + 6)
          .attr("y", textY + 4);
        ttTextIdentifier
          .attr("x", textX + 6)
          .attr("y", textY + 4);

        /* show cursor */
        cursor
          .style("display", "")
          .attr("x1", valueX)
          .attr("x2", valueX);
      }

      function hide() {
        cursor.style("display", "none");
        container.style("display", "none");
      }

      return {
        container: container,
        ttTextType: ttTextType,
        ttTextIdentifier: ttTextIdentifier,
        ttTextTimestamp: ttTextTimestamp,
        show: show,
        hide: hide
      };
    }

    function brushed() {
      if (brushClearing) {
        brushClearing = false;
        return false;
      }
      var s = d3.event.selection;
      if (s == null) {
        return;
      }
      var newTimeDomain = !s ? initDomainTime : s.map(x.invert);
      x.domain(newTimeDomain);
      brushClearing = true;
      zoom();
    }

    function mousemove() {
      let mouseX = d3.mouse(this)[0],
        mouseY = d3.mouse(this)[1],
        mouseDate = x.invert(mouseX),
        ind = bisectDate(data.train.pos_reports, mouseDate, 1),
        eoaInd, powerInd,
        dataPoint, additionalDataPoint, powerDataPoint,
        timestamp,
        dataX,
        posY,
        speedY,
        accelY,
        levelY,
        modeY,
        eoaY,
        urY,
        orY,
        powerX, powerY, // power has higher resolution, place circle at exact x pos
        textX,
        textY,
        format = d3.format(".1f"),
        format2 = d3.format(".2f"),
        textLines = [];

      if (ind > data.train.pos_reports.length - 1) {
        ind = data.train.pos_reports.length - 1;
      }
      if (ind > 0 && ((mouseDate - data.train.pos_reports[ind - 1].tm) < (data.train.pos_reports[ind].tm - mouseDate))) {
        ind = ind - 1;
      }
      dataPoint = data.train.pos_reports[ind];
      timestamp = dataPoint.tm;

      if (additionalData) {
        eoaInd = bisectDate(additionalData, mouseDate, 1);
        if (eoaInd >= additionalData.length) {
          eoaInd = additionalData.length - 1;
        }
        if (eoaInd > 0 && ((mouseDate - additionalData[eoaInd - 1].tm) < (additionalData[eoaInd].tm - mouseDate))) {
          eoaInd = eoaInd - 1;
        }
        additionalDataPoint = additionalData[eoaInd];
      }

      if (powerData) {
        powerInd = bisectDate(powerData, mouseDate, 1);
        if (powerInd >= powerData.length) {
          powerInd = powerData.length - 1;
        }
        if (powerInd > 0 && ((mouseDate - powerData[powerInd - 1].tm) < (powerData[powerInd].tm - mouseDate))) {
          powerInd = powerInd - 1;
        }
        // ignore powerData if mouse is > 100 seconds away from the outmost data point
        if ((Math.abs(powerData[powerInd].tm - timestamp) > 100000)
            && ((powerInd == 0) || (powerInd == powerData.length - 1))) {
          powerDataPoint = null;
        } else {
          powerDataPoint = powerData[powerInd];
        }
      }

      dataX = x(timestamp);
      posY = yPos(dataPoint.km);
      speedY = ySpeed(dataPoint.sp);
      accelY = yAccel(dataPoint.ac);
      levelY = yLevel(dataPoint.lv);
      modeY = yMode(dataPoint.md);
      if (additionalData) {
        eoaY = yEoa(additionalDataPoint.eoa);
        urY = ySpeed(additionalDataPoint.ur);
        orY = ySpeed(additionalDataPoint.or);
      }
      if (powerDataPoint) {
        powerY = yPower(powerDataPoint.mw);
        powerX = x(powerDataPoint.tm);
      }

      cursor
        .style("display", "")
        .attr("x1", dataX)
        .attr("x2", dataX);
      tooltip.container
        .style("display", "")
        .attr("transform", "translate(" + dataX + "," + mouseY + ")");
      tooltip.posCircle
        .attr("cy", posY - mouseY);
      tooltip.speedCircle
        .attr("cy", speedY - mouseY);
      tooltip.accelCircle
        .attr("cy", accelY - mouseY);
      tooltip.levelCircle
        .attr("cy", levelY - mouseY);
      tooltip.modeCircle
        .attr("cy", modeY - mouseY);
      if (additionalData) {
        tooltip.eoaCircle
          .attr("cy", eoaY - mouseY);
        tooltip.urCircle
          .attr("cy", urY - mouseY);
        tooltip.orCircle
          .attr("cy", orY - mouseY);
      }
      if (powerDataPoint){
        tooltip.powerCircle
          .attr("cx", powerX - dataX)
          .attr("cy", powerY - mouseY)
          .style("display", null);
      } else {
        tooltip.powerCircle
          .style("display", "none");
      }
      
      textLines.push(timestampFormat(timestamp));
      textLines.push(`km ${dataPoint.kmr} (${dataPoint.tr}, RBC ${dataPoint.rbc})`);
      textLines.push(`${dataPoint.sp} km/h, Level ${dataPoint.lv}, Mode ${dataPoint.md}`);
      textLines.push(`Beschleunigung: ${format(dataPoint.ac)} m/s2`);


      if (additionalData) {
        textLines.push(`EOA in ${format(additionalDataPoint.eoa * 1000)} m`);
        textLines.push(`Under-reading:  ${format(additionalDataPoint.ur)} m`);
        textLines.push(`Over-reading:  ${format(additionalDataPoint.or)} m`);
      }
      if (powerDataPoint) {
        textLines.push(`Leistung ${format2(powerDataPoint.mw)} MW`);
      }

      let tspans = tooltip.ttText
        .selectAll("tspan")
        .data(textLines);
      tspans.exit()
        .remove();
      tspans.enter()
        .append("tspan")
        .merge(tspans)
        .text(d => d);

      // calculate dimensions of the tooltip
      let ttHeight = textLines.length * 16;
      if (mouseY - ttHeight >= 24) {
        textY = - 4;
      } else {
        textY = ttHeight + 4;
      }
      let textBBox = tooltip.ttText.node().getBBox();
      if (dataX + textBBox.width <= width - 8) {
        textX = 6;
      } else {
        textX = -6 - textBBox.width;
      }
      tspans
        .attr("x", textX + 4)
        .attr("y", (_, i) => `${textY - ttHeight + 14 + 16 * i}`);

      tooltip.ttBox
        .attr("width", textBBox.width + 12)
        .attr("height", textBBox.height + 8)
        .attr("x", textX - 2)
        .attr("y", textY - textBBox.height - 2);
      if (showAccelCurve) {
        tooltip.accelCircle.style("display", null);
      } else {
        tooltip.accelCircle.style("display", "none");
      }
    }

    function zoom() {
      var t = svg.transition().duration(500).on("end", function () {
        if (brushClearing) {
          brushArea.call(brush.move, null);
        }
      });
      xAxis.tickFormat(timeFormat());
      svg.select(".axis--x").transition(t).call(xAxis);
      plotContainer.selectAll("circle")
        .transition(t)
        .attr("cx", function (d) {
          return x(d.tm);
        });
      posPlot.select("path").transition(t)
        .attr("d", pos);
      speedPlot.select("path").transition(t)
        .attr("d", speed);
      accelPlot.select("path").transition(t)
        .attr("d", accel);
      levelPlot.select("path").transition(t)
        .attr("d", level);
      modePlot.select("path").transition(t)
        .attr("d", mode);
      lmcePlot.selectAll("image").transition(t)
        .attr("x", function (d) {
          return x(d.tm) - 5;
        });
      if (additionalData) {
        eoaPlot.select("path").transition(t)
          .attr("d", eoa);
        urPlot.select("path").transition(t)
          .attr("d", ur);
        orPlot.select("path").transition(t)
          .attr("d", or);
        eventsPlot.selectAll("image").transition(t)
          .attr("x", function (d) {
            return x(d.tm) - 5;
          });
      }
      if (powerData) {
        powerPlot.selectAll("path").transition(t)
          .attr("d", power);
      }
    }

    cursor = addCursor();
    tooltip = addTooltip();
    lmceTooltip = addLMCETooltip();
    eventsTooltip = addEventsTooltip();
    d3.select("#reset-zoom")
      .attr("class", "btn btn-xs btn-default")
      .attr("title", "Reset zoom")
      .on("click", function () {
        x.domain(initDomainTime);
        zoom();
      });
  }

  localizeD3();
  calculateAcceleration();
  defineScales();
  adjustPlotWidth();
  setScaleDomains();
  defineAxes();
  addAxes();
  addClip();
  addMarkings();
  defineLineFunctions();
  createPlots();
  addInteraction();
  lmcePlot = plotLevelModeChangeEvents(data.train.level_mode_change_events);
  addLegend();
  installPrevNextHandler();
  showHideAccelCurve();
  installPopstateHandler();

  function updatePlot(data, status, req) {
    additionalData = null;
    var renderTime = new Date();
    calculateAcceleration();
    $(".train-header").replaceWith(data.header);
    $("#base_data").replaceWith(data.table);
    // App.installRbcDatePicker();
    installPrevNextHandler();
    setScaleDomains();
    posPlot = plotSeries("pos", function (d) {
      return yPos(d.km);
    }, pos, data.train.pos_reports);
    speedPlot = plotSeries("speed", function (d) {
      return ySpeed(d.sp);
    }, speed, data.train.pos_reports);
    accelPlot = plotSeries("accel", function (d) {
      return yAccel(d.ac);
    }, accel, data.train.pos_reports);
    levelPlot = plotSeries("level", function (d) {
      return yLevel(d.lv);
    }, level, data.train.pos_reports);
    modePlot = plotSeries("mode", function (d) {
      return yMode(d.md);
    }, mode, data.train.pos_reports);
    lmcePlot = plotLevelModeChangeEvents(data.train.level_mode_change_events);
    xAxis.tickFormat(timeFormat());
    svg.select(".axis--x").call(xAxis);
    $("a.update-train-plot.next").attr("href", data.next_url);
    $("a.update-train-plot.prev").attr("href", data.prev_url);
    if (showAccelCurve) {
      $("#show_accel").attr("checked", "checked");
    } else {
      $("#show_accel").attr("checked", false);
    }
    showHideAccelCurve();
    $(".stats").text("received " + window.formatBytes(req.responseText.length) +
            " after " + (renderTime - loadTime) + " ms, took " + (new Date() - renderTime) + " ms to render");
  }

  function handleWindowResize() {
    adjustPlotWidth();
    setScaleDomains();
    xAxisG.call(xAxis);
    rightAxes.forEach((selector, i) => {
      d3.select(`.${selector}`).attr("transform", `translate(${(width + 50 * i)}, 0)`);
    });
    d3.select("#reset-zoom").attr("transform", "translate(" + (width + 14) + ",4)");
    posAxis.tickSizeInner(-width);
    d3.select(".axis--pos").call(posAxis);
    posPlot = plotSeries("pos", function (d) {
      return yPos(d.km);
    }, pos, data.train.pos_reports);
    speedPlot = plotSeries("speed", function (d) {
      return ySpeed(d.sp);
    }, speed, data.train.pos_reports);
    accelPlot = plotSeries("accel", function (d) {
      return yAccel(d.ac);
    }, accel, data.train.pos_reports);
    levelPlot = plotSeries("level", function (d) {
      return yLevel(d.lv);
    }, level, data.train.pos_reports);
    modePlot = plotSeries("mode", function (d) {
      return yMode(d.md);
    }, mode, data.train.pos_reports);
    lmcePlot.selectAll("image")
      .attr("x", d => x(d.tm) - 5);

    if (additionalData) {
      eoaPlot = plotSeries("eoa", d => yEoa(d.eoa), eoa, additionalData);
      urPlot = plotSeries("ur", d => ySpeed(d.ur), ur, additionalData);
      orPlot = plotSeries("or", d => ySpeed(d.or), or, additionalData);
      if (eventsPlot){
        eventsPlot.select("image")
          .attr("x", d => x(d.tm) - 10);
      }
    }
    if (powerData){
      powerPlot = plotSeries("power", d => yPower(d.mw), power, powerData);
    }

    brush.extent([
      [0, 0],
      [width, height]
    ]);
    brushArea.call(brush);
    d3.selectAll("g.markings rect.markings").attr("width", width);
    //d3.select("button#reset-zoom").style("left", (width) + "px");
  }

  function installPrevNextHandler() {
    $("a.update-train-plot").click(function (event) {
      event.preventDefault();
      var url = $(event.target).attr("href");
      history.pushState({
        url: url
      }, $(".page-header h1").text(), url);
      loadTime = new Date();
      hideCursor();
      $.get(url, updatePlot, "json");
    });
  }

  function installPopstateHandler() {
    window.onpopstate = function (event) {
      event.preventDefault();
      loadTime = new Date();
      hideCursor();
      $.get(event.state.url, updatePlot, "json");
    };
  }

  function showHideAccelCurve() {
    if ($("#show_accel").is(":checked")) {
      $("g.accel").show();
      showAccelCurve = true;
    } else {
      $("g.accel").hide();
      showAccelCurve = false;
    }
  }

  /* 2018-11-10 / Matthias Kummer
       added functionality for MA curve
       while data for all other curves is kept in global var data,
       MA data (array of {tm: time_ms, eoa: distance_to_eoa_in_km_or_null}) is
       stored in local val additionalData
    */
  var addMACurve = function (data) {
    $("#loading-mas").modal("hide");
    window.skipBusy = false;
    additionalData = data.pr;
    yEoa.domain(d3.extent([0, 10].concat(d3.extent(data.pr, function (d) {
      return d.eoa;
    }))));
    eoaPlot = plotSeries("eoa", function (d) {
      return yEoa(d.eoa);
    }, eoa, data.pr);
    urPlot = plotSeries("ur", function (d) {
      return ySpeed(d.ur);
    }, ur, data.pr);
    orPlot = plotSeries("or", function (d) {
      return ySpeed(d.or);
    }, or, data.pr);
    coordG.append("g")
      .attr("class", "axis--y")
      .attr("class", "axis--eoa")
      .call(eoaAxis)
      .append("text")
      .attr("class", "axis-label")
      .attr("transform", "rotate(90)")
      .attr("x", height / 2)
      .attr("y", -38)
      .attr("dy", "0.71em")
      .attr("text-anchor", "middle")
      .text("Distanz bis EOA [km]");
    rightAxes.push("axis--eoa");
    $("g.legend .eoa").show();
    $("g.legend .ur").show();
    $("g.legend .or").show();
    handleWindowResize();
  };

  var addESLabels = function (data) {
    eventsPlot = plotEvents(data);
  };

  $(document).on("change", "#show_accel", function () {
    showHideAccelCurve();
  });

  function addAnalyzedData(data) {
    addMACurve(data);
    addESLabels(data.mas.concat(data.events));
    d3.select("rect.legend-background")
      .attr("width", 80)
      .attr("height", 94);
  }

  /* 2021-06-09 / Matthias Kummer
       added functionality for power curve
    */
  function addPowerPlot() {
    const trainID = $("#add-power").data("id");
    d3.json(`/trains/${trainID}/power_curve.json`, {
      credentials: "same-origin"
    }).then(function (response) {
      if (response.length == 0){
        alert("Keine Leistungsdaten gefunden!");
        return;
      }
      powerData = response;
      yPower.domain(d3.extent(d3.extent(powerData, d => d.mw)));
      powerPlot = plotSeries("power", d => yPower(d.mw), power, powerData);
      $("g.legend .power").show();
      coordG.append("g")
        .attr("class", "axis--y axis--power")
        .call(powerAxis)
        .append("text")
        .attr("class", "axis-label")
        .attr("transform", "rotate(90)")
        .attr("x", height / 2)
        .attr("y", -38)
        .attr("dy", "0.71em")
        .attr("text-anchor", "middle")
        .text("Leistung [MW]");
      rightAxes.push("axis--power");
      handleWindowResize();
    });
  }  

  $("#run-analysis").click(function (e) {
    var id = $(this).data("id");
    e.preventDefault();
    $("#loading-mas").modal("show");
    window.skipBusy = true;
    $.get("/trains/" + id + "/data_analysis.json", addAnalyzedData);
  });

  $("#add-power").on("click", function (e) {
    e.preventDefault();
    addPowerPlot();
  });

  $(window).on("resize", handleWindowResize);

};

postInit("trains#show", initTrainPlot);