import {
  postInit
} from "./platin";
import $ from "jquery";


function initCalendar() {
  const selector = "#planning-calendar";
  let cal;

  function render(event, element) {
    /*if (!$("#show_" + event.class).is(":checked")) {
        return false;
    }*/
    const viewName = cal.fullCalendar("getView").name;
    if (viewName != "month") {
      $(".fc-title, .fc-list-item-title", element).html(`${event.title}: ${event.name}`);
    }
    element.attr("title", event.tooltip);
    element.tooltip({
      html: true
    });
    element.addClass(event.status);
    element.addClass(event.class);
  };

  function clickHandler(calEvent) {
    let url;
    if (calEvent.class == "mission" && calEvent.access) {
      url = `/${window.locale}/missions/${calEvent.id}`;
      window.open(url, "_blank");
    }
  };

  import( /* webpackChunkName: "fc" */ 'fullcalendar/dist/fullcalendar').then(() => {
    import( /* webpackChunkName: "fc" */ "fullcalendar/dist/locale/it");
    import( /* webpackChunkName: "fc" */ "fullcalendar/dist/locale/de-ch").then(() => {
      //    import( /* webpackChunkName: "fc" */ 'fullcalendar').then(() => {
      cal = $(selector).fullCalendar({
        header: {
          left: "today prev,next",
          center: "title",
          right: "month,agendaWeek,agendaDay,listWeek"
        },
        locale: window.locale || "de-ch",
        events: `/${window.locale}/planning_scenarios/calendar_events.json`,
        defaultView: "agendaWeek",
        height: "parent",
        eventRender: render,
        eventClick: clickHandler,
        allDaySlot: false,
        weekNumbers: true,
        themeSystem: "bootstrap4"
      });
      $(".calendar-filter input").change(function () {
        cal.fullCalendar("rerenderEvents");
      });
    });
  });
};

postInit("planning_scenarios#calendar", initCalendar);