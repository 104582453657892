/*
    2018-06-19 / Matthias Kummer
    
    triggers a click on any <button|input type="submit"> element
    upon enter keypress.
    The handler is installed on the document and thus only installed
    upon initial loading of the js pack.

*/

import $ from "jquery";
import { initializers } from "./platin";

const installEnterModalCloseHandler = function () {
  $(document).on("keydown", function (event) {
    if (event.which == 13) {
      $(event.target, "button[type=\"submit\"], input[type=\"submit\"]").click();
    }
  });
};

function dismissModalByClassOnSubmitButton() {
  $("body").on("submit", ".dismiss-on-submit .modal-dialog form", () => {
    $(".modal", this).modal("hide");
  });
}

/* 2019-11-18 / Matthias Kummer
   disable due to intermittent double submission behaviour in Chrome
   
installEnterModalCloseHandler();
*/

initializers.push(dismissModalByClassOnSubmitButton);

export {
  installEnterModalCloseHandler
};