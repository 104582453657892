import $ from "jquery";
import {
  initializers,
  actionInitializersPre,
  actionInitializersPost
} from "platin";
import "webpack-jquery-ui/datepicker";
import "jquery-ui/ui/i18n/datepicker-de";
import "bootstrap";
import consumer from "./channels/consumer";

var delayTimer;

/* 
    2018-10-01 / Matthias Kummer
    utility function for identifying duplicate IDs on a page
*/
const findDuplicateIDs = function () {
  $("[id]").each(function () {
    var id = $("[id=\"" + this.id + "\"]");
    if (id.length > 1 && id[0] == this) {
      alert("Duplicate id " + this.id);
    }
  });
};

const highlightMenu = function () {
  const l = window.location,
    hrefSelector = `[href="${l.pathname}${l.search}"]`,
    menuItem = $("#mainMenu").find(hrefSelector);
  if (menuItem) {
    menuItem.addClass("active");
    $(menuItem).parent().siblings(".dropdown-toggle").addClass("active");
  }
};

const flashFlash = function () {
  $("footer.flash .alert.alert-success").show().delay(5000).fadeTo(500, 0).hide(0);
  $("footer.flash .alert.alert-warning").show().delay(10000).fadeTo(500, 0).hide(0);
};

// 2017-02-21 / Matthias Kummer
// pass in e.g. result of bootstrap_flash helper
const updateFlash = function (contents) {
  $("footer .flash").html(contents);
  flashFlash();
};

/* 2018-06-03 / Matthias Kummer
  display a simple string in the footer message bar
*/
const flashMessage = function (message, type = "success") {
  let div = `
      <div class="alert alert-${type} alert-dismissible fade show " role="alert">
        <button type="button" class="close" data-dismiss="alert">
            <span aria-hidden="true">×</span>
        </button>
      ${message}
    </div>
    `;
  $("footer .flash").html(div);
  flashFlash();
};

const delay = function (callback, ms) {
  clearTimeout(delayTimer);
  delayTimer = setTimeout(callback, ms);
};

const parseParams = function (searchString) {
  return searchString.replace("?", "").replace("%3F", "").split("&")
    .reduce(function (params, param) {
      var paramSplit = param.split("=").map(
        function (value) {
          return decodeURIComponent(value.replace("+", " "));
        });
      params[paramSplit[0]] = paramSplit[1];
      delete params["?"];
      return params;
    }, {});
};

const showBusy = function () {
  $("#busy-indicator").show(); //fadeIn(msBusyFade);
};
const busy = showBusy;
const hideBusy = function () {
  $("#busy-indicator").hide(); //fadeOut(msBusyFade);
};

const handleAjaxErrors = function (event) {
  const status = event.detail[1],
    xhr = event.detail[2];
  if (status == "Unauthorized") {
    let url = new URL(xhr.responseURL),
      rescueUrl = url.searchParams.get("rescue_url");
    if (rescueUrl) {
      window.location.replace(rescueUrl);
    } else {
      window.location.pathname = "/"; // redirect to home
    }
  }
};

const installAjaxCSRFConfig = function () {
  var token = $("meta[name=\"csrf-token\"]").attr("content");
  $.ajaxSetup({
    beforeSend: function (xhr) {
      xhr.setRequestHeader("X-CSRF-Token", token);
    }
  });
};

const hideTooltips = function () {
  $("[data-toggle='tooltip'],[data-original-title]").tooltip("hide");
};

const initTooltips = function () {
  $("body").tooltip({
    selector: "[data-toggle='tooltip']",
    container: "header",
    html: true,
    boundary: "window"
  });
  $(document).on("shown.bs.modal", function () {
    hideTooltips();
  });
};

const hidePopovers = function () {
  $("[data-toggle='popover']").popover("hide");
};

const initPopoversHover = function () {
  $("[data-toggle='popover-hover']").popover({
    html: true,
    trigger: "hover",
    template: "<div class=\"popover wide\" role=\"tooltip\"><div class=\"arrow\"></div><h3 class=\"popover-header\"></h3><div class=\"popover-body\"></div></div>"
  });
};

const initPopoversClick = function () {
  $("[data-toggle='popover-click']").popover({
    html: true,
    trigger: "click",
    template: "<div class=\"popover wide\" role=\"tooltip\"><div class=\"arrow\"></div><h3 class=\"popover-header text-primary\"></h3><div class=\"popover-body\"></div></div>"
  });
};

const initPopovers = function () {
  $("body").popover({
    selector: "[data-toggle='popover']",
    html: true,
    template: "<div class=\"popover wide\" role=\"tooltip\"><div class=\"arrow\"></div><h3 class=\"popover-header\"></h3><div class=\"popover-body\"></div></div>"
  });
  $("[data-toggle='popover-hover-wide']").popover({
    //$("body").popover({
    //selector: "[data-toggle='popover-hover-wide']",
    html: true,
    trigger: "hover click",
    template: "<div class=\"popover wide\" role=\"tooltip\"><div class=\"arrow\"></div><h3 class=\"popover-header\"></h3><div class=\"popover-body\"></div></div>"
  });
  $("[data-toggle='popover-wide']").popover({
    html: true,
    template: "<div class=\"popover wide\" role=\"tooltip\"><div class=\"arrow\"></div><h3 class=\"popover-header\"></h3><div class=\"popover-body\"></div></div>"
  });
  $(document).on("shown.bs.modal", function () {
    hidePopovers();
  });
  $("body").on("inserted.bs.popover", function () {
    $("a.has-data-remote").attr("data-remote", true);
  });
};

// el is an optional jQuery selector
const startDatepicker = function (el) {
  var datepickerElements = el || $(".datepicker");
  datepickerElements.datepicker({
    prevStatus: "",
    prevJumpText: "&#x3c;&#x3c;",
    prevJumpStatus: "",
    nextStatus: "",
    nextJumpText: "&#x3e;&#x3e;",
    nextJumpStatus: "",
    currentStatus: "",
    todayStatus: "",
    clearText: "-",
    clearStatus: "",
    closeStatus: "",
    showWeek: true,
    showOtherMonths: true,
    changeYear: true,
    changeMonth: true
  });
  $.datepicker.setDefaults($.datepicker.regional["de"]);
};

// 2017-09-25 / Matthias Kummer
// usage: add data-on-change="handler url for post request" to select
// will post to the url with data = {name of the select: val of the select}
const installOnSelectChangeHandler = function () {
  $("select[data-on-change]").change(function () {
    var data = {};
    data[$(this).attr("name")] = $(this).val();
    $.post($(this).data("on-change"), data, null, "script");
  });
};

function afterLoginMessage() {
  const notification = $("body").data("after-login-message");
  if (notification) {
    alert(notification);
  }
}

function getActionKey() {
  return $("body").data("controller") + "#" + $("body").data("action");
}

$(document).on("ajax:before turbolinks:visit", showBusy);
$(document).on("ajax:complete turbolinks:load", hideBusy);
$(document).on("turbolinks:before-cache", hideTooltips);
$(document).on("ajax:error", "[data-remote]", handleAjaxErrors);

document.addEventListener("turbolinks:load", function () {

  var actionKey = getActionKey();
  var actionInitializerPre = actionInitializersPre[actionKey];
  if (actionInitializerPre != undefined) {
    actionInitializerPre.forEach(proc => proc.call());
  }

  installAjaxCSRFConfig();
  startDatepicker();
  flashFlash();
  installOnSelectChangeHandler();
  highlightMenu();
  $("#busy-indicator").click(function () {
    hideBusy();
  });

  initializers.forEach(function (proc) {
    proc.call();
  });

  // 2017-12-08 / Matthias Kummer:
  // run AFTER App.initializers, as these may add additional data-toggle="tooltip"
  initTooltips();
  initPopovers();

  //$(".collapse-panel").on("shown.bs.collapse", function() {
  //$("table", $(this)).DataTable().columns.adjust();
  //});

  var actionInitializerPost = actionInitializersPost[actionKey];
  if (actionInitializerPost != undefined) {
    actionInitializerPost.forEach(proc => proc.call());
  }

  afterLoginMessage();
});

$(document).on("page:change", hideBusy);

export {
  findDuplicateIDs,
  flashFlash,
  updateFlash,
  flashMessage,
  delay,
  parseParams,
  showBusy,
  busy,
  hideBusy,
  startDatepicker,
  initTooltips,
  hideTooltips,
  initPopovers,
  initPopoversHover,
  initPopoversClick,
  hidePopovers,
  installOnSelectChangeHandler,
  getActionKey,
  consumer
};