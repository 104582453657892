import { postInit } from "./platin";
import $ from "jquery";

function initBadgeDataDialog() {
  let width = 210; // Width of the video
  let height = 173; // Height of the video
  let streaming = false; // No streaming before startup
  let video = null;
  let canvas = null;
  const photo = $("#foto"),
    newFoto = $("#new_foto"),
    badgeDataModal = $("#badge-data-dialog"),
    takePictureButton = $("#take-picture-button");

  function startup() {
    video = document.getElementById("video");
    canvas = document.getElementById("canvas");

    navigator.mediaDevices.getUserMedia({
      video: true,
      audio: false
    })
      .then(function (stream) {
        video.srcObject = stream;
        video.play();
      })
      .catch(function (err) {
        console.log("An error occurred: " + err); // Print error
      });

    video.addEventListener("canplay", function () {
      if (!streaming) {
        // Set dimensions
        video.setAttribute("width", width);
        video.setAttribute("height", height);
        canvas.setAttribute("width", height);
        canvas.setAttribute("height", width);
        photo.attr("width", height);
        photo.attr("height", width);
        streaming = true; // Begin streeming video
      }
    }, false);

    takePictureButton[0].addEventListener("click", function (ev) {
      takepicture(); // Take a picture
      ev.preventDefault();
    }, false);

    clearphoto();
  }

  // Grey rectangular where the picture will be placed
  function clearphoto() {
    var context = canvas.getContext("2d");
    context.fillRect(0, 0, canvas.width, canvas.height);
    newFoto.val("");
  }

  // Take a picture of the current contents of the video
  function takepicture() {
    var context = canvas.getContext("2d");
    if (width && height) {
      context.drawImage(video, 120, 0, 390, 480, 0, 0, height, width); // Crop video image to ID-badge-picture
      var data = canvas.toDataURL("image/png"); // Convert to PNG
      photo.attr("src", data);
      newFoto.val(data);
    } else {
      clearphoto();
    }
  }

  function stopCamera() {
    let stream = (video && video.srcObject);
    if (stream) {
      stream.getTracks().forEach(function (track) {
        track.stop();
      });
    }
  }

  $("#start-camera").click((e) => {
    e.preventDefault();
    $("#camera-area").removeClass("d-none");
    $("#start-camera").addClass("d-none");
    startup();
    return false;
  });
  badgeDataModal.on("hide.bs.modal", stopCamera);
  badgeDataModal.on("shown.bs.modal", () => $("#emergency_name").focus());

  $("body[data-action='show'][data-controller='training_sessions']").on("click", "[data-target='#badge-data-dialog']", (event) => {
    const data = $(event.currentTarget).data(),
      form = $("#badge-data-form");
    $("#camera-area").addClass("d-none");
    $("#start-camera").removeClass("d-none");

    // store original action URL (with __ID__ placeholder) in data attribute
    if (!($(form).attr("data-action"))) {
      $(form).attr("data-action", $(form).attr("action"));
    }
    $(form).attr("action", $(form).attr("data-action").replace("__ID__", data.id));
    $("#emergency_name").val(data.name);
    $("#emergency_phone").val(data.phone);
    $("#badge-data-for-person").text(data.personName);
    if (data.foto) {
      photo.attr("src", data.foto);
    } else {
      photo.attr("src", photo.data("dummy-foto"));
    }
  });
}

postInit("training_sessions#show", initBadgeDataDialog);