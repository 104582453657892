/* global $ */

import {
  peopleBloodhound,
  personSelect
} from "./person-select";
import {
  handleNewlyRegisteredPerson
} from "./people";

const installEditStaffHandler = function () {
  const selectStaffModal = $("#select-staff.modal"),
    selectStaffForm = $("form#select-staff-form", selectStaffModal),
    staffIDField = $("#staff_id", selectStaffForm),
    modalSubmitButton = $("input.btn[type='submit']", selectStaffForm),
    continueField = $("<input type='hidden' name='continue' value='true'></input>"),
    editableGroups = $("[data-toggle='edit-staff']").data("groups");

  function toggleFunctionDependentFields() {
    const func = $("#function option:selected", selectStaffModal).val();
    if (func == "vis") {
      $("tr.supervisor", selectStaffModal).show();
    } else {
      $("#supervisor_name", selectStaffModal).typeahead("val", "");
      $("#supervisor_name", selectStaffModal)[0].selection = null;
      $("tr.supervisor", selectStaffModal).hide();
    }
    $("tr.locations", selectStaffModal).toggle(func != "email");
  }

  $("body").on("click", "[data-toggle='edit-staff']", function (event) {
    event.preventDefault();
    for (let group of editableGroups) {
      $(`.edit-staff.group-${group}`).toggleClass("d-none");
    }
    $(".add-staff").toggleClass("d-none");
    if ($(this).hasClass("fa-lock")) {
      $(this).removeClass("fa-lock").addClass("fa-unlock");
    } else {
      $(this).removeClass("fa-unlock").addClass("fa-lock");
    }
    $("[data-toggle='tooltip']").tooltip("hide");
  });

  // on click on edit button, populate modal with participant data
  $("body").on("click", "[data-target='#select-staff']", function (event) {
    const editLink = $(event.currentTarget),
      person = {
        id: editLink.data("person-id"),
        name: editLink.data("person-name"),
        email: editLink.data("email"),
        org: editLink.data("org")
      },
      supervisor = {
        id: editLink.data("supervisor-id"),
        name: editLink.data("supervisor-name"),
      },
      locations = editLink.data("locations");
    resetselectStaffForm();
    staffIDField.val(editLink.data("staff-id"));
    $("#remarks", selectStaffForm).val(editLink.data("remarks"));
    $("input[type=checkbox]#receives_df_dossier", selectStaffForm).prop("checked", editLink.data("receives-df-dossier"));
    $(`#function option[value="${editLink.data("function")}"]`, selectStaffModal).prop("selected", "selected");
    if (person.id) {
      $("#person_name", selectStaffModal)
        .typeahead("val", person.name);
      $("#person_name", selectStaffModal)[0].selection = person;
      $("#person_id", selectStaffModal).val(person.id);
      $("#person_email", selectStaffForm).val(person.email);
      $("#person_org", selectStaffForm).val(person.org);
    }
    if (supervisor.id) {
      $("#supervisor_name", selectStaffModal)
        .typeahead("val", supervisor.name);
      $("#supervisor_name", selectStaffModal)[0].selection = supervisor;
      $("#supervisor_id", selectStaffModal).val(supervisor.id);
    }
    toggleFunctionDependentFields();
    $(`#mission_part option[value=${editLink.data("mission-part")}]`).prop("selected", "selected");
    $("select#locations_ option:selected").prop("selected", false);
    if (typeof (locations) == "object") {
      locations.forEach(loc => $(`select#locations_ option[value=${loc}]`).prop("selected", "selected"));
    }
    selectStaffForm.valid();
    $("#function").focus();
  });

  $("body").on("keydown keyup", "#select-staff.modal", function (event) {
    if (staffIDField.val() != "") {
      return;
    }
    if (event.keyCode == 18) {
      if (event.type == "keydown") {
        modalSubmitButton.val("Speichern, nächste Person erfassen…");
        selectStaffForm.append(continueField);
      } else {
        modalSubmitButton.val("Speichern");
        continueField.remove();
      }
    }
  });

  selectStaffModal.on("shown.bs.modal", () => $("#function").focus());
  personSelect();
  $("#function", selectStaffForm).change(toggleFunctionDependentFields);
  handleNewlyRegisteredPerson();

  selectStaffForm.submit(function (event) {
    if ($("input[type=hidden][name=continue]", event.target).length == 0) {
      selectStaffModal.modal("hide");
    }
  });
};

const editstaff = function () {
  return ($("[data-toggle='edit-staff']").hasClass("fa-unlock"));
};

const resetselectStaffForm = function (keepFunction = false) {
  const selectStaffModal = $("#select-staff.modal");
  peopleBloodhound.clear();
  peopleBloodhound.clearPrefetchCache();
  peopleBloodhound.initialize(true);
  $("#staff_id", selectStaffModal).val(null);
  $("#person_name", selectStaffModal)[0].selection = null;
  $("#person_name", selectStaffModal).typeahead("val", "").show();
  $("#person_id", selectStaffModal).val("");
  $("#supervisor_name", selectStaffModal)[0].selection = null;
  $("#supervisor_name", selectStaffModal).typeahead("val", "").show();
  $("#supervisor_id", selectStaffModal).val("");
  $("#person_email", selectStaffModal).val("");
  $("#person_org", selectStaffModal).val("");
  $("#receives_df_dossier", selectStaffModal).prop("checked", false);
  $("#mission_part option:selected", selectStaffModal).prop("selected", "");
  $("input[type=checkbox]#receives_df_dossier", selectStaffModal).prop("checked", true);
  if (!keepFunction) {
    $("#function", selectStaffModal).val("").focus();
  } else {
    $("input[name=person_name]", selectStaffModal).focus();
  }
};

export {
  resetselectStaffForm,
  editstaff,
  installEditStaffHandler
};