import {
  postInit
} from "./platin";
import * as d3 from "d3";
import $ from "jquery";

const initGantt = function (selector) {
  var FIT_TIME_DOMAIN_MODE = "fit";

  var margin = {
    top: 20,
    right: 40,
    bottom: 30,
    left: 150
  };
  const container = d3.select(selector);

  let svg, coordinateG, clipContainer;

  var timeDomainStart = d3.timeDay.offset(new Date(), -3);
  var timeDomainEnd = d3.timeHour.offset(new Date(), +3);
  var timeDomainMode = FIT_TIME_DOMAIN_MODE; // fixed or fit
  var systems = [];
  var height = 400;
  var width = $(container.node().closest(".tab-content"))[0].clientWidth - margin.right - margin.left - 5;

  var tickFormat = "%H:%M";

  var keyFunction = function (d) {
    return d.startDate + d.systemName + d.endDate;
  };

  var barTransform = function (d) {
    return "translate(" + x(d.startDate) + "," + y(d.systemName) + ")";
  };

  var x = d3.scaleTime().domain([timeDomainStart, timeDomainEnd]).range([0, width]).clamp(true);
  var y = d3.scaleBand().domain(systems).rangeRound([0, height - margin.top - margin.bottom]).padding(.1);

  var xAxis = d3.axisBottom(x).tickFormat(d3.timeFormat(tickFormat)); //.tickSubdivide(true).tickSize(8).tickPadding(8);
  var yAxis = d3.axisLeft(y).tickSize(0);

  var initTimeDomain = function (tasks) {
    if (timeDomainMode === FIT_TIME_DOMAIN_MODE) {
      if (tasks === undefined || tasks.length < 1) {
        timeDomainStart = d3.timeDay.offset(new Date(), -3);
        timeDomainEnd = d3.timeHour.offset(new Date(), +3);
        return;
      }
      tasks.sort(function (a, b) {
        return a.endDate - b.endDate;
      });
      timeDomainEnd = tasks[tasks.length - 1].endDate;
      tasks.sort(function (a, b) {
        return a.startDate - b.startDate;
      });
      timeDomainStart = tasks[0].startDate;
    }
  };

  var initAxis = function () {
    x = d3.scaleTime().domain([timeDomainStart, timeDomainEnd]).range([0, width]);
    y = d3.scaleBand().domain(systems).rangeRound([0, height - margin.top - margin.bottom]).padding(.1);
    xAxis = d3.axisBottom(x).tickFormat(d3.timeFormat(tickFormat));
    yAxis = d3.axisLeft(y).tickSize(0);
  };

  function addLegendItem(index, text, klass) {
    const w = 120,
      m = 4,
      h = 30;
    let g = svg.append("g")
      .attr("transform", `translate(${margin.left + (w+m)*index},${height + margin.bottom - h + 10})`);
    g.append("rect")
      .attr("rx", 5)
      .attr("width", w)
      .attr("height", h)
      .attr("class", klass);
    g.append("text")
      .text(text)
      .attr("dy", h - 10)
      .attr("fill", "black")
      .attr("x", w / 2)
      .attr("text-anchor", "middle");
    return g;
  }

  function gantt(tasks) {

    initTimeDomain(tasks);
    initAxis();

    svg = d3.select(selector)
      .append("svg")
      .attr("class", "outage-gantt-chart")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom);

    coordinateG = svg
      .append("g")
      .attr("class", "coordinate-system")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .attr("transform", "translate(" + margin.left + ", " + margin.top + ")");

    // svg <defs> for setting clipPaths
    coordinateG.append("defs")
      .append("clipPath")
      .attr("id", "clip")
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", width)
      .attr("height", height);

    clipContainer = coordinateG.append("g")
      .attr("clip-path", "url(#clip)");

    let barGs = clipContainer.selectAll("g.outage")
      .data(tasks, keyFunction)
      .enter()
      .append("g")
      .attr("class", "outage")
      .attr("transform", barTransform)
      .attr("title", d => d.description)
      .attr("data-toggle", "tooltip")
      .on("click", d => window.open(d.url, "_blank"));

    barGs.selectAll("rect")
      .data(d => d.statusChanges)
      .enter()
      .append("rect")
      .attr("class", d => `bar-${d.status}`)
      .attr("rx", 5)
      .attr("ry", 5)
      .attr("y", 0)
      .attr("height", function () {
        return y.bandwidth();
      })
      .attr("x", d => x(d.startTime) - x(d.outageStartTime))
      .attr("width", d => Math.max(1, (x(d.endTime) - x(d.startTime))));

    barGs.append("text")
      .attr("dy", y.bandwidth() / 2 + 4)
      .attr("text-anchor", "middle")
      .attr("x", d => Math.max(1, (x(d.endDate) - x(d.startDate))) / 2)
      .text(d => d.name);

    coordinateG.append("g")
      .attr("class", "x axis")
      .attr("transform", "translate(0, " + (height - margin.top - margin.bottom) + ")")
      .transition()
      .call(xAxis);

    coordinateG.append("g").attr("class", "y axis").transition().call(yAxis);

    addLegendItem(0, "erfasst", "bar-discovered");
    addLegendItem(1, "temporär behoben", "bar-temp_fix");
    addLegendItem(2, "behoben", "bar-fixed");
    addLegendItem(3, "Ursache behoben", "bar-cause_fixed");
    addLegendItem(4, "Geschlossen", "bar-closed");

    return gantt;

  }

  gantt.redraw = function () {

    initAxis();

    let barGs = coordinateG.selectAll("g.outage");

    barGs.transition()
      .attr("transform", barTransform);
    barGs.selectAll("rect")
      .transition()
      .attr("x", d => x(d.startTime) - x(d.outageStartTime))
      .attr("width", d => Math.max(1, (x(d.endTime) - x(d.startTime))));
    barGs.selectAll("text")
      .transition()
      .attr("x", d => Math.max(1, (x(d.endDate) - x(d.startDate))) / 2);

    svg.select(".x").transition().call(xAxis);
    svg.select(".y").transition().call(yAxis);

    return gantt;
  };

  gantt.margin = function (value) {
    if (!arguments.length)
      return margin;
    margin = value;
    return gantt;
  };

  gantt.timeDomain = function (value) {
    if (!arguments.length)
      return [timeDomainStart, timeDomainEnd];
    timeDomainStart = +value[0], timeDomainEnd = +value[1];
    return gantt;
  };

  /**
   * @param {string}
   *                vale The value can be "fit" - the domain fits the data or
   *                "fixed" - fixed domain.
   */
  gantt.timeDomainMode = function (value) {
    if (!arguments.length)
      return timeDomainMode;
    timeDomainMode = value;
    return gantt;
  };

  /* get/set the systems to be displayed (y dimension) */
  gantt.systems = function (value) {
    if (!arguments.length)
      return systems;
    systems = value;
    return gantt;
  };

  gantt.width = function (value) {
    if (!arguments.length)
      return width;
    width = +value;
    return gantt;
  };

  gantt.height = function (value) {
    if (!arguments.length)
      return height;
    height = +value;
    return gantt;
  };

  gantt.tickFormat = function (value) {
    if (!arguments.length)
      return tickFormat;
    tickFormat = value;
    return gantt;
  };

  gantt.selector = function (value) {
    if (!arguments.length)
      return selector;
    selector = value;
    return gantt;
  };

  return gantt;
};

/* transforms an array of {status:, at:} tuples
   to an array of triples {status:, startTime:, endTime:}
   for the final endTime, needs the actual end time of the outage
*/
function statusChanges(statusChangeArray, startTime, endTime) {
  return statusChangeArray.map((change, i) => {
    return {
      status: change.status,
      outageStartTime: new Date(startTime),
      startTime: (i == 0) ? new Date(startTime) : new Date(change.at),
      endTime: (i < statusChangeArray.length - 1) ? new Date(statusChangeArray[i + 1].at) : new Date(endTime)
    };
  });
}

function drawGantt() {
  let gantt;

  d3.json("/outages/gantt_tasks.json", {
    credentials: "same-origin"
  }).then(function (data) {
    let tasks = data.tasks.map(d => {
      d.startDate = new Date(d.startTime);
      d.endDate = new Date(d.endTime);
      d.statusChanges = statusChanges(d.status_changes, d.startTime, d.endTime);
      d.systemName = d.system;
      return d;
    });

    const systemNames = data.systems;

    tasks.sort(function (a, b) {
      return a.endDate - b.endDate;
    });
    tasks.sort(function (a, b) {
      return a.startDate - b.startDate;
    });

    var format = "%d.%m %H:%M";

    gantt = initGantt(".gantt-container").systems(systemNames).tickFormat(format);
    gantt(tasks);
  });

  $("button.expand").click(function (e) {
    e.preventDefault();
    let timeDomain = gantt.timeDomain(),
      timeWidth = timeDomain[1] - timeDomain[0],
      newTimeDomain = [
        new Date(+timeDomain[0] - timeWidth / 2),
        new Date(+timeDomain[1] + timeWidth / 2)
      ];
    gantt.timeDomainMode("fix").timeDomain(newTimeDomain).redraw();
  });
  $("button.reduce").click(function (e) {
    e.preventDefault();
    let timeDomain = gantt.timeDomain(),
      timeWidth = timeDomain[1] - timeDomain[0],
      newTimeDomain = [
        new Date(+timeDomain[0] + timeWidth / 4),
        new Date(+timeDomain[1] - timeWidth / 4)
      ];
    gantt.timeDomainMode("fix").timeDomain(newTimeDomain).redraw();
  });
  $("button.move-left").click(function (e) {
    e.preventDefault();
    let timeDomain = gantt.timeDomain(),
      timeWidth = timeDomain[1] - timeDomain[0],
      newTimeDomain = [
        new Date(+timeDomain[0] - timeWidth / 3),
        new Date(+timeDomain[1] - timeWidth / 3)
      ];
    gantt.timeDomainMode("fix").timeDomain(newTimeDomain).redraw();
  });
  $("button.move-right").click(function (e) {
    e.preventDefault();
    let timeDomain = gantt.timeDomain(),
      timeWidth = timeDomain[1] - timeDomain[0],
      newTimeDomain = [
        new Date(+timeDomain[0] + timeWidth / 3),
        new Date(+timeDomain[1] + timeWidth / 3)
      ];
    gantt.timeDomainMode("fix").timeDomain(newTimeDomain).redraw();
  });
}

postInit("outages#index", drawGantt);